<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col col-titles col-mid">
          <h1>Salario diario base de cotización para efectos del IMSS e INFONAVIT</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">

        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Año del cálculo:"
              label-for="i-anio">
              <b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Zona geográfica del trabajador?: "
              label-for="i-zona_geografica">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.zona_geografica" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="rp" >RP</b-form-select-option>
                        <b-form-select-option value="zlfn" >ZLFN</b-form-select-option>
                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días del mes: "
              label-for="i-dias_mes">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.dias_mes" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="30" >30</b-form-select-option>
                        <b-form-select-option value="30.40" >30.40</b-form-select-option>
                        <b-form-select-option value="30.41666" >30.41666</b-form-select-option>
                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Salario bruto mensual:"
              label-for="i-vent" id="input-salario_bruto_mensual">

              <currency-input id="i-vent" class="form-control"
                v-model="form.salario_bruto_mensual" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.salario_bruto_mensual != null" style="color: red;"><small>{{validation.salario_bruto_mensual}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Días de vacaciones según las prestaciones del empleador:"
              label-for="i-nul" id="input-dias_vacaciones">

              <b-form-input
                id="i-nul"
                v-model="form.dias_vacaciones"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_vacaciones != null" style="color: red;"><small>{{validation.dias_vacaciones}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Prima vacacional según las prestaciones del empleador:"
              label-for="i-nul" id="input-prima_vacacional">

              <b-form-input
                id="i-nul"
                v-model="form.prima_vacacional"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.prima_vacacional != null" style="color: red;"><small>{{validation.prima_vacacional}}</small></div>
            </b-form-group>
            <b-form-group class="custom-form-group-s2"
              label="Días de aguinaldo según las prestaciones del empleador:"
              label-for="i-nul" id="input-dias_aguinaldo">

              <b-form-input
                id="i-nul"
                v-model="form.dias_aguinaldo"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.dias_aguinaldo != null" style="color: red;"><small>{{validation.dias_aguinaldo}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Cualquier otro importe entregado al trabajador por su trabajo (ingresar el monto diario):"
              label-for="i-vent" id="input-otro_monto">

              <currency-input id="i-vent" class="form-control"
                v-model="form.otro_monto" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.otro_monto != null" style="color: red;"><small>{{validation.otro_monto}}</small></div>
            </b-form-group>

            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <h5 class="title">DATOS GENERALES</h5>

          <div class="row">
            <div class="col-12 col-line">
              <hr />
            </div>

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!--div class="col col-share">
                  <a class="btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div-->
              </div>
            </div>

            <div class="col-12 col-info">
              <p style="text-align:center"><b>Determinación del salario diario integrado</b></p>
              <div class="row row-body">
                <div class="col col-detail">
                  Salario diario no integrado:
                </div>
                <div class="col col-med">
                  {{detailInfo.salario_diario_nointegrado}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Factor de integración por las prestaciones laborales:
                </div>
                <div class="col col-med">
                  {{detailInfo.factor_integracion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Salario diario integrado:
                </div>
                <div class="col col-med">
                  {{detailInfo.salario_diario_integrado}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Cualquier otro importe entregado al trabajador por su trabajo (ingresar el monto diario):
                </div>
                <div class="col col-med">
                  {{detailInfo.otro_monto}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Salario diario base de cotización:
                </div>
                <div class="col col-med">
                  {{detailInfo.salario_diario_cotizacion}}
                </div>
              </div>

              <p style="text-align:center"><b>Topes de cotización al IMSS e INFONAVIT</b></p>
              <div class="row row-body">
                <div class="col col-detail">
                  Límite mínimo de cotización para la base de las C.O.P. (uso del SMGZG):
                </div>
                <div class="col col-med">
                  {{detailInfo.limite_minimo_cotizacion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Límite máximo de cotización para la base de las C.O.P. (uso de la UMA a partir de febrero 2017):
                </div>
                <div class="col col-med">
                  {{detailInfo.limite_maximo_cotizacion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Valor de la UMA a partir de {{detailInfo.mes_info}} de {{detailInfo.anio}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.uma}}
                </div>
              </div>
              

            </div>
            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

           
          </div>
        </div>
        <!-- Paso 2, FIN -->
        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->
      </div>
    </section>


    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
   
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
  import FlegalesComponent from './components/fleg-comp-salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue';
export default {
  data(){
    return{
      showFLegalesComp: false,
      step: 1,

      form:{
		anio:null,
		zona_geografica:null,
		dias_mes:null,
		salario_bruto_mensual:null,
		dias_vacaciones:null,
		prima_vacacional:null,
		dias_aguinaldo:null,
		otro_monto:null,
	},

      formComp: {
        email: null
      },
      years:[],
      detailInfo:{

      },
      validation:{
        anio:null,
		zona_geografica:null,
		dias_mes:null,
		salario_bruto_mensual:null,
		dias_vacaciones:null,
		prima_vacacional:null,
		dias_aguinaldo:null,
		otro_monto:null,
        
      }
    }
  },

  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      this.validation.total_creditos = null;
     
      
      event.preventDefault();
      if(this.form.dias_vacaciones < 6){
                    this.validation.dias_vacaciones = '"Días de vacaciones según las prestaciones del empleador" no debe de ser menor a 6 días según la LFT';
                    var scrollt = document.getElementById("input-dias_vacaciones").offsetTop;
                    setTimeout( ()=>{
                      window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
                    }, 200);
                    return;
        }
        if(this.form.prima_vacacional < 25){
                    this.validation.prima_vacacional = '"Prima vacacional según las prestaciones del empleador" no debe de ser menor a 25% según la LFT';
                    var scrollt = document.getElementById("input-prima_vacacional").offsetTop;
                    setTimeout( ()=>{
                      window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
                    }, 200);
                    return;
              }
       if (this.form.dias_aguinaldo < 15) {
          this.validation.dias_aguinaldo = '"Días de aguinaldo según las prestaciones del empleador" no debe de ser menor a 15 días según la LFT';
          var scrollt = document.getElementById("input-dias_aguinaldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }

        this.$refs['modal-loading'].show();
        //$('#btn_calc').hide();
        event.preventDefault();
        axios.post(tools.url("/calculateNineteen"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al calcular la informacion');
        });
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

       

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    otherCalc(){
      location.reload();
    },
    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },
  },

  mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var years = [];
      for (var i = 2016; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }

      years.reverse();
      this.years = years;
  },
  components: {
    FlegalesComponent
  },
}
</script>
<style>
#table_parcialidades {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
}

#table_parcialidades td, #table_parcialidades th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#table_parcialidades th {
  background-color: #dddddd;
}
</style>
