<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'LISR 14', title2: 'PAGOS PROVISIONALES MENSUALES',
          content: [
          	
            { c1: 'Fracción II',
              c2: '…………………………………………………<br><b>DETERMINACION DE LA UTILIDAD FISCAL PARA EL PAGO PROVISIONAL</b><br>II. La utilidad fiscal para el pago provisional se determinará multiplicando el coeficiente de utilidad que corresponda conforme a la fracción anterior, por los ingresos nominales correspondientes al periodo comprendido desde el inicio del ejercicio y hasta el último día del mes al que se refiere el pago y, en su caso, se disminuirán los siguientes conceptos:<br>…………………………………………………'
            },
            { c1: 'Inciso c)',
              c2: '<b>PÉRDIDA FISCAL DE EJERCICIOS ANTERIORES PENDIENTE DE APLICAR</b><br>c) La pérdida fiscal de ejercicios anteriores pendiente de aplicar contra las utilidades fiscales, sin perjuicio de disminuir dicha pérdida de la utilidad fiscal del ejercicio.<br>…………………………………………………'
            },
            
          ]
        },
       
      	{ title1: 'LISR 57', title2: 'DETERMINACIÓN DE LA PÉRDIDA FISCAL',
          content: [
          	{ c1: 'Primer párrafo',
              c2: 'La pérdida fiscal se obtendrá de la diferencia entre los ingresos acumulables del ejercicio y las deducciones autorizadas por esta Ley, cuando el monto de estas últimas sea mayor que los ingresos. El resultado obtenido se incrementará, en su caso, con la participación de los trabajadores en las utilidades de las empresas pagada en el ejercicio en los términos del artículo 123 de la Constitución Política de los Estados Unidos Mexicanos.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>PLAZO PARA DISMINUIR LAS PÉRDIDAS DE LA UTILIDAD FISCAL</b><br>La pérdida fiscal ocurrida en un ejercicio podrá disminuirse de la utilidad fiscal de los diez ejercicios siguientes hasta agotarla.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>PÉRDIDA DEL DERECHO A LA DISMINUCIÓN</b><br>Cuando el contribuyente no disminuya en un ejercicio la pérdida fiscal de ejercicios anteriores, pudiendo haberlo hecho conforme a este artículo, perderá el derecho a hacerlo en los ejercicios posteriores y hasta por la cantidad en la que pudo haberlo efectuado.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>ACTUALIZACIÓN DEL MONTO DE LA PÉRDIDA FISCAL</b><br>Para los efectos de este artículo, el monto de la pérdida fiscal ocurrida en un ejercicio, se actualizará multiplicándolo por el factor de actualización correspondiente al periodo comprendido desde el primer mes de la segunda mitad del ejercicio en el que ocurrió y hasta el último mes del mismo ejercicio. La parte de la pérdida fiscal de ejercicios anteriores ya actualizada pendiente de aplicar contra utilidades fiscales se actualizará multiplicándola por el factor de actualización correspondiente al periodo comprendido desde el mes en el que se actualizó por última vez y hasta el último mes de la primera mitad del ejercicio en el que se aplicará.'
            },
            { c1: 'Quinto párrafo',
              c2: 'Para los efectos del párrafo anterior, cuando sea impar el número de meses del ejercicio en que ocurrió la pérdida, se considerará como primer mes de la segunda mitad, el mes inmediato posterior al que corresponda la mitad del ejercicio.'
            },
            { c1: 'Sexto párrafo',
              c2: '<b>DERECHO QUE NO PUEDE TRANSMITIRSE</b><br>El derecho a disminuir las pérdidas fiscales es personal del contribuyente que las sufra y no podrá ser transmitido a otra persona ni como consecuencia de fusión.'
            },
            { c1: 'Séptimo párrafo',
              c2: '<b>ESCISIÓN DE SOCIEDADES</b><br>En el caso de escisión de sociedades, las pérdidas fiscales pendientes de disminuirse de utilidades fiscales, se deberán dividir entre las sociedades escindentes y las escindidas que se dediquen al mismo giro, debiendo acreditar el mismo en el ejercicio de facultades de comprobación.'
            },
            { c1: 'Octavo párrafo',
              c2: '<b>DIVISIÓN DE PÉRDIDAS FISCALES QUE EFECTÚEN LAS SOCIEDADES ESCINDENTES Y ESCINDIDAS</b><br>Para los efectos del párrafo anterior, la división de las pérdidas fiscales que efectúen las sociedades escindentes y escindidas, se realizará en la proporción en que se divida la suma del valor total de los inventarios y de las cuentas por cobrar relacionadas con las actividades comerciales de la escindente cuando ésta realizaba preponderantemente dichas actividades, o de los activos fijos cuando la sociedad escindente realizaba preponderantemente otras actividades empresariales. Para determinar la proporción a que se refiere este párrafo, se deberán excluir las inversiones en bienes inmuebles no afectos a la actividad preponderante.'
            },
            
          ]
        },
      	{ title1: 'LISR 58', title2: 'GIRO PARA FINES DE DISMINUCIÓN DE PÉRDIDAS EN EL CASO DE FUSIÓN',
          content: [
          	
            { c1: 'Primer párrafo',
              c2: 'En los casos de fusión, la sociedad fusionante sólo podrá disminuir su pérdida fiscal pendiente de disminuir al momento de la fusión, con cargo a la utilidad fiscal correspondiente a la explotación de los mismos giros en los que se produjo la pérdida.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CAMBIO DE SOCIOS O ACCIONISTAS</b><br>Cuando cambien los socios o accionistas que posean el control de una sociedad que tenga pérdidas fiscales de ejercicios anteriores pendientes de disminuir y la suma de sus ingresos en los tres últimos ejercicios hayan sido menores al monto actualizado de esas pérdidas al término del último ejercicio antes del cambio de socios o accionistas, dicha sociedad únicamente podrá disminuir las pérdidas contra las utilidades fiscales correspondientes a la explotación de los mismos giros en los que se produjeron las pérdidas. Para estos efectos, se considerarán los ingresos mostrados en los estados financieros correspondientes al periodo señalado, aprobados por la asamblea de accionistas.'
            },
            { c1: 'Tercer párrafo',
              c2: 'Para los efectos del párrafo anterior, se considera que existe cambio de socios o accionistas que posean el control de una sociedad, cuando en uno o más actos realizados dentro de un periodo de tres años, contados a partir de que se lleve a cabo la fusión:<br>I. Cambien los tenedores, directa o indirectamente, de más del cincuenta por ciento de las acciones o partes sociales con derecho a voto de la sociedad de que se trate.<br>II. Cambien los tenedores, directos o indirectos de alguno de los derechos siguientes:<br>a) Los que permitan imponer decisiones en las asambleas generales de accionistas, de socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes, de la sociedad de que se trate.<br>b) Los que permitan dirigir la administración, la estrategia o las principales políticas de la sociedad de que se trate, ya sea a través de la propiedad de valores, por contrato o de cualquier otra forma.<br>III. Posterior a la fusión, la sociedad de que se trate y su socio o accionista, que sea persona moral, dejen de consolidar sus estados financieros de conformidad con las disposiciones que regulen al contribuyente en materia contable y financiera, o bien, que esté obligado a aplicar.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>MOMENTO DE CAMBIO DE SOCIOS O ACCIONISTAS</b><br>Para efectos de las fracciones anteriores, en caso de que se celebren acuerdos o actos jurídicos que sujeten el cambio de los socios o accionistas a una condición suspensiva o término, se considerará que dicho cambio se efectúa desde el momento de la celebración del acto.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>CASOS EN QUE NO APLICA EN EL CAMBIO DE SOCIOS O ACCIONISTAS</b><br>Lo dispuesto en los párrafos tercero y cuarto de este artículo, no aplica en los casos en que el cambio de socios o accionistas se presente como consecuencia de herencia, donación o con motivo de una reestructura corporativa, fusión o escisión de sociedades que no se consideren enajenación en los términos del Código Fiscal de la Federación , siempre que en el caso de la reestructura, fusión o escisión los socios o accionistas directos o indirectos que mantenían el control previo a dichos actos, lo mantengan con posteridad a los mismos. En el caso de fusión, deberá estarse a lo dispuesto en el primer párrafo de este artículo. Para estos efectos, no se incluirán las acciones colocadas entre el gran público inversionista, de conformidad con las reglas de carácter general que para tal efecto emita el Servicio de Administración Tributaria.'
            },
            { c1: 'Sexto párrafo',
              c2: '<b>REGISTROS CONTABLES</b><br>Las sociedades a que se refieren los párrafos anteriores para disminuir las pérdidas fiscales pendientes de disminuir, deberán llevar sus registros contables en tal forma que el control de sus pérdidas fiscales en cada giro se pueda ejercer individualmente respecto de cada ejercicio, así como de cada nuevo giro que se incorpore al negocio. Por lo que se refiere a los gastos no identificables, éstos deberán aplicarse en la parte proporcional que representen en función de los ingresos obtenidos propios de la actividad. Esta aplicación deberá hacerse con los mismos criterios para cada ejercicio.'
            },
            { c1: 'Séptimo párrafo',
              c2: '<b>PÉRDIDAS QUE PROVENGAN DE FUSIÓN O LIQUIDACIÓN DE SOCIEDADES</b><br>No se disminuirá la pérdida fiscal o la parte de ella, que provenga de fusión o de liquidación de sociedades, en las que el contribuyente sea socio o accionista.'
            },
            
          ]
        },
        { title1: 'CFF 11', title2: 'EJERCICIOS FISCALES',
          content: [
          	
            { c1: 'Primer párrafo',
              c2: 'Cuando las leyes fiscales establezcan que las contribuciones se calcularán por ejercicios fiscales, éstos coincidirán con el año de calendario. Cuando las personas morales inicien sus actividades con posterioridad al 1o. de enero, en dicho año el ejercicio fiscal será irregular, debiendo iniciarse el día en que comiencen actividades y terminarse el 31 de diciembre del año de que se trate.<br>…………………………………………………'
            },
            
          ]
        },
        
        { title1: 'CFF 17-A', title2: 'ACTUALIZACIÓN DE CONTRIBUCIONES, APROVECHAMIENTOS O DEVOLUCIONES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El monto de las contribuciones, aprovechamientos, así como de las devoluciones a cargo del fisco federal, se actualizará por el transcurso del tiempo y con motivo de los cambios de precios en el país, para lo cual se aplicará el factor de actualización a las cantidades que se deban actualizar. Dicho factor se obtendrá dividiendo el Indice Nacional de Precios al Consumidor del mes anterior al más reciente del periodo entre el citado índice correspondiente al mes anterior al más antiguo de dicho periodo. Las contribuciones, los aprovechamientos, así como las devoluciones a cargo del fisco federal, no se actualizarán por fracciones de mes.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>APLICACIÓN DEL ÚLTIMO INPC PUBLICADO</b><br>En los casos en que el Indice Nacional de Precios al Consumidor del mes anterior al más reciente del periodo, no haya sido publicado por el Instituto Nacional de Estadística y Geografía, la actualización de que se trate se realizará aplicando el último índice mensual publicado.'
            },
            { c1: 'Tercer párrafo',
              c2: 'Los valores de bienes u operaciones se actualizarán de acuerdo con lo dispuesto por este artículo, cuando las leyes fiscales así lo establezcan. Las disposiciones señalarán en cada caso el periodo de que se trate.'
            },
            { c1: 'Cuarto párrafo',
              c2: 'Las cantidades actualizadas conservan la naturaleza jurídica que tenían antes de la actualización. El monto de ésta, determinado en los pagos provisionales, definitivos y del ejercicio, no será deducible ni acreditable.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>CASO EN QUE EL FACTOR DE ACTUALIZACIÓN SERÁ 1</b><br>Cuando el resultado de la operación a que se refiere el primer párrafo de este artículo sea menor a 1, el factor de actualización que se aplicará al monto de las contribuciones, aprovechamientos y devoluciones a cargo del fisco federal, así como a los valores de bienes u operaciones de que se traten, será 1.<br>…………………………………………………'
            },
            { c1: 'Octavo párrafo',
              c2: '<b>AJUSTE A DECENAS DE PESOS</b><br>Para determinar el monto de las cantidades a que se refieren los párrafos sexto y séptimo de este artículo, se considerarán, inclusive, las fracciones de peso; no obstante lo anterior, dicho monto se ajustará para que las cantidades de 0.01 a 5.00 pesos en exceso de una decena, se ajusten a la decena inmediata anterior y de 5.01 a 9.99 pesos en exceso de una decena, se ajusten a la decena inmediata superior.<br>…………………………………………………'
            },
            { c1: 'Décimo párrafo',
              c2: '<b>FACTORES O PROPORCIONES HASTA EL DIEZMILÉSIMO</b><br>Cuando de conformidad con las disposiciones fiscales se deban realizar operaciones aritméticas, con el fin de determinar factores o proporciones, las mismas deberán calcularse hasta el diezmilésimo.'
            },
           ]
        }

      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
