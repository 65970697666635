<template lang="html">
	<footer id="footer" v-if="$route.name != 'accessPage'">
		<div class="container oversized-container">
			<div class="row align-items-center">
				<div class="col-lg-4 col-footer">
					<!--p>Última actualización: 12/02/2021</p-->
					<p>V. 1.0.1</p>
				</div>

				<div class="col-lg-4 col-footer col-logo">
					<router-link to="/">
						<img class="img-fluid" src="public/images/shared/logo-white.png">
					</router-link>
				</div>

				<div class="col-lg-4 col-footer">
					<p>Todos los derechos reservados {{ currentYear }}</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data(){
		return{
			currentYear: 0,
		}
	},

	beforeMount(){
		this.currentYear = new Date().getFullYear();
	}
}
</script>
