<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'LISR 93', title2: 'INGRESOS EXENTOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'No se pagará el impuesto sobre la renta por la obtención de los siguientes ingresos:<br>........................'
            },
            { c1: 'Fracción XIV',
              c2: '<b>GRATIFICACIONES, PRIMAS VACACIONALES, PTU Y PRIMAS DOMINICALES A TRABAJADORES</b><br>XIV. Las gratificaciones que reciban los trabajadores de sus patrones, durante un año de calendario, hasta el equivalente del salario mínimo general del área geográfica del trabajador elevado a 30 días, cuando dichas gratificaciones se otorguen en forma general; así como las primas vacacionales que otorguen los patrones durante el año de calendario a sus trabajadores en forma general y la participación de los trabajadores en las utilidades de las empresas, hasta por el equivalente a 15 días de salario mínimo general del área geográfica del trabajador, por cada uno de los conceptos señalados. Tratándose de primas dominicales hasta por el equivalente de un salario mínimo general del área geográfica del trabajador por cada domingo que se labore.'
            },
            { c1: 'Fracción XV',
              c2: '<b>EXCEDENTE DE LOS INGRESOS</b><br>XV. Por el excedente de los ingresos a que se refiere la fracción anterior se pagará el impuesto en los términos de este Título.<br>........................'
            },
           
          ]
        },
        { title1: 'LISR 94', title2: 'INGRESOS QUE SE GRAVAN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Se consideran ingresos por la prestación de un servicio personal subordinado, los salarios y demás prestaciones que deriven de una relación laboral, incluyendo la participación de los trabajadores en las utilidades de las empresas y las prestaciones percibidas como consecuencia de la terminación de la relación laboral. Para los efectos de este impuesto, se asimilan a estos ingresos los siguientes:<br>........................'
            },
            { c1: 'Quinto párrafo',
              c2: 'El pago del impuesto a que se refiere este artículo deberá efectuarse mediante retención que efectúen las citadas personas morales.<br>........................'
            },
          ]
        },
       	{ title1: 'LISR 95', title2: 'INGRESOS POR PRIMAS DE ANTIGÜEDAD, RETIRO, INDEMNIZACIÓN, ETC., POR SEPARACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Cuando se obtengan ingresos por concepto de primas de antigüedad, retiro e indemnizaciones u otros pagos, por separación, se calculará el impuesto anual, conforme a las siguientes reglas:'
            },
            { c1: 'Fracción I',
              c2: 'I. Del total de percepciones por este concepto, se separará una cantidad igual a la del último sueldo mensual ordinario, la cual se sumará a los demás ingresos por los que se deba pagar el impuesto en el año de calendario de que se trate y se calculará, en los términos de este Título, el impuesto correspondiente a dichos ingresos. Cuando el total de las percepciones sean inferiores al último sueldo mensual ordinario, éstas se sumarán en su totalidad a los demás ingresos por los que se deba pagar el impuesto y no se aplicará la fracción II de este artículo.'
            },
            {	c1: 'Fracción II',
            	c2:'II. Al total de percepciones por este concepto se restará una cantidad igual a la del último sueldo mensual ordinario y al resultado se le aplicará la tasa que correspondió al impuesto que señala la fracción anterior. El impuesto que resulte se sumará al calculado conforme a la fracción que antecede.'
            },
            {	c1: 'Segundo párrafo',
            	c2:'La tasa a que se refiere la fracción II que antecede se calculará dividiendo el impuesto señalado en la fracción I anterior entre la cantidad a la cual se le aplicó la tarifa del artículo 152 de esta Ley; el cociente así obtenido se multiplica por cien y el producto se expresa en por ciento.'
            }
          ]
        },
        { title1: 'LISR 96', title2: 'RETENCIONES Y ENTEROS MENSUALES DE PAGOS PROVISIONALES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Quienes hagan pagos por los conceptos a que se refiere este Capítulo están obligados a efectuar retenciones y enteros mensuales que tendrán el carácter de pagos provisionales a cuenta del impuesto anual. No se efectuará retención a las personas que en el mes únicamente perciban un salario mínimo general correspondiente al área geográfica del contribuyente.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CÁLCULO DE LA RETENCIÓN</b><br>La retención se calculará aplicando a la totalidad de los ingresos obtenidos en un mes de calendario, la siguiente:'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>BASE OPCIONAL PARA LA RETENCIÓN DEL IMPUESTO</b><br>Quienes hagan pagos por concepto de gratificación anual, participación de utilidades, primas dominicales y primas vacacionales, podrán efectuar la retención del impuesto de conformidad con los requisitos que establezca el Reglamento de esta Ley; en las disposiciones de dicho Reglamento se preverá que la retención se pueda hacer sobre los demás ingresos obtenidos durante el año de calendario.'
            },
           	{ c1: 'Cuarto párrafo',
              c2: '<b>DEDUCCIÓN DEL IMPUESTO LOCAL A LOS INGRESOS POR SALARIOS</b><br>Quienes hagan las retenciones a que se refiere este artículo, deberán deducir de la totalidad de los ingresos obtenidos en el mes de calendario, el impuesto local a los ingresos por salarios y en general por la prestación de un servicio personal subordinado que, en su caso, hubieran retenido en el mes de calendario de que se trate, siempre que la tasa de dicho impuesto no exceda del 5%.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>PRIMAS DE ANTIGÜEDAD, RETIRO, INDEMNIZACIÓN, ETC., POR SEPARACIÓN</b><br>Las personas que hagan pagos por los conceptos a que se refiere el artículo 95 de esta Ley, efectuarán la retención aplicando al ingreso total por este concepto, una tasa que se calculará dividiendo el impuesto correspondiente al último sueldo mensual ordinario, entre dicho sueldo; el cociente obtenido se multiplicará por cien y el producto se expresará en por ciento. Cuando los pagos por estos conceptos sean inferiores al último sueldo mensual ordinario, la retención se calculará aplicándoles la tarifa establecida en este artículo.<br>'
            },
          ]
        },
        { title1: 'RISR 151', title2: 'GRATIFICACIONES INFERIORES AL SMG ELEVADO A TREINTA DÍAS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para efectos del artículo 93, fracción XIV de la Ley, en el caso de que la gratificación sea inferior al monto equivalente al salario mínimo general del área geográfica del trabajador elevado a treinta días, no se pagará el Impuesto hasta por el monto de la gratificación otorgada aun cuando se calcule sobre un salario superior al mínimo.'
            },
            ]
           
        },
        { title1: 'RISR 174', title2: 'RETENCIÓN OPCIONAL EN PAGOS DE GRATIFICACIÓN, PTU Y PRIMAS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Tratándose de las remuneraciones por concepto de gratificación anual, participación de utilidades, primas dominicales y vacacionales a que se refiere el artículo 96 de la Ley, la persona que haga dichos pagos podrá optar por retener el Impuesto que corresponda conforme a lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: 'I. La remuneración de que se trate se dividirá entre 365 y el resultado se multiplicará por 30.4;'
            },
            { c1: 'Fracción II',
              c2: 'II. A la cantidad que se obtenga conforme a la fracción anterior, se le adicionará el ingreso ordinario por la prestación de un servicio personal subordinado que perciba el trabajador en forma regular en el mes de que se trate y al resultado se le aplicará el procedimiento establecido en el artículo 96 de la Ley;'
            },
            { c1: 'Fracción III',
              c2: 'III. El Impuesto que se obtenga conforme a la fracción anterior se disminuirá con el Impuesto que correspondería al ingreso ordinario por la prestación de un servicio personal subordinado a que se refiere dicha fracción, calculando este último sin considerar las demás remuneraciones mencionadas en este artículo;'
            },
            { c1: 'Fracción IV',
              c2: 'IV. El Impuesto a retener será el que resulte de aplicar a las remuneraciones a que se refiere este artículo, sin deducción alguna, la tasa a que se refiere la fracción siguiente, y'
            },
            { c1: 'Fracción V',
              c2: 'V. La tasa a que se refiere la fracción anterior, se calculará dividiendo el Impuesto que se determine en términos de la fracción III de este artículo entre la cantidad que resulte conforme a la fracción I de dicho artículo. El cociente se multiplicará por cien y el producto se expresará en por ciento.'
            },
            
           
          ]
        },
        { title1: 'LFT 48', title2: 'DERECHO DEL TRABAJADOR A LA REINSTALACIÓN O INDEMNIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El trabajador podrá solicitar ante la Autoridad Conciliadora, o ante el Tribunal si no existe arreglo conciliatorio, que se le reinstale en el trabajo que desempeñaba, o que se le indemnice con el importe de tres meses de salario, a razón del que corresponda a la fecha en que se realice el pago, observando previamente las disposiciones relativas al procedimiento de conciliación previsto en el artículo 684-A y subsiguientes.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>PAGO DE SALARIOS VENCIDOS</b><br>Si en el juicio correspondiente no comprueba el patrón la causa de la rescisión, el trabajador tendrá derecho, además, cualquiera que hubiese sido la acción intentada, a que se le paguen los salarios vencidos computados desde la fecha del despido hasta por un periodo máximo de doce meses, en términos de lo preceptuado en la última parte del párrafo anterior.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>GENERACIÓN Y PAGO DE INTERESES AL NO CONCLUIR EL PROCEDIMIENTO EN UN AÑO</b><br>Si al término del plazo señalado en el párrafo anterior no ha concluido el procedimiento o no se ha dado cumplimiento a la sentencia, (1) se pagarán también al trabajador los intereses que se generen sobre el importe de quince meses de salario, a razón del dos por ciento mensual, capitalizable al momento del pago. Lo dispuesto en este párrafo no será aplicable para el pago de otro tipo de indemnizaciones o prestaciones.<br>........................'
            },
            ]
        },
    	  { title1: 'LFT 50', title2: 'INDEMNIZACIÓN POR DESPIDO INJUSTIFICADO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las indemnizaciones a que se refiere el artículo anterior consistirán:'
            },
            { c1: 'Fracción I',
              c2: '<b>TIEMPO DETERMINADO MENOR O MAYOR DE UN AÑO</b><br>I. Si la relación de trabajo fuere por tiempo determinado menor de un año, en una cantidad igual al importe de los salarios de la mitad del tiempo de servicios prestados; si excediera de un año, en una cantidad igual al importe de los salarios de seis meses por el primer año y de veinte días por cada uno de los años siguientes en que hubiese prestado sus servicios;'
            },
            { c1: 'Fracción II',
              c2: '<b>TIEMPO INDETERMINADO</b><br>II. Si la relación de trabajo fuere por tiempo indeterminado, la indemnización consistirá en veinte días de salario por cada uno de los años de servicios prestados; y'
            },
            { c1: 'Fracción III',
              c2: '<b>TRES MESES DE SALARIO, SALARIOS VENCIDOS E INTERESES</b><br>III. Además de las indemnizaciones a que se refieren las fracciones anteriores, en el importe de tres meses de salario y el pago de los salarios vencidos e intereses, en su caso, en los términos previstos en el artículo 48 de esta Ley.'
            },
            ]
        },
        
        { title1: 'LFT 162', title2: 'PRIMA DE ANTIGÜEDAD MÍNIMA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los trabajadores de planta tienen derecho a una prima de antigüedad, de conformidad con las normas siguientes:'
            },
            { c1: 'Fracción I',
              c2: '<b>DÍAS QUE COMPRENDE LA PRIMA</b><br>I. La prima de antigüedad consistirá en el importe de doce días de salario, por cada año de servicios;'
            },
            { c1: 'Fracción II',
              c2: '<b>SALARIO BASE</b><br>II. Para determinar el monto del salario, se estará a lo dispuesto en los artículos 485 y 486;'
            },
            { c1: 'Fracción III',
              c2: '<b>PAGO A TRABAJADORES QUE SE SEPARAN VOLUNTARIA, JUSTIFICADA O INJUSTIFICADAMENTE DE LA EMPRESA</b><br>III. La prima de antigüedad se pagará a los trabajadores que se separen voluntariamente de su empleo, siempre que hayan cumplido quince años de servicios, por lo menos. Asimismo se pagará a los que se separen por causa justificada y a los que sean separados de su empleo, independientemente de la justificación o injustificación del despido;<br>........................'
            },
            { c1: 'Fracción V',
              c2: '<b>PAGO DE PRIMA A BENEFICIARIOS DEL TRABAJADOR</b><br>V. En caso de muerte del trabajador, cualquiera que sea su antigüedad, la prima que corresponda se pagará a las personas mencionadas en el artículo 501; y'
            },
            { c1: 'Fracción VI',
              c2: '<b>PAGO DE PRIMA DE ANTIGÜEDAD</b><br>VI. La prima de antigüedad a que se refiere este artículo se cubrirá a los trabajadores o a sus beneficiarios, independientemente de cualquier otra prestación que les corresponda.'
            },
            ]
        },
        { title1: 'LFT 485', title2: 'SALARIO MÍNIMO PARA INDEMNIZACIÓN POR RIESGO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'La cantidad que se tome como base para el pago de las indemnizaciones no podrá ser inferior al salario mínimo.'
            },
            ]
        },
      	{ title1: 'LFT 486', title2: 'SALARIO MÁXIMO PARA INDEMNIZACIÓN POR RIESGO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para determinar las indemnizaciones a que se refiere este Título, si el salario que percibe el trabajador excede del doble del salario mínimo del área geográfica de aplicación a que corresponda el lugar de prestación del trabajo, se considerará esa cantidad como salario máximo. Si el trabajo se presta en lugares de diferentes áreas geográficas de aplicación, el salario máximo será el doble del promedio de los salarios mínimos respectivos.'
            },
            ]
        },
        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
