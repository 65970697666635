<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col col-titles col-mid">
          <h1>Amortización de pérdidas fiscales</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">

        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Monto histórico de la pérdida fiscal:"
              label-for="i-co">

              <currency-input id="i-co" class="form-control"
                v-model="form.monto_historico_perdida" placeholder="$0.00" required min="1" oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.monto_historico_perdida != null" style="color: red;"><small>{{validation.monto_historico_perdida}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Año de la obtención de la pérdida fiscal:"
              label-for="i-anio">
              <b-form-select v-model="form.anio_obtencion" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Año al que corresponde la declaración anual a la que se pretende restar a la utilidad la pérdida fiscal:"
              label-for="i-anio">
              <b-form-select v-model="form.anio_declaracion" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
           
            <b-form-group class="custom-form-group-s2"
              label="¿La pérdida fiscal se generó en el ejercicio de inicio de operaciones?:"
              label-for="i-anio">
              <b-form-select v-model="form.perida_segenero" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Seleccione opción</b-form-select-option>
                  <b-form-select-option :value="0">No</b-form-select-option>
                  <b-form-select-option :value="1">Sí</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Mes del inicio de operaciones, en su caso:"
              label-for="i-mes" v-if="form.perida_segenero == 1">
                <b-form-select v-model="form.mes_inicio" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                </b-form-select>
                <div v-if="validation.mes_inicio != null" style="color: red;"><small>{{validation.mes_inicio}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Amortizará la pérdida fiscal contra algún pago provisional del ejercicio fiscal 2021?:"
              label-for="i-anio">
              <b-form-select v-model="form.amortizara_perdida" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Seleccione opción</b-form-select-option>
                  <b-form-select-option :value="0">No</b-form-select-option>
                  <b-form-select-option :value="1">Sí</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Mes del pago provisional al que se le pretende restar la pérdida fiscal en 2021:"
              label-for="i-mes" v-if="form.amortizara_perdida == 1">
                <b-form-select v-model="form.mes_pagoprovisional" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                </b-form-select>
                <div v-if="validation.mes_pagoprovisional != null" style="color: red;"><small>{{validation.mes_pagoprovisional}}</small></div>
            </b-form-group>


            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <h5 class="title">DATOS GENERALES</h5>

          <div class="row">
            <div class="col-12 col-line">
              <hr />
            </div>

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!--div class="col col-share">
                  <a class="btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div-->
              </div>
            </div>

            <div class="col-12 col-info">
              <p style="text-align:center"><b>Pérdida fiscal actualizada a Diciembre de {{detailInfo.anio_obtencion}} </b></p>

              <div class="row row-body">
                <div class="col col-detail">
                  Pérdida fiscal actualizada a Diciembre del {{detailInfo.anio_obtencion}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.perdida_fiscal_actualizada}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  INPC 2 último mes del ejercicio fiscal en que se generó la pérdida ({{detailInfo.inpc_dos_ultimomes_name}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_dos_ultimomes}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  INPC 1 del primer mes de la segunda mitad del ejercicio en que se generó ({{detailInfo.inpc_uno_primsegunda_name}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_uno_primsegunda}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Primer factor de actualización:
                </div>
                <div class="col col-med">
                  {{detailInfo.primer_factor_actualizacion}}
                </div>
              </div>

              <p style="text-align:center"><b>Pérdida fiscal actualizada al último mes de la primera mitad del ejercicio fiscal del {{detailInfo.anio_declaracion}} </b></p>

              <div class="row row-body">
                <div class="col col-detail">
                  Pérdida fiscal actualizada del último mes de la primera mitad del ejercicio fiscal del {{detailInfo.anio_declaracion}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.perdida_fiscal_actualizada_alulitmomes}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  INPC 2 úlitmo mes de la primera mitad del ejercicio en que se utiliza ({{detailInfo.inpc_dos_ultimomesprimermitad_name}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_dos_ultimomesprimermitad}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  INPC 1 última actualización o INPC 2 último mes del ejercicio fiscal en que se generó la pérdida ({{detailInfo.inpc_dos_ultimomes_dos_name}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_dos_ultimomes_dos}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Segundo factor de actualización:
                </div>
                <div class="col col-med">
                  {{detailInfo.segundo_factor_actualizacion}}
                </div>
              </div>

              <p style="text-align:center"><b>Amortización de la pérdida fiscal contra el pago provisional de {{detailInfo.name_mes_pagoprovisional}} {{detailInfo.anio_declaracion}}</b></p>
              <div class="row row-body">
                <div class="col col-detail">
                  Pérdida fiscal actualizada para aplicar el pago provisional de {{detailInfo.name_mes_pagoprovisional}} {{detailInfo.anio_declaracion}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.perdida_fiscal_actualizada_aplicar}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  INPC 2 último mes de la primera mitad del ejercicio en que se utiliza:
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_dos_ulitmomprimermitad}}
                </div>
              </div>

               <div class="row row-body">
                <div class="col col-detail">
                  INPC 1 última actualización o INPC 2 último mes del ejercicio fiscal en que se generó la pérdida:
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc_dos_ultimomes_tres}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Segundo factor de actualización para pagos provisional:
                </div>
                <div class="col col-med">
                  {{detailInfo.segundo_factor_actualizacion_pagosprovi}}
                </div>
              </div>

            </div>

            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

           
          </div>
        </div>
        <!-- Paso 2, FIN -->
        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->
      </div>
    </section>


    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
   
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
  import FlegalesComponent from './components/fleg-comp-amortizacion-perdidas-fiscales.vue';
export default {
  data(){
    return{
      showFLegalesComp: false,
      step: 1,

      form: {
        monto_historico_perdida:null,
		anio_obtencion:null,
		anio_declaracion:null,
		perida_segenero:null,
		mes_inicio:null,
		amortizara_perdida:null,
		mes_pagoprovisional:null,
      },

      formComp: {
        email: null
      },
      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      detailInfo:{

      },
      validation:{
        monto_historico_perdida:null,
		anio_obtencion:null,
		anio_declaracion:null,
		perida_segenero:null,
		mes_inicio:null,
		amortizara_perdida:null,
		mes_pagoprovisional:null,
        
      }
    }
  },

  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      	this.validation.monto_historico_perdida = null;
     	this.validation.mes_inicio = null;
      	this.validation.mes_pagoprovisional = null;
      	
      	event.preventDefault();
      	if (this.form.monto_historico_perdida == 0 || this.form.monto_historico_perdida == null) {
          	this.validation.monto_historico_perdida = '"Monto historíco de la pérdida fiscal" debe de ser mayor a 0';
          	return;
      	}

      	if (this.form.perida_segenero == 1) {
      		if (this.form.mes_inicio == null) {
      			this.validation.mes_inicio = 'Selecciona una opción';
          		return;
      		}
          	
      	}

      	if (this.form.amortizara_perdida == 1) {
      		if (this.form.mes_pagoprovisional == null) {
      			this.validation.mes_pagoprovisional = 'Selecciona una opción';
          		return;
      		}
          	
      	}

        this.$refs['modal-loading'].show();
        //$('#btn_calc').hide();
        event.preventDefault();
        axios.post(tools.url("/calculateTen"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al calcular la informacion');
        });
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

       

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    otherCalc(){
      location.reload();
    },
    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },
  },

  mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var years = [];
      for (var i = 2011; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }

      years.reverse();
      this.years = years;
  },
  components: {
    FlegalesComponent
  },
}
</script>
