import { render, staticRenderFns } from "./fleg-comp-isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subodinado.vue?vue&type=template&id=6a372890&lang=html&"
import script from "./fleg-comp-isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subodinado.vue?vue&type=script&lang=js&"
export * from "./fleg-comp-isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subodinado.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports