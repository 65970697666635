<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
       
        { title1: 'LISR 100', title2: 'INGRESOS QUE SE GRAVAN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Están obligadas al pago del impuesto establecido en esta Sección, las personas físicas que perciban ingresos derivados de la realización de actividades empresariales o de la prestación de servicios profesionales.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>RESIDENTES EN EL EXTRANJERO CON ESTABLECIMIENTO PERMANENTE EN MEXICO</b><br>Las personas físicas residentes en el extranjero que tengan uno o varios establecimientos permanentes en el país, pagarán el impuesto sobre la renta en los términos de esta Sección por los ingresos atribuibles a los mismos, derivados de las actividades empresariales o de la prestación de servicios profesionales.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>DEFINICIONES</b><br>Para los efectos de este Capítulo se consideran:'
            },
            { c1: 'Fracción I',
              c2: '<b>INGRESOS POR ACTIVIDADES EMPRESARIALES</b><br>I. Ingresos por actividades empresariales, los provenientes de la realización de actividades comerciales, industriales, agrícolas, ganaderas, de pesca o silvícolas.'
            },
            { c1: 'Fracción II',
              c2: '<b>INGRESOS POR PRESTACION DE SERVICIOS PROFESIONALES</b><br>II. Ingresos por la prestación de un servicio profesional, las remuneraciones que deriven de un servicio personal independiente y cuyos ingresos no estén considerados en el Capítulo I de este Título.'
            },
            { c1: 'Cuarto párrafo',
              c2: 'Se entiende que los ingresos los obtienen en su totalidad las personas que realicen la actividad empresarial o presten el servicio profesional.'
            },
          ]
        },
        { title1: 'LISR 101', title2: 'INGRESOS ACUMULABLES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para los efectos de esta Sección, se consideran ingresos acumulables por la realización de actividades empresariales o por la prestación de servicios profesionales, además de los señalados en el artículo anterior y en otros artículos de esta Ley, los siguientes:'
            },
            { c1: 'Fracción I',
              c2: '<b>CONDONACION, QUITAS, REMISIONES O PRESCRIPCION DE DEUDAS</b><br>I. Tratándose de condonaciones, quitas o remisiones, de deudas relacionadas con la actividad empresarial o con el servicio profesional, así como de las deudas antes citadas que se dejen de pagar por prescripción de la acción del acreedor, la diferencia que resulte de restar del principal actualizado por inflación, el monto de la quita, condonación o remisión, al momento de su liquidación o reestructuración, siempre y cuando la liquidación total sea menor al principal actualizado y se trate de quitas, condonaciones o remisiones otorgadas por instituciones del sistema financiero.<br>En el caso de condonaciones, quitas o remisiones de deudas otorgadas por personas distintas a instituciones del sistema financiero, se acumulará el monto total en dichas condonaciones, quitas o remisiones.<br><b>DEUDAS PERDONADAS A CONTRIBUYENTES EN CONCURSO</b><br>Los contribuyentes sujetos a un procedimiento de concurso, podrán disminuir el monto de las deudas perdonadas conforme al convenio suscrito con sus acreedores reconocidos, en los términos establecidos en la Ley de Concursos Mercantiles, de las pérdidas pendientes de disminuir que tengan en el ejercicio en el que dichos acreedores les perdonen las deudas citadas. Cuando el monto de las deudas perdonadas sea mayor a las pérdidas fiscales pendientes de disminuir, la diferencia que resulte no se considerará como ingreso acumulable, salvo que la deuda perdonada provenga de transacciones efectuadas entre y con partes relacionadas a que se refiere el artículo 179 de esta Ley.'
            },
            { c1: 'Fracción II',
              c2: '<b>ENAJENACION DE CUENTAS POR COBRAR Y TITULOS DE CREDITO DISTINTOS A ACCIONES</b><br>II. Los provenientes de la enajenación de cuentas y documentos por cobrar y de títulos de crédito distintos de las acciones, relacionados con las actividades a que se refiere este Capítulo.'
            },
            { c1: 'Fracción III',
              c2: '<b>RECUPERACION POR SEGUROS, FIANZAS, ETC.</b><br>III. Las cantidades que se recuperen por seguros, fianzas o responsabilidades a cargo de terceros, tratándose de pérdidas de bienes del contribuyente afectos a la actividad empresarial o al servicio profesional.'
            },
            { c1: 'Fracción IV',
              c2: '<b>CANTIDADES PARA GASTOS DE TERCEROS</b><br>IV. Las cantidades que se perciban para efectuar gastos por cuenta de terceros, salvo que dichos gastos sean respaldados con comprobantes fiscales expedidos a nombre de aquél por cuenta de quien se efectúa el gasto.'
            },
            { c1: 'Fracción V',
              c2: '<b>ENAJENACION DE OBRAS DE ARTE</b><br>V. Los derivados de la enajenación de obras de arte hechas por el contribuyente.'
            },
            { c1: 'Fracción VI',
              c2: '<b>AGENTES DE INSTITUCIONES DE CREDITO, DE SEGUROS, DE FIANZAS, ETC.</b><br>VI. Los obtenidos por agentes de instituciones de crédito, de seguros, de fianzas o de valores, por promotores de valores o de administradoras de fondos para el retiro, por los servicios profesionales prestados a dichas instituciones.'
            },
            { c1: 'Fracción VII',
              c2: '<b>EXPLOTACION DE UNA PATENTE ADUANAL</b><br>VII. Los obtenidos mediante la explotación de una patente aduanal.'
            },
            { c1: 'Fracción VIII',
              c2: '<b>DERECHOS DE AUTOR</b><br>VIII. Los obtenidos por la explotación de obras escritas, fotografías o dibujos, en libros, periódicos, revistas o en las páginas electrónicas vía Internet, o bien, la reproducción en serie de grabaciones de obras musicales y en general cualquier otro que derive de la explotación de derechos de autor.'
            },
            { c1: 'Fracción IX',
              c2: '<b>INTERESES COBRADOS</b><br>IX. Los intereses cobrados derivados de la actividad empresarial o de la prestación de servicios profesionales, sin ajuste alguno.'
            },
            { c1: 'Fracción X',
              c2: '<b>DEVOLUCIONES, DESCUENTOS O BONIFICACIONES</b><br>X. Las devoluciones que se efectúen o los descuentos o bonificaciones que se reciban, siempre que se hubiese efectuado la deducción correspondiente.'
            },
            { c1: 'Fracción XI',
              c2: '<b>GANANCIA POR ENAJENACION DE ACTIVOS</b><br>XI. La ganancia derivada de la enajenación de activos afectos a la actividad.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>DETERMINACION PRESUNTIVA POR LA AUTORIDAD</b><br>Los ingresos determinados presuntivamente por las autoridades fiscales, en los casos en que proceda conforme al Código Fiscal de la Federación, se considerarán ingresos acumulables en los términos de esta Sección, cuando en el ejercicio de que se trate el contribuyente perciba preponderantemente ingresos que correspondan a actividades empresariales o a la prestación de servicios profesionales.'
            },
            { c1: 'Tercer párrafo',
              c2: 'Para los efectos del párrafo anterior, se considera que el contribuyente percibe ingresos preponderantemente por actividades empresariales o por prestación de servicios profesionales, cuando dichos ingresos representen en el ejercicio de que se trate o en el anterior, más del 50% de los ingresos acumulables del contribuyente.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>RESIDENTES EN EL EXTRANJERO</b><br>Las autoridades fiscales podrán determinar la utilidad de los establecimientos permanentes en el país de un residente en el extranjero, con base en las utilidades totales de dicho residente, considerando la proporción que los ingresos o los activos de los establecimientos en México representen del total de los ingresos o de activos, respectivamente.'
            },
           
          ]
        },
        { title1: 'LISR 102', title2: 'MOMENTO DE ACUMULACIÓN DE LOS INGRESOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para los efectos de esta Sección, los ingresos se consideran acumulables en el momento en que sean efectivamente percibidos.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>MOMENTO EN QUE SE CONSIDERAN EFECTIVAMENTE PERCIBIDOS LOS INGRESOS</b><br>Los ingresos se consideran efectivamente percibidos cuando se reciban en efectivo, en bienes o en servicios, aun cuando aquéllos correspondan a anticipos, a depósitos o a cualquier otro concepto, sin importar el nombre con el que se les designe. Igualmente se considera percibido el ingreso cuando el contribuyente reciba títulos de crédito emitidos por una persona distinta de quien efectúa el pago. Cuando se perciban en cheque, se considerará percibido el ingreso en la fecha de cobro del mismo o cuando los contribuyentes transmitan los cheques a un tercero, excepto cuando dicha transmisión sea en procuración. También se entiende que es efectivamente percibido cuando el interés del acreedor queda satisfecho mediante cualquier forma de extinción de las obligaciones.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>CONDONACION, QUITA, REMISION O PRESCRIPCION DE DEUDAS</b><br>Tratándose de los ingresos a que se refiere la fracción I del artículo 101 de esta Ley, éstos se considerarán efectivamente percibidos en la fecha en que se convenga la condonación, la quita o la remisión, o en la que se consume la prescripción.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>EXPORTACION DE BIENES</b><br>En el caso de enajenación de bienes que se exporten se deberá acumular el ingreso cuando efectivamente se perciba. En el caso de que no se perciba el ingreso dentro de los doce meses siguientes a aquél en el que se realice la exportación, se deberá acumular el ingreso una vez transcurrido dicho plazo.'
            },
            
          ]
        },
        { title1: 'LISR 103', title2: 'DEDUCCIONES AUTORIZADAS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las personas físicas que obtengan ingresos por actividades empresariales o servicios profesionales, podrán efectuar las deducciones siguientes:'
            },
           	{ c1: 'Fracción I',
              c2: '<b>DEVOLUCIONES, DESCUENTOS O BONIFICACIONES</b><br>I. Las devoluciones que se reciban o los descuentos o bonificaciones que se hagan, siempre que se hubiese acumulado el ingreso correspondiente.'
            },
            { c1: 'Fracción II',
              c2: '<b>ADQUISICIONES DE MERCANCIAS</b><br>II. Las adquisiciones de mercancías, así como de materias primas, productos semiterminados o terminados, que utilicen para prestar servicios, para fabricar bienes o para enajenarlos.<br><br><b>ACTIVOS FIJOS, TERRENOS, ACCIONES, ETC.</b><br>No serán deducibles conforme a esta fracción los activos fijos, los terrenos, las acciones, partes sociales, obligaciones y otros valores mobiliarios, los títulos valor que representen la propiedad de bienes, excepto certificados de depósito de bienes o mercancías, la moneda extranjera, las piezas de oro o de plata que hubieran tenido el carácter de moneda nacional o extranjera ni las piezas denominadas onzas troy.<br>En el caso de ingresos por enajenación de terrenos y de acciones, se estará a lo dispuesto en los artículos 19 y 22 de esta Ley, respectivamente.'
            },
            { c1: 'Fracción III',
              c2: '<b>GASTOS</b><br>III. Los gastos.'
            },
            { c1: 'Fracción IV',
              c2: '<b>INVERSIONES</b><br>IV. Las inversiones.'
            },
            { c1: 'Fracción V',
              c2: '<b>INTERESES PAGADOS</b><br>V. Los intereses pagados derivados de la actividad empresarial o servicio profesional, sin ajuste alguno, así como los que se generen por capitales tomados en préstamo siempre y cuando dichos capitales hayan sido invertidos en los fines de las actividades a que se refiere esta Sección y se obtenga el comprobante fiscal correspondiente.'
            },
            { c1: 'Fracción VI',
              c2: '<b>CUOTAS AL IMSS A CARGO DE LOS PATRONES</b><br>VI. Las cuotas a cargo de los patrones pagadas al Instituto Mexicano del Seguro Social.'
            },
            { c1: 'Fracción VII',
              c2: '<b>IMPUESTO LOCAL PAGADO</b><br>VII. Los pagos efectuados por el impuesto local sobre los ingresos por actividades empresariales o servicios profesionales.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>EXTRANJEROS CON ESTABLECIMIENTO PERMANENTE</b><br>Tratándose de personas físicas residentes en el extranjero que tengan uno o varios establecimientos permanentes en el país, podrán efectuar las deducciones que correspondan a las actividades del establecimiento permanente, ya sean las erogadas en México o en cualquier otra parte, aun cuando se prorrateen con algún establecimiento ubicado en el extranjero, aplicando al efecto lo dispuesto en el artículo 26 de esta Ley.'
            },
            { c1: 'Tercer párrafo',
              c2: 'Los contribuyentes a que se refiere esta Sección, considerarán los gastos e inversiones no deducibles del ejercicio, en los términos del artículo 28 de esta Ley.'
            },
            
          ]
        },
        { title1: 'LISR 104', title2: 'DEDUCCIÓN POR INVERSIONES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los contribuyentes a que se refiere esta Sección determinarán la deducción por inversiones aplicando lo dispuesto en la Sección II del Capítulo II del Título II de esta Ley. Para estos efectos, se consideran inversiones las señaladas en el artículo 32 de esta Ley.'
            },
            { c1: 'Segundo párrafo',
              c2: 'Para los efectos de este artículo, los por cientos de deducción se aplicarán sobre el monto original de la inversión, aun cuando ésta no se haya pagado en su totalidad en el ejercicio en que proceda su deducción. Cuando no se pueda separar el monto original de la inversión de los intereses que en su caso se paguen por el financiamiento, el por ciento que corresponda se aplicará sobre el monto total, en cuyo caso, los intereses no podrán deducirse en los términos de la fracción V del artículo 103 de esta Ley.'
            },
          ]
        },
        { title1: 'LISR 106', title2: 'PAGOS PROVISIONALES MENSUALES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los contribuyentes a que se refiere esta Sección, efectuarán pagos provisionales mensuales a cuenta del impuesto del ejercicio, a más tardar el día 17 del mes inmediato posterior a aquél al que corresponda el pago, mediante declaración que presentarán ante las oficinas autorizadas. El pago provisional se determinará restando de la totalidad de los ingresos a que se refiere esta Sección obtenidos en el periodo comprendido desde el inicio del ejercicio y hasta el último día del mes al que corresponde el pago, las deducciones autorizadas en esta Sección correspondientes al mismo periodo y la participación de los trabajadores en las utilidades de las empresas pagada en el ejercicio, en los términos del artículo 123 de la Constitución Política de los Estados Unidos Mexicanos y, en su caso, las pérdidas fiscales ocurridas en ejercicios anteriores que no se hubieran disminuido.'
            },
            { c1: 'Segundo párrafo',
              c2: 'Al resultado que se obtenga conforme al párrafo anterior, se le aplicará la tarifa que se determine de acuerdo a lo siguiente:'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>TARIFA APLICABLE</b><br>Se tomará como base la tarifa del artículo 96 de esta Ley, sumando las cantidades correspondientes a las columnas relativas al límite inferior, límite superior y cuota fija, que en los términos de dicho artículo resulten para cada uno de los meses del periodo a que se refiere el pago provisional de que se trate, y que correspondan al mismo renglón identificado por el mismo por ciento para aplicarse sobre el excedente del límite inferior. Las autoridades fiscales realizarán las operaciones aritméticas previstas en este párrafo para calcular la tarifa aplicable y la publicará en el Diario Oficial de la Federación.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>ACREDITAMIENTO DE PAGOS PROVISIONALES ANTERIORES</b><br>Contra el pago provisional determinado conforme a este artículo, se acreditarán los pagos provisionales del mismo ejercicio efectuados con anterioridad.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>RETENCION DE UN 10% DE ISR POR PERSONAS MORALES</b><br>Cuando los contribuyentes presten servicios profesionales a las personas morales, éstas deberán retener, como pago provisional, el monto que resulte de aplicar la tasa del 10% sobre el monto de los pagos que les efectúen, sin deducción alguna, debiendo proporcionar a los contribuyentes comprobante fiscal en el que conste el monto del impuesto retenido, el cual deberá enterarse, en su caso, conjuntamente con las retenciones señaladas en el artículo 96 de esta Ley. El impuesto retenido en los términos de este párrafo será acreditable contra el impuesto a pagar que resulte en los pagos provisionales de conformidad con este artículo.'
            },
          ]
        },
        { title1: 'LISR 109', title2: 'CÁLCULO DEL IMPUESTO DEL EJERCICIO',
          content: [
            { c1: 'Segundo párrafo',
              c2: '....................<br><b>PERDIDA FISCAL</b><br>La pérdida fiscal se obtendrá cuando los ingresos a que se refiere esta Sección obtenidos en el ejercicio sean menores a las deducciones autorizadas en el mismo. Al resultado obtenido se le adicionará la participación de los trabajadores en las utilidades pagada en el ejercicio a que se refiere el párrafo anterior. En este caso se estará a lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: '<b>PLAZO PARA AMORTIZACION DE PERDIDAS</b><br>I. La pérdida fiscal ocurrida en un ejercicio podrá disminuirse de la utilidad fiscal determinada en los términos de esta Sección, de los diez ejercicios siguientes, hasta agotarla.<br><b>ACTUALIZACION DEL MONTO DE LA PERDIDA FISCAL</b><br>Para los efectos de esta fracción, el monto de la pérdida fiscal ocurrida en un ejercicio, se actualizará multiplicándola por el factor de actualización correspondiente al periodo comprendido desde el primer mes de la segunda mitad del ejercicio en el que ocurrió y hasta el último mes del mismo ejercicio. La parte de la pérdida fiscal de ejercicios anteriores ya actualizada pendiente de aplicar contra utilidades fiscales se actualizará multiplicándola por el factor de actualización correspondiente al periodo comprendido desde el mes en el que se actualizó por última vez y hasta el último mes de la primera mitad del ejercicio en el que se aplicará.<br>Para los efectos del párrafo anterior, cuando sea impar el número de meses del ejercicio en que ocurrió la pérdida fiscal, se considerará como primer mes de la segunda mitad, el mes inmediato posterior al que corresponda la mitad del ejercicio.<br><b>PERDIDA DEL DERECHO DE AMORTIZACION</b><br>Cuando el contribuyente no disminuya en un ejercicio la pérdida fiscal ocurrida en ejercicios anteriores, pudiéndolo haber hecho conforme a este artículo, perderá el derecho a hacerlo posteriormente hasta por la cantidad en que pudo haberlo efectuado.'
            },
            { c1: 'Fracción II',
              c2: '<b>DERECHO DE DISMINUIR PERDIDAS FISCALES</b><br>II. El derecho de disminuir pérdidas fiscales es personal del contribuyente que las sufre y no podrá ser transmitido por acto entre vivos ni como consecuencia de la enajenación del negocio. En el caso de realizarse actividades empresariales, sólo por causa de muerte podrá transmitirse el derecho a los herederos o legatarios, que continúen realizando las actividades empresariales de las que derivó la pérdida.<br>Las pérdidas fiscales que obtengan los contribuyentes por la realización de las actividades a que se refiere esta Sección, sólo podrán ser disminuidas de la utilidad fiscal derivada de las propias actividades a que se refiere la misma.<br>…………………………………………'
            },
            
           
          ]
        },
        { title1: 'LIVA 1', title2: 'SUJETOS DEL IMPUESTO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Están obligadas al pago del impuesto al valor agregado establecido en esta Ley, las personas físicas y las morales que, en territorio nacional, realicen los actos o actividades siguientes:'
            },
            { c1: 'Fracción I',
              c2: 'Enajenen bienes.'
            },
            { c1: 'Fracción II',
              c2: 'Presten servicios independientes.'
            },
            { c1: 'Fracción III',
              c2: 'Otorguen el uso o goce temporal de bienes.'
            },
            { c1: 'Fracción IV',
              c2: 'Importen bienes o servicios.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>TASA DE IMPUESTO</b><br>El impuesto se calculará aplicando a los valores que señala esta Ley, la tasa del 16%. El impuesto al valor agregado en ningún caso se considerará que forma parte de dichos valores.'

            },
            /*{ c1: 'Penúltimo párrafo',
              c2: '…………………………<br><b>PAGO DEL IMPUESTO</b><br>El contribuyente pagará en las oficinas autorizadas la diferencia entre el impuesto a su cargo y el que le hubieran trasladado o el que él hubiese pagado en la importación de bienes o servicios, siempre que sean acreditables en los términos de esta Ley. En su caso, el contribuyente disminuirá del impuesto a su cargo, el impuesto que se le hubiere retenido.'
            },*/
           
          ]
        },
        { title1: 'LIVA 4', title2: 'CONCEPTO DE ACREDITAMIENTO',
          content: [
          { c1: 'Primer párrafo',
              c2: 'El acreditamiento consiste en restar el impuesto acreditable, de la cantidad que resulte de aplicar a los valores señalados en esta Ley la tasa que corresponda según sea el caso.'

            },
            { c1: 'Segundo párrafo',
              c2: '<b>CONCEPTO DE IMPUESTO ACREDITABLE</b><br>Para los efectos del párrafo anterior, se entiende por impuesto acreditable el impuesto al valor agregado que haya sido trasladado al contribuyente y el propio impuesto que él hubiese pagado con motivo de la importación de bienes o servicios, en el mes de que se trate.'

            },
            { c1: 'Último párrafo',
              c2: '...........................<br><b>TRANSMISION DEL DERECHO AL ACREDITAMIENTO</b><br>El derecho al acreditamiento es personal para los contribuyentes del impuesto al valor agregado y no podrá ser trasmitido por acto entre vivos, excepto tratándose de fusión. En el caso de escisión, el acreditamiento del impuesto pendiente de acreditar a la fecha de la escisión sólo lo podrá efectuar la sociedad escindente. Cuando desaparezca la sociedad escindente, se estará a lo dispuesto en el antepenúltimo párrafo del artículo  14-B del Código Fiscal de la Federación.'
            },
           
          ]
        },
        { title1: 'LIVA 5-D', title2: 'CÁLCULO DEL IMPUESTO MENSUAL',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El impuesto se calculará por cada mes de calendario, salvo los casos señalados en los artículos 5o.-E, 5o.-F y 33 de esta Ley.'

            },
            { c1: 'Segundo párrafo',
              c2: '...........................<br><b>PLAZO PARA PAGAR EL IMPUESTO</b><br>Los contribuyentes efectuarán el pago del impuesto mediante declaración que presentarán ante las oficinas autorizadas a más tardar el día 17 del mes siguiente al que corresponda el pago.'
            },
            { c1: 'Tercer párrafo',
              c2: 'El pago mensual será la diferencia entre el impuesto que corresponda al total de las actividades realizadas en el mes por el que se efectúa el pago, a excepción de las importaciones de bienes tangibles, y las cantidades por las que proceda el acreditamiento determinadas en los términos de esta Ley. En su caso, el contribuyente disminuirá del impuesto que corresponda al total de sus actividades, el impuesto que se le hubiere retenido en dicho mes.'

            },
          ]
        },
        { title1: 'LIVA 6', title2: 'ACREDITAMIENTO O DEVOLUCIÓN DE SALDOS A FAVOR',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Cuando en la declaración de pago resulte saldo a favor, el contribuyente únicamente podrá acreditarlo contra el impuesto a su cargo que le corresponda en los meses siguientes hasta agotarlo o solicitar su devolución. Cuando se solicite la devolución deberá ser sobre el total del saldo a favor.'

            },
            { c1: 'Segundo párrafo',
              c2: '...........................<br><b>NO ACREDITAMIENTO DE SALDOS CUYA DEVOLUCION SE SOLICITE</b><br>Los saldos cuya devolución se solicite no podrán acreditarse en declaraciones posteriores.'
            },
           
          ]
        },

        
        


       
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
