<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'CFF 17-A', title2: 'ACTUALIZACIÓN DE CONTRIBUCIONES, APROVECHAMIENTOS O DEVOLUCIONES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El monto de las contribuciones, aprovechamientos, así como de las devoluciones a cargo del fisco federal, se actualizará por el transcurso del tiempo y con motivo de los cambios de precios en el país, para lo cual se aplicará el factor de actualización a las cantidades que se deban actualizar. Dicho factor se obtendrá dividiendo el Indice Nacional de Precios al Consumidor del mes anterior al más reciente del periodo entre el citado índice correspondiente al mes anterior al más antiguo de dicho periodo. Las contribuciones, los aprovechamientos, así como las devoluciones a cargo del fisco federal, no se actualizarán por fracciones de mes.<br>…………………………………………………'
            },
            { c1: 'Tercer párrafo',
              c2: 'Los valores de bienes u operaciones se actualizarán de acuerdo con lo dispuesto por este artículo, cuando las leyes fiscales así lo establezcan. Las disposiciones señalarán en cada caso el periodo de que se trate.'
            },
            { c1: 'Cuarto párrafo',
              c2: 'Las cantidades actualizadas conservan la naturaleza jurídica que tenían antes de la actualización. El monto de ésta, determinado en los pagos provisionales, definitivos y del ejercicio, no será deducible ni acreditable.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>CASO EN QUE EL FACTOR DE ACTUALIZACIÓN SERÁ 1</b><br>Cuando el resultado de la operación a que se refiere el primer párrafo de este artículo sea menor a 1, el factor de actualización que se aplicará al monto de las contribuciones, aprovechamientos y devoluciones a cargo del fisco federal, así como a los valores de bienes u operaciones de que se traten, será 1.<br>…………………………………………………'
            },
            { c1: 'Octavo párrafo',
              c2: '<b>AJUSTE A DECENAS DE PESOS</b><br>Para determinar el monto de las cantidades a que se refieren los párrafos sexto y séptimo de este artículo, se considerarán, inclusive, las fracciones de peso; no obstante lo anterior, dicho monto se ajustará para que las cantidades de 0.01 a 5.00 pesos en exceso de una decena, se ajusten a la decena inmediata anterior y de 5.01 a 9.99 pesos en exceso de una decena, se ajusten a la decena inmediata superior.<br>…………………………………………………'
            },
            { c1: 'Decimo párrafo',
              c2: '<b>FACTORES O PROPORCIONES HASTA EL DIEZMILÉSIMO</b><br>Cuando de conformidad con las disposiciones fiscales se deban realizar operaciones aritméticas, con el fin de determinar factores o proporciones, las mismas deberán calcularse hasta el diezmilésimo.'
            },
          ]
        },
        { title1: 'CFF 66', title2: 'PAGO DE CONTRIBUCIONES EN PARCIALIDADES O DIFERIDO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las autoridades fiscales, a petición de los contribuyentes, podrán autorizar el pago a plazos, ya sea en parcialidades o diferido, de las contribuciones omitidas y de sus accesorios sin que dicho plazo exceda de doce meses para pago diferido y de treinta y seis meses para pago en parcialidades, siempre y cuando los contribuyentes:'
            },
            { c1: 'Fracción I',
              c2: '<b>PRESENTACIÓN DEL FORMATO</b><br>I. Presenten el formato que se establezca para tales efectos, por el Servicio de Administración Tributaria.<br>La modalidad del pago a plazos elegida por el contribuyente en el formato de la solicitud de autorización de pago a plazos podrá modificarse para el crédito de que se trate por una sola ocasión, siempre y cuando el plazo en su conjunto no exceda del plazo máximo establecido en el presente artículo.'
            },
            { c1: 'Fracción II',
              c2: '<b>PAGO PARCIAL DEL CRÉDITO FISCAL. INTEGRACIÓN DEL MONTO TOTAL DEL ADEUDO</b><br>II. Paguen el 20% del monto total del crédito fiscal al momento de la solicitud de autorización del pago a plazos. El monto total del adeudo se integrará por la suma de los siguientes conceptos:<br>a) El monto de las contribuciones omitidas actualizado desde el mes en que se debieron pagar y hasta aquél en que se solicite la autorización.<br>b) Las multas que correspondan actualizadas desde el mes en que se debieron pagar y hasta aquél en que se solicite la autorización.<br>c) Los accesorios distintos de las multas que tenga a su cargo el contribuyente a la fecha en que solicite la autorización.<br>La actualización que corresponda al periodo mencionado se efectuará conforme a lo previsto por el artículo 17-A de este Código.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>PAGO A PLAZOS A CONTRIBUYENTES QUE CORRIJAN SU SITUACIÓN FISCAL</b><br>Las autoridades fiscales, a petición de los contribuyentes que corrijan su situación fiscal durante cualquier etapa dentro del ejercicio de facultades de comprobación y hasta antes de que se emita la resolución que determine el crédito fiscal, podrán autorizar el pago a plazos de las contribuciones omitidas y de sus accesorios, ya sea en forma diferida o en parcialidades, en condiciones distintas a las previstas en el primer párrafo de este artículo, cuando el 40% del monto del adeudo a corregir informado por la autoridad durante el ejercicio de las facultades de comprobación represente más de la utilidad fiscal del último ejercicio fiscal en que haya tenido utilidad fiscal, para lo cual se deberá seguir el siguiente procedimiento:'
            },
            { c1: 'Fracción I',
              c2: '<b>SOLICITUD Y PROYECTO DE PAGOS</b><br>I. El contribuyente presentará la solicitud, así como un proyecto de pagos estableciendo fechas y montos concretos.'
            },
            { c1: 'Fracción II',
              c2: '<b>VALORACIÓN Y EMISIÓN DE RESOLUCIÓN DE ACEPTACIÓN O NEGACIÓN</b><br>II. La autoridad, una vez recibida la solicitud y el proyecto de pagos procederá a efectuar la valoración y emitirá una resolución de aceptación o negación de la propuesta de pagos, según corresponda, dentro del plazo de quince días contados a partir del día siguiente a aquel en que se recibió la solicitud.'
            },
            { c1: 'Fracción III',
              c2: '<b>OBLIGACIÓN DE EFECTUAR LOS PAGOS EN LOS MONTOS Y LAS FECHAS EN QUE SE HAYAN AUTORIZADO</b><br>III. Una vez que surta efectos la notificación de la resolución, en caso de que se haya autorizado la propuesta, el contribuyente tendrá la obligación de efectuar los pagos en los montos y las fechas en que se le haya autorizado. En caso de incumplimiento con alguno de dichos pagos, la autoridad procederá a requerir el pago del remanente a través del procedimiento administrativo de ejecución.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>RESOLUCIÓN QUE HAYA NEGADO LA AUTORIZACIÓN DEL PROYECTO DE PAGO</b><br>En el caso de que en la resolución a que se refiere la fracción anterior se haya negado la autorización del proyecto de pago presentado por el contribuyente, la autoridad fiscal procederá a concluir el ejercicio de facultades de comprobación y emitirá la resolución determinativa de crédito fiscal que corresponda.'
            },
            
          ]
        },
        {
          title1: 'CFF 66-A', title2: 'PROCEDIMIENTOS PARA EL PAGO EN PARCIALIDADES O DIFERIDO',
          	content: [
            	{ 
            		c1: 'Primer párrafo',
              		c2: 'Para los efectos de la autorización a que se refiere el artículo anterior se estará a lo siguiente: '
            	},
           		{ 
            		c1: 'Fracción I',
              		c2: '<b>PAGO EN PARCIALIDADES</b><br>I. Tratándose de la autorización del pago a plazos en parcialidades, el saldo que se utilizará para el cálculo de las parcialidades será el resultado de disminuir el pago correspondiente al 20% señalado en la fracción II del artículo anterior, del monto total del adeudo a que hace referencia dicha fracción.<br>El monto de cada una de las parcialidades deberá ser igual, y pagadas en forma mensual y sucesiva, para lo cual se tomará como base el saldo del párrafo anterior, el plazo elegido por el contribuyente en su solicitud de autorización de pago a plazos y la tasa mensual de recargos por prórroga que incluye actualización de acuerdo a la Ley de Ingresos de la Federación vigente en la fecha de la solicitud de autorización de pago a plazos en parcialidades.<br>Cuando no se paguen oportunamente los montos de los pagos en parcialidades autorizados, el contribuyente estará obligado a pagar recargos por los pagos extemporáneos sobre el monto total de las parcialidades no cubiertas actualizadas, de conformidad con los artículos 17-A y 21 de este Código, por el número de meses o fracción de mes desde la fecha en que se debió realizar el pago y hasta que éste se efectúe.'
            	},
            	{ 
            		c1: 'Fracción II',
              		c2: '<b>PAGO EN FORMA DIFERIDA</b><br>II. Tratándose de la autorización del pago a plazos de forma diferida, el monto que se diferirá será el resultado de restar el pago correspondiente al 20% señalado en la fracción II del artículo anterior, del monto total del adeudo a que hace referencia dicha fracción.<br>El monto a liquidar por el contribuyente, se calculará adicionando al monto referido en el párrafo anterior, la cantidad que resulte de multiplicar la tasa de recargos por prórroga que incluye actualización de acuerdo a la Ley de Ingresos de la Federación, vigente en la fecha de la solicitud de autorización de pago a plazos de forma diferida, por el número de meses, o fracción de mes transcurridos desde la fecha de la solicitud de pago a plazos de forma diferida y hasta la fecha señalada por el contribuyente para liquidar su adeudo y por el monto que se diferirá.<br>El monto para liquidar el adeudo a que se hace referencia en el párrafo anterior, deberá cubrirse en una sola exhibición a más tardar en la fecha de pago especificada por el contribuyente en su solicitud de autorización de pago a plazos.'
            	},
            	{ 
            		c1: 'Fracción III',
              		c2: '<b>GARANTÍA DEL INTERÉS FISCAL</b><br>III. Una vez recibida la solicitud de autorización de pago a plazos, ya sea en parcialidades o diferido, de las contribuciones omitidas y de sus accesorios, la autoridad exigirá la garantía del interés fiscal en relación al 80% del monto total del adeudo al que se hace referencia en la fracción II del artículo 66 de este Código, más la cantidad que resulte de aplicar la tasa de recargos por prórroga y por el plazo solicitado de acuerdo a lo dispuesto en las fracciones I y II de este artículo.<br>La autoridad podrá dispensar la garantía del interés fiscal en los casos que señale el Servicio de Administración Tributaria mediante reglas de carácter general.'
            	},
            	{ 
            		c1: 'Fracción IV',
              		c2: '<b>CAUSAS DE REVOCACIÓN DE LA AUTORIZACIÓN</b><br>IV. Se revocará la autorización para pagar a plazos en parcialidades o en forma diferida, cuando:<br>a) No se otorgue, desaparezca o resulte insuficiente la garantía del interés fiscal, en los casos que no se hubiere dispensado, sin que el contribuyente dé nueva garantía o amplíe la que resulte insuficiente.<br>b) El contribuyente se encuentre sometido a un procedimiento de concurso mercantil o sea declarado en quiebra.<br>c) Tratándose del pago en parcialidades el contribuyente no cumpla en tiempo y monto con tres parcialidades o, en su caso, con la última.<br>d) Tratándose del pago diferido, se venza el plazo para realizar el pago y éste no se efectúe.<br>En los supuestos señalados en los incisos anteriores las autoridades fiscales requerirán y harán exigible el saldo mediante el procedimiento administrativo de ejecución.<br>El saldo no cubierto en el pago a plazos se actualizará y causará recargos, de conformidad con lo establecido en los artículos 17-A y 21 de este Código, desde la fecha en que se haya efectuado el último pago conforme a la autorización respectiva.'
            	},
            	{
            		c1: 'Fracción V',
              		c2: '<b>ORDEN DE APLICACIÓN DE LOS PAGOS EFECTUADOS</b><br>V. Los pagos efectuados durante la vigencia de la autorización se deberán aplicar al periodo más antiguo, en el siguiente orden:<br>a) Recargos por prórroga.<br>b) Recargos por mora.<br>c) Accesorios en el siguiente orden:<br>1. Multas.<br>2. Gastos extraordinarios.<br>3. Gastos de ejecución.<br>4. Recargos.<br>5. Indemnización a que se refiere el séptimo párrafo del artículo 21 de este Código.<br>d) Monto de las contribuciones omitidas, a las que hace referencia el inciso a) de la fracción II del artículo 66 de este Código.'
            	},
            	{
            		c1: 'Fracción VI',
              		c2: '<b>CONTRIBUCIONES POR LAS QUE NO PROCEDE LA AUTORIZACIÓN</b><br>VI. No procederá la autorización a que se refiere este artículo tratándose de:<br>a) Contribuciones que debieron pagarse en el año de calendario en curso o las que debieron pagarse en los seis meses anteriores al mes en el que se solicite la autorización, excepto en los casos de aportaciones de seguridad social.<br>b) Contribuciones y aprovechamientos que se causen con motivo de la importación y exportación de bienes o servicios.<br>c) Contribuciones retenidas, trasladadas o recaudadas.'
            	},
            	{ 
            		c1: 'Segundo párrafo',
              		c2: '<b>USO INDEBIDO DEL PAGO A PLAZOS</b><br>La autoridad fiscal podrá determinar y cobrar el saldo de las diferencias que resulten por la presentación de declaraciones, en las cuales, sin tener derecho al pago a plazos, los contribuyentes hagan uso en forma indebida de dicho pago a plazos, entendiéndose como uso indebido cuando se solicite cubrir las contribuciones y aprovechamientos que debieron pagarse por motivo de la importación y exportación de bienes y servicios, contribuciones que debieron pagarse en el año de calendario en curso o las que debieron pagarse en los seis meses anteriores, al mes en el que se solicite la autorización, cuando se trate de contribuciones retenidas, trasladadas o recaudadas; cuando procediendo el pago a plazos, no se presente la solicitud de autorización correspondiente en los plazos establecidos en las reglas de carácter general que establezca el Servicio de Administración Tributaria, y cuando dicha solicitud no se presente con todos los requisitos a que se refiere el artículo 66 de este Código.'
            	},
            	{ 
            		c1: 'Tercer párrafo',
              		c2: '<b>ACTUALIZACIÓN INCLUIDA EN LA TASA DE RECARGOS</b><br>Durante el periodo que el contribuyente se encuentre pagando a plazos en los términos de las fracciones I y II del presente artículo, las cantidades determinadas, no serán objeto de actualización, debido a que la tasa de recargos por prórroga la incluye, salvo que el contribuyente se ubique en alguna causal de revocación, o cuando deje de pagar en tiempo y monto alguna de las parcialidades, supuestos en los cuales se causará ésta de conformidad con lo previsto por el artículo 17-A de este Código, desde la fecha en que debió efectuar el último pago y hasta que éste se realice.'
            	},
            ]
        },
        {
          title1: 'RCFF 65', title2: 'AUTORIZACIÓN PARA EL PAGO A PLAZOS, EN PARCIALIDADES O DIFERIDO',
            content: [
            	{ 
            		c1: '',
              		c2: 'Para los efectos del artículo 66, primer párrafo del Código, cuando el contribuyente solicite autorización de pago a plazos, en parcialidades o diferido, en tanto se resuelve su solicitud, deberá realizar los pagos mensuales subsecuentes, de acuerdo con el número de parcialidades solicitadas, a más tardar el mismo día de calendario que corresponda al día en el que fue efectuado el pago a que se refiere el artículo 66, fracción II del Código o, en su caso, la fecha propuesta para el pago diferido, aplicando la tasa de recargos correspondiente, de acuerdo a lo establecido en el artículo 66-A del Código.<br>Si previo a la autorización del pago a plazos, el contribuyente no cumple en tiempo o con el monto establecido para cualquiera de las parcialidades, no pague en la fecha propuesta el monto diferido, u omita garantizar el interés fiscal estando obligado a ello, se considerará que se ha desistido de su solicitud de pago a plazos, debiendo cubrir el saldo insoluto de las contribuciones omitidas actualizadas, conforme al artículo 17-A del Código con los recargos correspondientes que se causarán en términos del Código.'
            	},
              
            ]
        },
        {
          title1: 'RCFF 67', title2: 'CASO EN EL QUE NO SE PAGARÁ EL 20% DEL MONTO TOTAL DEL CRÉDITO FISCAL',
            content: [
            	{ 
            		c1: '',
              		c2: 'Para los efectos del artículo 66, tercer párrafo del Código, al momento de presentar la solicitud de autorización no se pagará el 20% del monto total del crédito fiscal a que hace referencia el artículo 66, primer párrafo, fracción II del Código, por lo que no le será aplicable la disminución establecida en el artículo 66-A, fracciones I, primer párrafo y II, primer párrafo del Código.<br>La Autoridad Fiscal podrá autorizar un plazo menor al solicitado por el contribuyente en su solicitud de pago a plazos en parcialidades en términos del tercer párrafo del artículo 66 del Código, derivado del análisis del caso en particular.'
            	},
             
            
            ]
        },
        {
          title1: 'RM 2022 2.11.1.', title2: 'SOLICITUD PARA PAGO A PLAZOS',
            content: [
              { 
                c1: 'Primer párrafo',
                  c2: 'Para los efectos de los artículos 66, primer párrafo y 66-A del CFF, y 65 de su Reglamento, la solicitud para pagar en parcialidades o de manera diferida las contribuciones omitidas y sus accesorios y/o los aprovechamientos y sus accesorios distintos a los que se causen con motivo de la importación o exportación de bienes o servicios ya sea determinados por la autoridad o los que deriven de la presentación de una declaración, se presentará de acuerdo a lo señalado en la ficha de trámite 103/CFF “Solicitud de autorización para pagar adeudos en parcialidades o diferido”, contenida en el Anexo 1-A o, en su caso, a través de los medios autorizados por el SAT a las entidades federativas.'
              },
              { 
                c1: 'Segundo  párrafo',
                  c2: 'En cualquier momento después de haberse notificado los créditos fiscales y, para el caso de autodeterminaciones o autocorrecciones, la solicitud se deberá presentar dentro de los diez días hábiles siguientes a aquel en que se haya presentado la declaración.'
              },
              { 
                c1: 'Tercer párrafo',
                  c2: 'Tratándose de créditos ya determinados, dentro de los diez días hábiles siguientes a la recepción de la solicitud de autorización para pagar en parcialidades o de manera diferida, la autoridad fiscal enviará al contribuyente el FCF (línea de captura) a través de su buzón tributario, para que cubra el pago inicial de cuando menos el 20% del adeudo debidamente actualizado a la fecha de la solicitud. En caso de que el contribuyente adjunte a la solicitud la línea de captura con el pago inicial de cuando menos el 20% ya realizado, la autoridad lo validará, y en caso de existir una diferencia por cubrir, le enviará el FCF (línea de captura) para que proceda a cubrir esta diferencia.'
              },
              { 
                c1: 'Cuarto párrafo',
                  c2: 'El contribuyente deberá realizar el pago señalado en el párrafo anterior, dentro de la vigencia señalada en el propio FCF (línea de captura).'
              },
              { 
                c1: 'Quinto párrafo',
                  c2: 'En aquellos casos en que el contribuyente deba presentar declaraciones para autodeterminarse o autocorregirse y el sistema de declaraciones y pagos le genere una línea de captura para cubrir el pago inicial del 20%, deberá anexar a su solicitud de autorización de pago a plazos, el comprobante de la realización de este pago, para que la autoridad lo considere, y si habiendo efectuado los cálculos determina una diferencia por cubrir del pago inicial, le remitirá al contribuyente el FCF (Línea de captura) para que proceda a pagar dicha diferencia, conforme al párrafo anterior.'
              },
              { 
                c1: 'Sexto párrafo',
                  c2: 'Cuando el contribuyente no cumpla con alguno de los requisitos señalados en la ficha de trámite 103/CFF “Solicitud de autorización para pagar adeudos en parcialidades o diferido”, la autoridad, dentro del plazo de diez días hábiles siguientes a la recepción de la solicitud, requerirá al contribuyente a fin que, en un plazo de cinco días hábiles siguientes a aquel en que surta efectos la notificación de dicho requerimiento, cumpla con los requisitos omitidos.'
              },
              { 
                c1: 'Séptimo párrafo',
                  c2: 'La autoridad fiscal emitirá la resolución de autorización correspondiente una vez que se haya efectuado el pago inicial de cuando menos el 20% y cumplido con el requerimiento de requisitos que, en su caso, le hubiera formulado la autoridad fiscal.'
              },
              { 
                c1: 'Octavo párrafo',
                  c2: 'Cuando no se cumpla con cualquiera de los dos supuestos señalados en el párrafo anterior, la autoridad emitirá la resolución de no autorización de la solicitud.'
              },
              { 
                c1: 'Noveno párrafo',
                  c2: 'La notificación de la resolución sobre la solicitud de pago a plazos se realizará a través del buzón tributario. Para los casos de autorización, la resolución incluirá los FCFs (Líneas de captura) de las parcialidades autorizadas, para efecto que, el contribuyente realice el pago puntual de cada parcialidad de manera mensual y sucesiva.'
              },
              { 
                c1: 'Décimo párrafo',
                  c2: 'Cuando el contribuyente no pague oportunamente alguna parcialidad, deberá solicitar un nuevo FCF que incluya la actualización y recargos por mora aplicables para el pago respectivo, mismo que deberá solicitar en la ADR más cercana a su domicilio fiscal para su entrega física, o a través de “Mi Portal” mediante un caso de “Servicio o solicitudes”.'
              },
              
              
          ]
        },
       {
          title1: 'RM 2022 2.11.2.', title2: 'FORMA OFICIAL PARA REALIZAR EL PAGO EN PARCIALIDADES O DIFERIDO',
            content: [
              { 
                c1: 'Primer párrafo',
                  c2: 'Para los efectos de los artículos 66 primer párrafo y 66-A del CFF, los pagos correspondientes al pago a plazos autorizado, ya sea en parcialidades o diferido, deberán realizarse mediante el FCF, para lo cual dicho formato será entregado u obtenido por el propio contribuyente conforme a lo siguiente:'
              },
              {
                  c1: 'Fracción I',
                  c2: 'I. A solicitud del contribuyente, en la ADR, que corresponda a su domicilio, cuando así lo requiera.'
              },
              {
                  c1: 'Fracción II',
                  c2: 'II. En el domicilio fiscal del contribuyente o a través de su buzón tributario habilitado, cuando se le notifique la resolución de autorización.'
              },
              {
                  c1: 'Fracción III',
                  c2: 'III. A solicitud del contribuyente a través de “Mi Portal”, mediante un caso de “Servicio o solicitudes”.'
              },
              
              { 
                c1: 'Segundo párrafo',
                  c2: 'Tratándose de entidades federativas, se utilizará la forma oficial para pagar la primera y hasta la última parcialidad que las mismas hayan aprobado, las cuales serán entregadas al contribuyente en las oficinas autorizadas de las propias entidades federativas.'
              },
              { 
                c1: 'Tercer  párrafo',
                  c2: 'El SAT o, en su caso, las entidades federativas, determinarán el importe de la primera y siguientes parcialidades o, cuando se trate de pago diferido, el monto diferido, de conformidad con lo establecido por el artículo 66-A, fracciones I y II del CFF. No se aceptarán los pagos efectuados en formatos diferentes al FCF, cuando el pago a plazos se encuentre controlado por el SAT.'
              },
          ]
        },
        {
          title1: 'RM 2022 2.11.5.', title2: 'DISPENSA DE GARANTIZAR EL INTERÉS FISCAL',
            content: [
              { 
                c1: 'Primer párrafo',
                  c2: 'Para los efectos del artículo 66-A, fracción III, segundo párrafo del CFF, tratándose del pago a plazos, ya sea en parcialidades o diferido, se podrá dispensar a los contribuyentes de la obligación de garantizar el interés fiscal, en cualquiera de los siguientes casos:'
              },
              {
                  c1: 'Fracción I',
                  c2: 'I. Cuando el crédito fiscal corresponda a la declaración anual de personas físicas por ISR, siempre que el número de parcialidades solicitadas sea igual o menor a seis y que dicha declaración se presente dentro del plazo establecido en el artículo 150 de la Ley del ISR.'
              },
              {
                  c1: 'Fracción II',
                  c2: 'II. Cuando los contribuyentes soliciten el pago a plazos en forma diferida y este les sea autorizado conforme a las reglas 2.9.10. y 2.11.1.'
              },
              {
                  c1: 'Fracción III',
                  c2: 'III. Cuando los contribuyentes realicen pago en parcialidades en términos de las reglas 2.9.10. y 2.11.1., siempre y cuando cumplan en tiempo y montos con todas las parcialidades a que se refiere la citada regla.'
              },
              {
                  c1: 'Segundo párrafo',
                  c2: 'En caso de que el contribuyente no cumpla en tiempo y monto con dos parcialidades, la autoridad fiscal exigirá la garantía del interés fiscal y si no se otorga se revocará la autorización del pago a plazos en parcialidades, salvo los pagos conforme a la regla 2.9.10., en los que al incumplirse con alguno de los pagos autorizados, se procederá a requerir el pago a través del procedimiento administrativo de ejecución, de conformidad con lo previsto en la fracción III del tercer párrafo del artículo 66 del CFF.'
              },
              {
                  c1: 'Tercer párrafo',
                  c2: 'La dispensa de garantizar el interés fiscal a que se refiere la presente regla no aplicará respecto de adeudos que se pretendan cubrir en parcialidades o de forma diferida, que estén relacionados, inmersos o deriven de la comisión de algún delito de carácter fiscal, por el cual se haya presentado la denuncia o querella respectiva, para tal fin las únicas garantías a considerar por parte de la autoridad fiscal, son:'
              },
              {
                  c1: 'Fracción I',
                  c2: 'I. Billete de depósito emitido por Institución autorizada;'
              },
              {
                  c1: 'Fracción II',
                  c2: 'II. Carta de crédito emitida por Institución autorizada;'
              },
              {
                  c1: 'Fracción III',
                  c2: 'III. Fianza otorgada por Institución autorizada, misma que no gozará de los beneficios de orden y excusión.'
              },
          ]
        },
         {
          title1: 'RM 2022 2.11.6.', title2: 'LIQUIDACIÓN ANTICIPADA DEL ADEUDO CON CONVENIO DE PAGO A PLAZOS',
            content: [
              { 
                c1: 'Primer párrafo',
                  c2: 'En términos de los artículos 66 y 66-A del CFF, así como lo señalado en la regla 3.17.3., los contribuyentes podrán en cualquier momento, pagar de forma anticipada el adeudo que se encuentra en convenio de pago a plazos, de acuerdo a lo siguiente:'
              },
              { 
                c1: 'Fracción I',
                c2: 'I. Deberán obtener el FCF conforme a lo señalado en la ficha de trámite 294/CFF “Solicitud del formato para el pago de contribuciones federales con línea de captura para la liquidación anticipada del adeudo con convenio de pago a plazos”, contenida en el Anexo 1-A.'
              },
              { 
                c1: 'Fracción II',
                c2: 'II. La autoridad fiscal, calculará el saldo del adeudo a liquidar que se encuentre en pago en parcialidades, de la siguiente manera: al saldo insoluto a la fecha del último pago se le adicionarán, en su caso, los recargos por prórroga de las parcialidades que tuviera vencidas y no cubiertas, con la actualización y los recargos por mora correspondientes a dichas parcialidades vencidas, más la prórroga de la parcialidad del mes en curso, siempre y cuando esta no se haya cubierto; en caso de estar al corriente en sus parcialidades, al saldo insoluto solo se le adicionarán los recargos por prórroga correspondientes a la parcialidad del mes en que se realice el pago, siempre y cuando esta no se haya cubierto.'
              },
              { 
                c1: 'Fracción III',
                c2: 'III. Para los casos de pago diferido, al saldo insoluto del adeudo a liquidar de manera anticipada, se le adicionará la cantidad que resulte de multiplicar dicho saldo por la tasa mensual aplicable al pago diferido señalada en el artículo 8 de la LIF, vigente a la fecha de autorización del pago diferido, por el número de meses o fracción de mes transcurridos desde la fecha de autorización hasta la fecha en que solicite la liquidación anticipada.'
              },
              { 
                c1: 'Fracción IV',
                c2: 'IV. La autoridad entregará el FCF (Línea de captura), a través del medio de contacto señalado por el contribuyente conforme a la ficha de trámite 294/CFF “Solicitud del formato para el pago de contribuciones federales con línea de captura para la liquidación anticipada del adeudo con convenio de pago a plazos”, contenida en el Anexo 1-A.'
              },
              { 
                c1: 'Fracción V',
                c2: 'V. En caso de que el contribuyente no efectúe el pago a más tardar en la fecha señalada en el FCF, se considerará que se desistió de la liquidación anticipada.'
              },
             
            ]
        },
        
          
        {
          title1: 'LIF 8', title2: 'RECARGOS POR PRÓRROGA EN EL PAGO DE CRÉDITOS FISCALES',
          	content: [
            	{ 
            		c1: 'Primer párrafo',
              		c2: 'En los casos de prórroga para el pago de créditos fiscales se causarán recargos:'
            	},
            	{ 
            		c1: 'Fracción I',
              		c2: 'I. Al 0.98 por ciento mensual sobre los saldos insolutos.'
            	},
            	{ 
            		c1: 'Fracción II',
              		c2: 'II. Cuando de conformidad con el Código Fiscal de la Federación, se autorice el pago a plazos, se aplicará la tasa de recargos que a continuación se establece, sobre los saldos y durante el periodo de que se trate:<br>1. Tratándose de pagos a plazos en parcialidades de hasta 12 meses, la tasa de recargos será del 1.26 por ciento mensual.<br>2. Tratándose de pagos a plazos en parcialidades de más de 12 meses y hasta de 24 meses, la tasa de recargos será de 1.53 por ciento mensual.<br>3. Tratándose de pagos a plazos en parcialidades superiores a 24 meses, así como tratándose de pagos a plazo diferido, la tasa de recargos será de 1.82 por ciento mensual.<br>Las tasas de recargos establecidas en la fracción II de este artículo incluyen la actualización realizada conforme a lo establecido por el Código Fiscal de la Federación.'
            	},
           
          	]
        },
      
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
