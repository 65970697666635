<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col-lg col-titles col-mid">
          <h1>ISR del ejercicio por la ganancia en la enajenación de inmuebles</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Precio de operación de la venta:"
              label-for="i-1" id="input-operacion_venta">

              <currency-input id="i-1" class="form-control"
                v-model="form.operacion_venta" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.operacion_venta != null" style="color: red;"><small>{{validation.operacion_venta}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Precio de compra del inmueble:"
              label-for="i-2" id="input-compra_inmueble">

              <currency-input id="i-2" class="form-control"
                v-model="form.compra_inmueble" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.compra_inmueble != null" style="color: red;"><small>{{validation.compra_inmueble}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Valor del terreno:"
              label-for="i-3" id="input-valor_terreno">

              <currency-input id="i-3" class="form-control"
                v-model="form.valor_terreno" placeholder="$0.00" v-if="valor_terrenoCheck == 0" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <currency-input id="i-3" class="form-control"
                v-model="form.valor_terreno" placeholder="$0.00" v-else disabled/>
                <div v-if="validation.valor_terreno != null" style="color: red;"><small>{{validation.valor_terreno}}</small></div>
              <b-form-checkbox v-model="valor_terrenoCheck" name="valor-terr"
                :value="1"
                :unchecked-value="0">
                No conozco el <u>Valor del terreno</u>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Valor de la construcción:"
              label-for="i-4" id="input-valor_construccion">

              <currency-input id="i-4" class="form-control"
                v-model="form.valor_construccion" placeholder="$0.00" v-if="valor_construccionCheck == 0" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <currency-input id="i-4" class="form-control"
                v-model="form.valor_construccion" placeholder="$0.00" v-else disabled/>
                <div v-if="validation.valor_construccion != null" style="color: red;"><small>{{validation.valor_construccion}}</small></div>
              <b-form-checkbox v-model="valor_construccionCheck" name="valor-construcc"
                :value="1"
                :unchecked-value="0">
                No conozco el <u>Valor de la construcción</u>
              </b-form-checkbox>
            </b-form-group>

            <!--b-form-group class="custom-form-group-s2"
              label="Gastos notariales:"
              label-for="i-5">

              <currency-input id="i-5" class="form-control"
                v-model="form.gastosNotar" placeholder="$0.00" />
            </b-form-group-->

            <b-form-group class="custom-form-group-s2"
              label="Gastos notariales de la escrituración:"
              label-for="i-6" id="input-gastos_notariales">

              <currency-input id="i-6" class="form-control"
                v-model="form.gastos_notariales" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.gastos_notariales != null" style="color: red;"><small>{{validation.gastos_notariales}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Derechos pagados de la escrituración:"
              label-for="i-7" id="input-derechos_pagados">

              <currency-input id="i-7" class="form-control"
                v-model="form.derechos_pagados" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.derechos_pagados != null" style="color: red;"><small>{{validation.derechos_pagados}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="ISAI pagado en la compra del inmueble:"
              label-for="i-8" id="input-isai_pagado">

              <currency-input id="i-8" class="form-control"
                v-model="form.isai_pagado" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.isai_pagado != null" style="color: red;"><small>{{validation.isai_pagado}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Avalúo pagado bien inmueble:"
              label-for="i-9"  id="input-avaluo_pagado">

              <currency-input id="i-9" class="form-control"
                v-model="form.avaluo_pagado" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.avaluo_pagado != null" style="color: red;"><small>{{validation.avaluo_pagado}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Comisión por la enajenación:"
              label-for="i-10" id="input-comision_enagenacion">

              <currency-input id="i-10" class="form-control"
                v-model="form.comision_enagenacion" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.comision_enagenacion != null" style="color: red;"><small>{{validation.comision_enagenacion}}</small></div>
            </b-form-group>
            <b-form-group class="custom-form-group-s2"
              label="Pago provisional a la entidad federativa:"
              label-for="i-11">

              <currency-input id="i-11" class="form-control"
                v-model="form.pago_provisional" placeholder="$0.00" v-if="pagoProvEFedCheck == 1 && form.pagoProvEFedType == 1" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <currency-input id="i-11" class="form-control"
                v-model="form.pago_provisional" placeholder="$0.00" v-else disabled/>
                <div class="col-12 col-sm" v-if="pagoProvEFedCheck == 1">
              <b-form-select required v-model="form.pagoProvEFedType" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="1">Ingresar valor deseado</b-form-select-option>
                    <b-form-select-option :value="2">Calcular</b-form-select-option>
                  </b-form-select>
              </div>
              <b-form-checkbox v-model="pagoProvEFedCheck" name="valor-construcc"
                :value="1"
                :unchecked-value="0">
                ¿Realiza pagos provisionales a la entidad federativa?</u>
              </b-form-checkbox>
              
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Deducciones personales del ejercicio:"
              label-for="i-9"  id="input-deducciones_personales">

              <currency-input id="i-9" class="form-control"
                v-model="form.deducciones_personales" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.deducciones_personales != null" style="color: red;"><small>{{validation.deducciones_personales}}</small></div>
            </b-form-group>
            
            <b-form-group class="custom-form-group-s2"
              label="Otros ingresos gravados del ejercicio:"
              label-for="i-9"  id="input-otros_ingresos_gravados">

              <currency-input id="i-9" class="form-control"
                v-model="form.otros_ingresos_gravados" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.otros_ingresos_gravados != null" style="color: red;"><small>{{validation.otros_ingresos_gravados}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="ISR retenido por otros ingresos acumulables:"
              label-for="i-9"  id="input-isr_retenido_ingresos_acumulables">

              <currency-input id="i-9" class="form-control"
                v-model="form.isr_retenido_ingresos_acumulables" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.isr_retenido_ingresos_acumulables != null" style="color: red;"><small>{{validation.isr_retenido_ingresos_acumulables}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Se optará por calcular la tasa promedio de los últimos cinco ejercicios aplicable a los ingresos no acumulables:"
              label-for="i-anio">
              <b-form-select v-model="form.se_optara_tasa" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Seleccione opción</b-form-select-option>
                  <b-form-select-option :value="0">No</b-form-select-option>
                  <b-form-select-option :value="1">Sí</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <div v-if="form.se_optara_tasa == 1" >
	            <b-form-group v-for="(tasa,indx) in form.tasas" :key="indx" class="custom-form-group-s2"
	              :label="'Tasa del '+tasa.anio+':'"
	              label-for="i-agui" id="input-porporcion_participacion">
	             <b-form-input
	                id="i-co"
	                v-model="tasa.tasa"
	                type="text"
	                placeholder="0.00"
	                @keypress="isNumber($event)"
	               
	              ></b-form-input>
	             <!--  <div v-if="validation.porporcion_participacion != null" style="color: red;"><small>{{validation.porporcion_participacion}}</small></div> -->
	            </b-form-group>
	        </div>
            <!-- gastos -->
            <b-form-group class="custom-form-group-s2"
              label="Fecha de los gastos:"
              label-for="i-11">

             
              <b-form-checkbox v-model="form.pagoOtrosGastosCheck" name="valor-construcc"
                :value="1"
                :unchecked-value="0">
                ¿El mes de realización de los gastos es distinto al mes de la enajenación?</u>
              </b-form-checkbox>
              
            </b-form-group>

            <b-form-group class="custom-form-group-s2" v-if="form.pagoOtrosGastosCheck == 1" style="padding-left: 40px;">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select class="blacked" v-model="form.mes3" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia3" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysthree" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio3" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

                  </b-form-select>
                </div>
              </div>
            </b-form-group>


            
            <!-- mejoras -->

            <b-form-group class="custom-form-group-s2"
              label="Mejoras al inmueble:"
              label-for="i-11">
               <b-form-checkbox v-model="form.pagoMejorasCheck" name="valor-construcc"
                :value="1"
                :unchecked-value="0" >
                ¿Se realizaron mejoras al inmueble?</u>
              </b-form-checkbox>         
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Total de gastos por inversiones hechas en la construcción, mejoras o amplicaciones:"
              label-for="i-11" v-if="form.pagoMejorasCheck == 1 " style="padding-left: 40px;" id="input-pagoMejoras">
              <currency-input id="i-11" class="form-control"
                v-model="form.pagoMejoras" placeholder="$0.00" oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')" required/>
              <div v-if="validation.pagoMejoras != null" style="color: red;"><small>{{validation.pagoMejoras}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de realización de la mejora:" v-if="form.pagoMejorasCheck == 1 " style="padding-left: 40px;" >
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select class="blacked" v-model="form.mes4"  oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" >
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia4" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" >
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysfour" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio4" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" >
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

                  </b-form-select>
                 
                </div>
              </div>
            </b-form-group>
            <!--  -->
            

            

            <b-form-group class="custom-form-group-s2"
              label="Fecha de adquisición del inmueble:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select class="blacked" v-model="form.mes1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio1" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

                  </b-form-select>
                </div>
              </div>
              <div v-if="validation.fecha_adquisicion != null" style="color: red;"><small>{{validation.fecha_adquisicion}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Mes de enajenación:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select class="blacked" v-model="form.mes2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.dia2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daystwo" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select v-model="form.anio2" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div v-if="validation.fecha_enajenacion != null" style="color: red;"><small>{{validation.fecha_enajenacion}}</small></div>
            </b-form-group>

            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->

          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">
              <!-- <div class="row row-top">
                <div class="col col-detail">
                  Detalle
                </div>

                <div class="col col-med">
                  ART. 96 DE LA LISTA LISR
                </div>
              </div> -->

              <div class="row row-body">
                <div class="col col-detail">
                  Ganancia por enajenación del inmueble:
                </div>
                <div class="col col-med">
                  {{detailInfo.ganancia_por_enajenacion}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Número de años transcurridos entre la fecha de adquisición y la enajenación:
                </div>
                <div class="col col-med">
                  {{detailInfo.numero_anio}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Base gravable para el pago provisional:
                </div>
                <div class="col col-med">
                  {{detailInfo.base_gravable_pago_provisional}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Número de años transcurridos entre la fecha de adquisición y la enajenación:
                </div>
                <div class="col col-med">
                  {{detailInfo.numero_anio}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR a cargo en términos del Art. 126 de la LISR:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.isr_cargo_terminos_126}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Pago provisional a la entidad federativa:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.pago_provisional}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR a pagar a la federación en pagos provisionales:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.isr_pagar_a_federacion}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR sobre la ganancia no acumulable:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.isr_sobre_ganancia_noacumulable}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR del ejercicio sobre los ingresos acumulables:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.isr_ejercicio_sobre_ingresoacumulables}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Total del ISR a cargo del ejercicio:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.total_isr_cargo_ejercicio}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR retenido por otros ingresos acumulables:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.isr_retenido_ingresos_acumulables}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Impuesto previo para la entidad federativa:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.impuesto_precio_para_entidadfederativa}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR a pagar a la federación:
                </div>
                <div class="col col-med col-med">
                  {{detailInfo.isr_pagar_a_federacion}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  ISR a pagar (a favor) del ejercicio:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.isr_pagar_afavor_ejercicio}}
                </div>
              </div>
            </div>

            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

            

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
    <b-modal id="modal-compartir" ref="modal-compartir" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
      <b-form class="content" @submit="onSubmitComp">
        <div class="box-content">
          <h5 class="title">Escribe el correo a compartir</h5>

          <b-form-group class="custom-form-group"
            label="Correo"
            label-for="il-email">
            <b-form-input
              id="il-email"
              v-model="formComp.email"
              type="email"
              placeholder="correo@ejemplo.com"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="box-footer">
          <button type="button" class="btn _btn-st1" @click="$refs['modal-compartir'].hide()">Cancelar</button>
          <b-button type="submit" class="_btn-st1 btn-orange">Compartir</b-button>
        </div>
      </b-form>
    </b-modal>
    <!--  -->

    <!-- Modal "Guardar calculo" -->
    <b-modal id="modal-gcalculo" ref="modal-gcalculo" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
      <b-form class="content" @submit="onSubmitCalc">
        <div class="text-center box-content">
          <i class="fal fa-check-circle icon-success"></i>
          <h5 class="title">¡Cálculo guardado con éxito!</h5>
        </div>

        <div class="box-footer">
          <router-link type="button" class="btn _btn-st1" to="/catalogos">Salir</router-link>
          <b-button type="submit" class="_btn-st1 btn-orange">Hacer otro cálculo</b-button>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
// import ComponentModalFlegales from './components/modal-flegales.vue';
import FlegalesComponent from './components/fleg-comp-isr-porla-ganancia-enla-enajenacion-de-inmuebles.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form: {
        mes1: null,
        dia1:null,
        anio1:null,

        mes2: null,
        dia2:null,
        anio2:null,
        contribucion:null,

        
        /*mes1: 1,
        dia1:17,
        anio1:2021,

        mes2: 2,
        dia2:23,
        anio2:2021,
        contribucion:369962.85**/
        compra_inmueble:0,
        valor_construccion:0,
        gastos_notariales:0,
        derechos_pagados: 0,
        isai_pagado:0,
        avaluo_pagado:0,
        comision_enagenacion:0,
        isr_retenido_ingresos_acumulables:0,

        pago_provisional:0,
      	deducciones_personales:0,
      	otros_ingresos_gravados:0,
      	se_optara_tasa:null,

        pagoOtrosGastosCheck:0,
        pagoMejorasCheck:0,
        pagoMejoras:0,
        mes3: null,
        dia3:null,
        anio3:null,

        mes4: null,
        dia4:null,
        anio4:null,
        pagoProvEFedType:null,

        tasas:[]
      },
      /*form: {
        mes1: 12,
        dia1:1,
        anio1:2021,

        mes2: 8,
        dia2:31,
        anio2:2021,

        contribucion:null,

        operacion_venta:'5181579.43',
        compra_inmueble:'3108947.66',
        valor_terreno:'621789.53 ',
        valor_construccion:'2487158.13',
        gastos_notariales:'35000.00',
        derechos_pagados: '1500.00',
        isai_pagado:'1500.00',
        avaluo_pagado:'15000.00',
        comision_enagenacion:'51815.79',
        pagoProvEFed:'64771.38',

        pagoOtrosGastosCheck:1,
        pagoMejorasCheck:1,
        pagoMejoras: 0,

        mes3: 2,
        dia3:25,
        anio3:2021,

        mes4: 5,
        dia4:9,
        anio4:2021,
      },*/

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],
      daysthree:[],
      daysfour:[],

      detailInfo:[],
      valor_terrenoCheck:0,
      valor_construccionCheck:0,
      pagoProvEFedCheck:0,

      validation:{
        fecha_enajenacion:null,
        fecha_adquisicion:null,
        operacion_venta:null,
        compra_inmueble:null,
        valor_terreno:null,
        valor_construccion:null,
        gastos_notariales:null,
        derechos_pagados:null,
        isai_pagado:null,
        avaluo_pagado:null,
        comision_enagenacion:null,
        pagoMejoras:null,
        deducciones_personales:null
      }
    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },

    'form.mes3':function(val)
    {
      var fecha = new Date();
      if (this.form.anio3 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio3;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysthree = days;
    },
    'form.anio3':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes3, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysthree = days;
    },

    'form.mes4':function(val)
    {
      var fecha = new Date();
      if (this.form.anio4 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio4;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysfour = days;
    },
    'form.anio4':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes4, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysfour = days;
    },

    'valor_terrenoCheck':function(val){
        if (val == 1) {
            this.form.valor_terreno = this.form.compra_inmueble * .2;
        }
        else{
            this.form.valor_terreno = 0;
        }  
    },
    'valor_construccionCheck':function(val){
        if (val == 1) {
            this.form.valor_construccion = this.form.compra_inmueble - this.form.valor_terreno;
        }
        else{
            this.form.valor_construccion = 0;
        } 
    },
    'pagoProvEFedCheck':function(val){
        if (val == 1) {
            this.form.pago_provisional = 0;
        }
        else{
            this.form.pago_provisional = 0;
            this.form.pagoProvEFedType = null;
        }
    },
    'form.pagoOtrosGastosCheck':function(val){
        this.form.mes3 = null;
        this.form.anio3 = null;
        this.form.dia3 = null;
    },
    'form.pagoMejorasCheck':function(val){
        this.form.mes4 = null;
        this.form.anio4 = null;
        this.form.dia4 = null;
        this.form.pagoMejoras = 0;
    },
    'form.se_optara_tasa':function(val){
    	if (this.form.anio2 != null) {
	    	if (val == 0) {
	    		this.form.tasas = [];
	    	}	
	    	else{
	    		var tasas = [];
	    		
	    		for (var i = 1; i <= 4; i++) {
	    			console.log(i);
	    			var aux = {
	    				'anio':this.form.anio2 - i,
	    				'tasa':0
	    			};
	    			tasas.push(aux);
	    		}
	    		this.form.tasas = tasas;
	    	}
	    }
    },
    'form.anio2':function(val){
    	if (this.form.anio2 != null) {
	    	if (val == 0) {
	    		this.form.tasas = [];
	    	}	
	    	else{
	    		var tasas = [];
	    		
	    		for (var i = 1; i <= 4; i++) {
	    			console.log(i);
	    			var aux = {
	    				'anio':this.form.anio2 - i,
	    				'tasa':0
	    			};
	    			tasas.push(aux);
	    		}
	    		this.form.tasas = tasas;
	    	}
	    }
    }
  },
  methods: {
    isNumber: function(evt) {

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      this.validation.fecha_enajenacion = null;
      this.validation.fecha_adquisicion = null;
      this.validation.operacion_venta = null;
      this.validation.compra_inmueble = null;
      this.validation.valor_terreno = null;
      this.validation.valor_construccion = null;
      this.validation.gastos_notariales = null;
      this.validation.derechos_pagados = null;
      this.validation.isai_pagado = null;
      this.validation.avaluo_pagado = null;
      this.validation.comision_enagenacion = null;

      event.preventDefault();
      var fecha1 = null;
      if (this.form.dia1 < 10) {
          if (this.form.mes1 < 10) {
              fecha1 = this.form.anio1+'-0'+this.form.mes1+'-0'+this.form.dia1;
          }
          else{
              fecha1 = this.form.anio1+'-'+this.form.mes1+'-0'+this.form.dia1;
          }
      }
      else{
          if (this.form.mes1 < 10) {
              fecha1 = this.form.anio1+'-0'+this.form.mes1+'-'+this.form.dia1;
          }
          else{
              fecha1 = this.form.anio1+'-'+this.form.mes1+'-'+this.form.dia1;
          }
      }
      var fecha2=null;
      if (this.form.dia2 < 10) {
          if (this.form.mes2 < 10) {
              fecha2 = this.form.anio2+'-0'+this.form.mes2+'-0'+this.form.dia2;
          }
          else{
              fecha2 = this.form.anio2+'-'+this.form.mes2+'-0'+this.form.dia2;
          }
      }
      else{
          if (this.form.mes2 < 10) {
              fecha2 = this.form.anio2+'-0'+this.form.mes2+'-'+this.form.dia2;
          }
          else{
              fecha2 = this.form.anio2+'-'+this.form.mes2+'-'+this.form.dia2;
          }
      }

      var fecha_uno = new Date(fecha1).getTime();
      var fecha_dos = new Date(fecha2).getTime();
      var diff = fecha_dos - fecha_uno;

      var result = diff/(1000*60*60*24);
      if (result < 1) {
          this.validation.fecha_adquisicion = '"Fecha de adquisición del inmueble" no debe de ser mayor a "Mes de enajenación"'
          return;
      }
      if (this.form.operacion_venta == 0 || this.form.operacion_venta == null) {
          this.validation.operacion_venta = '"Precio de operación de la venta" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-operacion_venta").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.compra_inmueble == 0 || this.form.compra_inmueble == null) {
          this.validation.compra_inmueble = '"Precio de compra del inmueble" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-compra_inmueble").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.valor_terreno == 0 || this.form.valor_terreno == null) {
          this.validation.valor_terreno = '"Valor del terreno" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-valor_terreno").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      /*if (this.form.valor_construccion == 0 || this.form.valor_construccion == null) {
          this.validation.valor_construccion = '"Gastos notariales de la escrituración" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-valor_construccion").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.gastos_notariales == 0 || this.form.gastos_notariales == null) {
          this.validation.gastos_notariales = '"Gastos notariales de la escrituración" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-gastos_notariales").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.derechos_pagados == 0 || this.form.derechos_pagados == null) {
          this.validation.derechos_pagados = '"Derechos pagados de la escrituración" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-derechos_pagados").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.isai_pagado == 0 || this.form.isai_pagado == null) {
          this.validation.isai_pagado = '"ISAI pagado en la compra del inmueble" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-isai_pagado").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.avaluo_pagado == 0 || this.form.avaluo_pagado == null) {
          this.validation.avaluo_pagado = '"Avalúo pagado bien inmueble" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-avaluo_pagado").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }*/
      if (this.form.pagoMejoras == 0 || this.form.pagoMejoras == null) {
          if (this.form.pagoMejorasCheck == 1) {
              this.validation.pagoMejoras = '"Total de gastos por inversiones hechas en la construcción, mejoras o amplicaciones" debe de ser mayor a 0';
              var scrollt = document.getElementById("input-pagoMejoras").offsetTop;
              setTimeout( ()=>{
                window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
              }, 200);
              return;
          }
          
      }


      
      this.$refs['modal-loading'].show();
      //$('#btn_calc').hide();
      
       axios.post(tools.url("/calculateEleven"),this.form).then((response)=>{
           this.detailInfo = response.data;
           this.step = 2;
           this.$refs['modal-loading'].hide();
      }).catch((error)=>{
          this.$refs['modal-loading'].hide();
           $('#btn_calc').show();
           alert('Ocurrio un error al calcular la informacion');
       });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();



      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 1900; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
