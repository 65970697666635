import { render, staticRenderFns } from "./fleg-comp-isr-enajenacion-inpfisicas.vue?vue&type=template&id=22e7c353&lang=html&"
import script from "./fleg-comp-isr-enajenacion-inpfisicas.vue?vue&type=script&lang=js&"
export * from "./fleg-comp-isr-enajenacion-inpfisicas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports