<template lang="html">
  <div id="catalog-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block mt-3">
        <router-link class="link-back" :to="{ name: 'homePage'}"><i class="fal fa-chevron-left icon"></i> <span>Inicio</span></router-link>
      </div>
    </section>

    <section class="container oversized-container catalogs-e-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Catálogo de herramientas</h2>
            </div>
          </div>
        </div>

        <div class="col-12 col-catalogs">
          <div class="row">

            <div class="col-lg col-catalog" v-for="(catalog, cathInx) in catalogs" :key="'cathInx-'+cathInx">
              <router-link class="box" v-bind:style="{ backgroundImage: 'url('+catalog.img+')' }" :to="{ name: catalog.url }">
                <div class="inside">
                  <div class="row">
                    <div class="col col-icon">
                      <img :src="catalog.icon">
                    </div>

                    <div class="col col-info">
                      <p>{{ catalog.text }}</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container extra-section">
      <div class="row align-items-center">
        <div class="pl-sm-0 col-lg-5 col-xl-3 col-info">
          <h6 class="mb-2 txt-orange">¿Necesitas ayuda?</h6>
          <h6>¿No encuentras la herramienta que necesitabas?</h6>
        </div>

        <div class="col-lg col-buttons">
          <a class="btn _btn-st1" href="mailto:contacto@domain.com">Contáctanos</a>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      catalogs: [
        { //CALCULADORA 1
          url: 'catActuRecaPage', img: 'public/images/pages/catalog/catalog-1.jpg', icon: 'public/images/pages/catalog/catalog-icon-1.png', text: 'Actualización y recargos' },

        { //CALCULADORA 2
          url: 'catISREnaInmPerFis', img: 'public/images/pages/catalog/catalog-2.jpg', icon: 'public/images/pages/catalog/catalog-icon-6.png', text: 'ISR por enajenación de Inmuebles por personas físicas' },
        { //CALCULADORA 3
          url: 'catISRIVAactempypro', img: 'public/images/pages/catalog/catalog-3.jpg', icon: 'public/images/pages/catalog/catalog-icon-2.png', text: 'ISR e IVA por actividades empresariales y profesionales' },
        
        { //CALCULADORA 4
          url: 'catISRPagoAguiPage', img: 'public/images/pages/catalog/catalog-5.jpg', icon: 'public/images/pages/catalog/catalog-icon-5.png', text: 'ISR por pago de aguinaldo' },

        { //CALCULADORA 5
          url: 'IESPSVentaCerveza', img: 'public/images/pages/catalog/catalog-4.jpg', icon: 'public/images/pages/catalog/catalog-icon-4.png', text: 'IESPS por venta de cerveza' },

        // { url: '', img: 'public/images/pages/catalog/catalog-3.jpg', icon: 'public/images/pages/catalog/catalog-icon-3.png', text: 'Factor de ajuste anual por inflación' },
        
        // { url: '', img: 'public/images/pages/catalog/catalog-5.jpg', icon: 'public/images/pages/catalog/catalog-icon-5.png', text: 'Dividentos por distribuir a accionistas e ISR a cargo' },
        // { url: '', img: 'public/images/pages/catalog/catalog-6.jpg', icon: 'public/images/pages/catalog/catalog-icon-6.png', text: 'Factor de ajuste anual por inflación' },
        // { url: '', img: 'public/images/pages/catalog/catalog-7.jpg', icon: 'public/images/pages/catalog/catalog-icon-7.png', text: 'Amortización de perdidas fiscales' },

      ],
    }
  }
}
</script>
