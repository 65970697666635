<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col col-titles col-mid">
          <h1>Pago de contribuciones diferidas</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">

        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Año del cálculo:"
              label-for="i-anio">
              <b-form-select class="blacked" v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Total del crédito fiscal al momento de realizar la solicitud de autorización (importe actualizado):"
              label-for="i-agui" id="input-total_creditos">
              <currency-input id="i-agui" class="form-control"
                v-model="form.total_creditos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.total_creditos != null" style="color: red;"><small>{{validation.total_creditos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Número de meses por lo que se pretende realizar el diferimiento:"
              label-for="i-anio">
              <b-form-select v-model="form.numero_meses" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  <b-form-select-option :value="null" disabled selected>Número de meses</b-form-select-option>
                  <b-form-select-option :value="yindx+1" v-for="(year, yindx) in 12" :key="yindx">{{yindx+1}}</b-form-select-option>
              </b-form-select>
            </b-form-group>


            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <h5 class="title">DATOS GENERALES</h5>

          <div class="row">
            <div class="col-12 col-line">
              <hr />
            </div>

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!--div class="col col-share">
                  <a class="btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div-->
              </div>
            </div>

            <div class="col-12 col-info">
              <p style="text-align:center"><b>Monto mínimo a pagar para acceder a la solicitud de autorización</b></p>
              <div class="row row-body">
                <div class="col col-detail">
                  Pago del 20% del monto total del crédito fiscal al momento de la solicitud de autorización:
                </div>
                <div class="col col-med">
                  {{detailInfo.pago_del_vienteporciento}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Saldo pendiente a diferir a meses:
                </div>
                <div class="col col-med">
                  {{detailInfo.saldo_pendiente_adiferir}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Tasa de recargos mensual:
                </div>
                <div class="col col-med">
                  {{detailInfo.tasa_recargos_mensual}}
                </div>
              </div>

              <p style="text-align:center"><b>Importe pendiente del crédito fiscal a pagar en una sola exhibición</b></p>

              <div class="row row-body">
                <div class="col col-detail">
                  Saldo del crédito fiscal a liquidar a meses:
                </div>
                <div class="col col-med">
                  {{detailInfo.saldo_del_credito_fiscal_liquidar}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Total de los recargos por el diferimiento a meses:
                </div>
                <div class="col col-med">
                  {{detailInfo.total_recargos_diferiminento}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Tasa de recargos acumulada:
                </div>
                <div class="col col-med">
                  {{detailInfo.tasa_recargos_acumulada}}
                </div>
              </div>

             

            </div>
            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

           
          </div>
        </div>
        <!-- Paso 2, FIN -->
        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->
      </div>
    </section>


    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
   
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
  import FlegalesComponent from './components/fleg-comp-pago-contribuciones-diferidas.vue';
export default {
  data(){
    return{
      showFLegalesComp: false,
      step: 1,

      form: {
        anio: null,
        numero_meses:null,
        total_creditos:null
      },

      formComp: {
        email: null
      },
      years:[],
      detailInfo:{

      },
      validation:{
        total_creditos:null,
        
      }
    }
  },

  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      this.validation.total_creditos = null;
     
      
      event.preventDefault();
      if (this.form.total_creditos == 0 || this.form.total_creditos == null) {
          this.validation.total_creditos = '"Total del crédito fiscal al momento de realizar la solicitud de autorización (importe actualizado):" debe de ser mayor a 0';
          return;
      }
      

        this.$refs['modal-loading'].show();
        //$('#btn_calc').hide();
        event.preventDefault();
        axios.post(tools.url("/calculateEight"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al calcular la informacion');
        });
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

       

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    otherCalc(){
      location.reload();
    },
    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },
  },

  mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var years = [];
      for (var i = 2016; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }

      years.reverse();
      this.years = years;
  },
  components: {
    FlegalesComponent
  },
}
</script>
