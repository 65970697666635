<template lang="html">
  <div class="d-flex account-page">
    <div class="container">

      <div class="row">
        <div class="col-xl-10 offset-xl-1 main-box">

          <div class="row">
            <div class="col-12 px-2">
              <h1 class="mb-3 page-title">{{ title }}</h1>
            </div>
            <div class="col-lg-4 px-2 col-menu">
              <div class="white-box">
                <div class="box-profile">
                  <div class="d-block mt-4 pb-3">
                    <span class="fa-stack fa-4x user-icon">
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fas fas fa-user fa-stack-1x fa-inverse"></i>
                    </span>

                    <h5 class="txt-username">Bienvenido</h5>
                  </div>
                </div>

                <hr />

                <div class="box-menu">
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario"><i class="fal fa-user-edit"></i> Mis datos</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/usuario/contrasena"><i class="fal fa-lock-alt"></i> Mi contraseña</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/"><i class="fal fa-sign-out-alt"></i> Cerrar sesión</router-link>
                  </p>
                </div>

              </div>
            </div>

            <div class="col-lg px-2">
              <div class="white-box p-4">

                <router-view></router-view>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      title: 'Mi cuenta'
    }
  },

  methods:{
    getUrlName(){
      var urlName = this.$route.path;

      if(urlName == '/usuario'){ this.title = 'Mis datos' }
      if(urlName == '/usuario/contrasena'){ this.title = 'Cambiar contraseña' }
    }
  },

  beforeMount(){
    this.getUrlName();
  },

  watch: {
    $route(to, from) {
      this.getUrlName();
    },
  }
}
</script>
