<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col col-titles col-mid">
          <h1>Dividendos por distribuir a accionistas e ISR a cargo</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">

        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Año del cálculo:"
              label-for="i-anio">
              <b-form-select  v-model="form.anio1" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" required>
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in yearsone" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Año en el que se generaron las utilidades:"
              label-for="i-anio">
              <b-form-select  v-model="form.anio2" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" required>
                  <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
                  <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-form-group-s2">
              <b-form-checkbox v-model="form.dividendosEntregadoCheck" name="valor-terr"
                :value="1"
                :unchecked-value="0">
                No conozco el <u>Dividendo bruto entregado al socio</u>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Proporción de la participación del socio o accionista en el capital de la sociedad:"
              label-for="i-agui" id="input-porporcion_participacion" v-if="form.dividendosEntregadoCheck == 1">
             <b-form-input
                id="i-co"
                v-model="form.porporcion_participacion"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
               
              ></b-form-input>
              <div v-if="validation.porporcion_participacion != null" style="color: red;"><small>{{validation.porporcion_participacion}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Dividendos a repartir entre los socios o accionistas:"
              label-for="i-sala" id="input-dividendos_socio_acc" v-if="form.dividendosEntregadoCheck == 1">
              <currency-input id="i-sala" class="form-control"
                v-model="form.dividendos_socio_acc" placeholder="$0.00" oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.dividendos_socio_acc != null" style="color: red;"><small>{{validation.dividendos_socio_acc}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Dividendo bruto entregado al socio:"
              label-for="i-sala" id="input-dividendos_entregado" v-if="form.dividendosEntregadoCheck == 0">
              <currency-input id="i-sala" class="form-control"
                v-model="form.dividendos_entregado" placeholder="$0.00" oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.dividendos_entregado != null" style="color: red;"><small>{{validation.dividendos_entregado}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Dividendos provienen de la CUFIN:"
              label-for="i-anio">
              <b-form-select  v-model="form.provienen_dela_cufin" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')" required>
                  <b-form-select-option :value="null" disabled selected>Seleccione una opción</b-form-select-option>
                  <b-form-select-option :value="1">Sí</b-form-select-option>
                  <b-form-select-option :value="2">No</b-form-select-option>
              </b-form-select>
            </b-form-group>


            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <h5 class="title">DATOS GENERALES</h5>

          <div class="row">
            <div class="col-12 col-line">
              <hr />
            </div>

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!--div class="col col-share">
                  <a class="btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div-->
              </div>
            </div>

            <div class="col-12 col-info">
              
              <div class="row row-body">
                <div class="col col-detail">
                  Dividendos netos a repartir al socio o accionista:
                </div>
                <div class="col col-med">
                  {{detailInfo.dividendos_netos_arepartir}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                 Retención por dividendos:
                </div>
                <div class="col col-med">
                  {{detailInfo.retencion_por_dividendos}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                 ISR a enterar por la persona moral:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_enterar}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 Proporción de la participación del socio o accionista en el capital de la sociedad:
                </div>
                <div class="col col-med">
                  {{detailInfo.porporcion_participacion}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 Tasa de la retención:
                </div>
                <div class="col col-med">
                  {{detailInfo.taza_retencion}}
                </div>
              </div>


              <div class="row row-body">
                <div class="col col-detail">
                 Dividendos a repartir al socio o accionista:
                </div>
                <div class="col col-med">
                  {{detailInfo.dividendos_repartir}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 Dividendos piramidados:
                </div>
                <div class="col col-med">
                  {{detailInfo.dividendos_piramidados}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 Ingresos acumulables para el socio o accionista:
                </div>
                <div class="col col-med">
                  {{detailInfo.ingresos_acumulables}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 ISR del ejercicio por dividendos:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_del_ejercicio}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 ISR acreditable correspondiente a los dividendos:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_acreditable_correspondiente}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 ISR a pagar (favor) del ejercicio:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_apagar}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                 Factor de piramidación:
                </div>
                <div class="col col-med">
                  {{detailInfo.factor_piramide}}
                </div>
              </div>
            
            </div>
            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

           
          </div>
        </div>
        <!-- Paso 2, FIN -->
        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->
      </div>
    </section>


    <!-- ******** Modals ******** -->



    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
  import FlegalesComponent from './components/fleg-comp-dividendos-a-distribuir.vue';
export default {
  data(){
    return{
      showFLegalesComp: false,
      step: 1,

      form: {
        anio1: null,
        anio2: null,
        provienen_dela_cufin:null,
        dividendos_entregado:null,

        porporcion_participacion:0,
        dividendos_socio_acc:0,
        dividendos_entregado:0,
        dividendosEntregadoCheck:0,

      },


      formComp: {
        email: null
      },
      yearsone:[],
      years:[],
      detailInfo:{

      },
      validation:{
        porporcion_participacion:null,
        dividendos_socio_acc:null,
        dividendos_entregado:null
      }
    }
  },

  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      this.validation.porporcion_participacion = null;
      this.validation.dividendos_socio_acc = null;
      this.validation.dividendos_entregado = null;
      event.preventDefault();
      if (this.form.dividendosEntregadoCheck == 1) {

        if (this.form.porporcion_participacion == 0 || this.form.porporcion_participacion == null) {
            this.validation.porporcion_participacion = '"Proporción de la participación del socio o accionista en el capital de la sociedad" debe de ser mayor a 0';
            var scrollt = document.getElementById("input-porporcion_participacion").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
        else{
             if (this.form.porporcion_participacion > 100) {
                  this.validation.porporcion_participacion = '"Proporción de la participación del socio o accionista en el capital de la sociedad" no debe de superar el 100%';
                  var scrollt = document.getElementById("input-porporcion_participacion").offsetTop;
                  setTimeout( ()=>{
                    window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
                  }, 200);
                  return;
             }
            
        }
        if (this.form.dividendos_socio_acc == 0 || this.form.dividendos_socio_acc == null) {
            this.validation.dividendos_socio_acc = '"Dividendos a repartir entre los socios o accionistas" debe de ser mayor a 0';
            var scrollt = document.getElementById("input-dividendos_socio_acc").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
      }
      else if(this.form.dividendosEntregadoCheck == 0)
      {
        if (this.form.dividendos_entregado == 0 || this.form.dividendos_entregado == null) {
            this.validation.dividendos_entregado = '"Dividendo bruto entregado al socio" debe de ser mayor a 0';
            var scrollt = document.getElementById("input-dividendos_entregado").offsetTop;
            setTimeout( ()=>{
              window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
            }, 200);
            return;
        }
      }
        this.$refs['modal-loading'].show();
        $('#btn_calc').hide();
        event.preventDefault();
        axios.post(tools.url("/calculateSix"),this.form).then((response)=>{
            this.detailInfo = response.data;
            this.$refs['modal-loading'].hide();
            this.step = 2;
        }).catch((error)=>{
            $('#btn_calc').show();
            this.$refs['modal-loading'].hide();
            alert('Ocurrio un error al calcular la informacion');
        });
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
    otherCalc(){
      location.reload();
    },
    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },
  },

  mounted(){
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var years = [];
      for (var i = 2014; i <= anio; i++) {
          var aux = {
            'value':i
          };
          years.push(aux);
      }

      years.reverse();
      this.years = years;


      var yearsone = [];
      for (var i = 2016; i <= anio; i++) {
          var aux = {
            'value':i
          };
          yearsone.push(aux);
      }

      yearsone.reverse();
      this.yearsone = yearsone;


  },
  components: {
    FlegalesComponent
  },
}
</script>
