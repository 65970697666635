/*
 *
 * Estos componentes estan presentes solo en el website
 *
 */

const page="./page/";
// import slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// import VueSweetalert2 from 'vue-sweetalert2';
import VueCurrencyInput from 'vue-currency-input'; // https://github.com/dm4t2/vue-currency-input/tree/1.22.6 | https://vue-currency-input-v1.netlify.app/guide/#introduction

function plugin (Vue) {
	//Componentes llamados
  Vue.component('my-header', require(page+'shared/header.vue').default);
	Vue.component('my-footer', require(page+'shared/footer.vue').default);
	// Vue.component('slick',slick);
  Vue.use(BootstrapVue);
  // Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
  // Vue.use(VueSweetalert2);

  Vue.use(VueCurrencyInput,
    {
      /* see config reference */
      globalOptions: { currency: 'MXN', locale: 'es-mx', allowNegative: false }
    }
  );
}

// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
