<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col" v-html="item.title2"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'LISR 7', title2: 'ALCANCE DE PERSONA MORAL',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Cuando en esta Ley se haga mención a persona moral, se entienden comprendidas, entre otras, las sociedades mercantiles, los organismos descentralizados que realicen preponderantemente actividades empresariales, las instituciones de crédito, las sociedades y asociaciones civiles y la asociación en participación cuando a través de ella se realicen actividades empresariales en México.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>ALCANCE DE ACCIONES Y DE ACCIONISTAS</b><br>En los casos en los que se haga referencia a acciones, se entenderán incluidos los certificados de aportación patrimonial emitidos por las sociedades nacionales de crédito, las partes sociales, las participaciones en asociaciones civiles y los certificados de participación ordinarios emitidos con base en fideicomisos sobre acciones que sean autorizados conforme a la legislación aplicable en materia de inversión extranjera. Cuando se haga referencia a accionistas, quedarán comprendidos los titulares de los certificados a que se refiere este párrafo, de las partes sociales y de las participaciones señaladas. Tratándose de sociedades cuyo capital esté representado por partes sociales, cuando en esta Ley se haga referencia al costo comprobado de adquisición de acciones, se deberá considerar la parte alícuota que representen las partes sociales en el capital social de la sociedad de que se trate.<br>…………………………………………………'
            },
            
            

          ]
        },
        { title1: 'LISR 44', title2: 'DETERMINACIÓN DEL AJUSTE ANUAL POR INFLACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las personas morales determinarán, al cierre de cada ejercicio, el ajuste anual por inflación, como sigue:'
            },
            { c1: 'Fracción I',
              c2: '<b>DETERMINACION DEL SALDO PROMEDIO ANUAL DE LAS DEUDAS Y DE LOS CREDITOS</b><br>I. Determinarán el saldo promedio anual de sus deudas y el saldo promedio anual de sus créditos.<br>El saldo promedio anual de los créditos o deudas será la suma de los saldos al último día de cada uno de los meses del ejercicio, dividida entre el número de meses del ejercicio. No se incluirán en el saldo del último día de cada mes los intereses que se devenguen en el mes.'
            },
            { c1: 'Fracción II',
              c2: '<b>AJUSTE ANUAL POR INFLACION ACUMULABLE</b><br>II. Cuando el saldo promedio anual de las deudas sea mayor que el saldo promedio anual de los créditos, la diferencia se multiplicará por el factor de ajuste anual y el resultado será el ajuste anual por inflación acumulable.<br><b>AJUSTE ANUAL POR INFLACION DEDUCIBLE</b><br>Cuando el saldo promedio anual de los créditos sea mayor que el saldo promedio anual de las deudas, la diferencia se multiplicará por el factor de ajuste anual y el resultado será el ajuste anual por inflación deducible.'
            },

            { c1: 'Fracción III',
              c2: '<b>FACTOR DE AJUSTE ANUAL</b><br>III. El factor de ajuste anual será el que se obtenga de restar la unidad al cociente que se obtenga de dividir el Indice Nacional de Precios al Consumidor del último mes del ejercicio de que se trate entre el citado índice del último mes del ejercicio inmediato anterior.<br>Cuando el ejercicio sea menor de 12 meses, el factor de ajuste anual será el que se obtenga de restar la unidad al cociente que se obtenga de dividir el Indice Nacional de Precios al Consumidor del último mes del ejercicio de que se trate entre el citado índice del mes inmediato anterior al del primer mes del ejercicio de que se trate.<br><b>CREDITOS O DEUDAS EN MONEDA EXTRANJERA</b><br>Los créditos y las deudas, en moneda extranjera, se valuarán a la paridad existente al primer día del mes.'
            },
  
          ]
        },
        
       
        { title1: 'LISR 45', title2: 'CONCEPTO DE CRÉDITOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para los efectos del artículo anterior, se considerará crédito, el derecho que tiene una persona acreedora a recibir de otra deudora una cantidad en numerario, entre otros: los derechos de crédito que adquieran las empresas de factoraje financiero, las inversiones en acciones de fondos de inversión en instrumentos de deuda y las operaciones financieras derivadas señaladas en la fracción IX del artículo 20 de esta Ley.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>NO SE CONSIDERAN CREDITOS</b><br>No se consideran créditos para los efectos del artículo anterior:'
            },
            { c1: 'Fracción I',
              c2: 'I.Los que sean a cargo de personas físicas y no provengan de sus actividades empresariales, cuando sean a la vista, a plazo menor de un mes o a plazo mayor si se cobran antes del mes. Se considerará que son a plazo mayor de un mes, si el cobro se efectúa después de 30 días naturales contados a partir de aquél en que se concertó el crédito.'
            },
            { c1: 'Fracción II',
              c2: 'II. Los que sean a cargo de socios o accionistas, asociantes o asociados en la asociación en participación, que sean personas físicas o sociedades residentes en el extranjero, salvo que en este último caso, estén denominadas en moneda extranjera y provengan de la exportación de bienes o servicios.<br>Tampoco se consideran créditos, los que la fiduciaria tenga a su favor con sus fideicomitentes o fideicomisarios en el fideicomiso por el que se realicen actividades empresariales, que sean personas físicas o sociedades residentes en el extranjero, salvo que en este último caso, estén denominadas en moneda extranjera y provengan de la exportación de bienes o servicios.<br>No será aplicable lo dispuesto en esta fracción, tratándose de créditos otorgados por las uniones de crédito a cargo de sus socios o accionistas, que operen únicamente con sus socios o accionistas.'
            },
            { c1: 'Fracción III',
              c2: 'III. Los que sean a cargo de funcionarios y empleados, así como los préstamos efectuados a terceros a que se refiere la fracción VII del artículo 27 de esta Ley.'
            },
             { c1: 'Fracción IV',
              c2: 'IV. Los pagos provisionales de impuestos, así como los estímulos fiscales.'
            },
             { c1: 'Fracción V',
              c2: 'V. Cualquier ingreso cuya acumulación esté condicionada a su percepción efectiva. Lo dispuesto en esta fracción no es aplicable a los ingresos derivados de los contratos de arrendamiento financiero por los que se ejerza la opción prevista en el artículo 17, fracción III de esta Ley.'
            },
             { c1: 'Fracción VI',
              c2: 'VI. Las acciones, los certificados de participación no amortizables y los certificados de depósito de bienes y en general los títulos de crédito que representen la propiedad de bienes, las aportaciones a una asociación en participación, así como otros títulos valor cuyos rendimientos no se consideren interés en los términos del artículo 8 de esta Ley'
            },
             { c1: 'Fracción VII',
              c2: 'VII. El efectivo en caja.'
            },
             { c1: 'Tercer párrafo',
              c2: '<b>CREDITOS POR INGRESOS ACUMULABLES DISMINUIDOS DE DESCUENTOS Y BONIFICACIONES</b><br>Los créditos que deriven de los ingresos acumulables, disminuidos por el importe de descuentos y bonificaciones sobre los mismos, se considerarán como créditos para los efectos de este artículo, a partir de la fecha en la que los ingresos correspondientes se acumulen y hasta la fecha en la que se cobren en efectivo, en bienes, en servicios o, hasta la fecha de su cancelación por incobrables. En el caso de la cancelación de la operación que dio lugar al crédito, se cancelará la parte del ajuste anual por inflación que le corresponda a dicho crédito, en los términos que establezca el Reglamento de esta Ley, siempre que se trate de créditos que se hubiesen considerado para dicho ajuste.'
            },
             { c1: 'Cuarto párrafo',
              c2: '<b>SALDOS A FAVOR POR CONTRIBUCIONES</b><br>Para los efectos de este artículo, los saldos a favor por contribuciones únicamente se considerarán créditos a partir del día siguiente a aquél en el que se presente la declaración correspondiente y hasta la fecha en la que se compensen, se acrediten o se reciba su devolución, según se trate.'
            },
          ]
        },
        { title1: 'LISR 46', title2: 'CONCEPTO DE DEUDA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para los efectos del artículo 44 de esta Ley, se considerará deuda, cualquier obligación en numerario pendiente de cumplimiento, entre otras: las derivadas de contratos de arrendamiento financiero, de operaciones financieras derivadas a que se refiere la fracción IX del artículo 20 de la misma, las aportaciones para futuros aumentos de capital y las contribuciones causadas desde el último día del periodo al que correspondan y hasta el día en el que deban pagarse.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>PASIVOS Y RESERVAS DEDUCIBLES</b><br>También son deudas, los pasivos y las reservas del activo, pasivo o capital, que sean o hayan sido deducibles. Para estos efectos, se considera que las reservas se crean o incrementan mensualmente y en la proporción que representan los ingresos del mes del total de ingresos en el ejercicio.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>DEUDAS ORIGINADAS POR PARTIDAS NO DEDUCIBLES O CON PARTES RELACIONADAS QUE EXCEDAN EL CAPITAL</b><br>En ningún caso se considerarán deudas las originadas por partidas no deducibles, en los términos de las fracciones I, VIII y IX del artículo 28 de esta Ley, así como el monto de las deudas que excedan el límite a que se refiere el primer párrafo de la fracción XXVII y el monto de las deudas de las cuales deriven intereses no deducibles de conformidad con la fracción XXXII del mismo artículo, según sean aplicables durante el ejercicio. Sin embargo, en el caso de la fracción XXXII del artículo 28 de esta Ley, cuando el monto de los intereses no deducibles, sea deducido en un ejercicio posterior de conformidad con dicha fracción, el monto de la deuda de la cual deriven dichos intereses sí se considerará para el cálculo señalado en el artículo 44 de esta Ley en dicho ejercicio.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>MOMENTOS EN LOS QUE SE CONSIDERA SE CONTRAEN DEUDAS</b><br>Para los efectos del artículo 44 de esta Ley, se considerará que se contraen deudas por la adquisición de bienes y servicios, por la obtención del uso o goce temporal de bienes o por capitales tomados en préstamo, cuando se dé cualquiera de los supuestos siguientes:'
            },
            { c1: 'Fracción I',
              c2: '<b>ADQUISICION DE BIENES O SERVICIOS U OBTENCION DEL USO O GOCE TEMPORAL DE BIENES</b><br>I. Tratándose de la adquisición de bienes o servicios, así como de la obtención del uso o goce temporal de bienes, cuando se dé alguno de los supuestos previstos en el artículo 17 de esta Ley y el precio o la contraprestación, se pague con posterioridad a la fecha en que ocurra el supuesto de que se trate.'
            },
            { c1: 'Fracción II',
              c2: '<b>CAPITALES TOMADOS EN PRESTAMO</b><br>II. Tratándose de capitales tomados en préstamo, cuando se reciba parcial o totalmente el capital.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>CANCELACION DE OPERACIONES DE LAS QUE DERIVEN DEUDAS</b><br>En el caso de la cancelación de una operación de la cual deriva una deuda, se cancelará la parte del ajuste anual por inflación que le corresponda a dicha deuda, en los términos que establezca el Reglamento de esta Ley, siempre que se trate de deudas que se hubiesen considerado para dicho ajuste.'
            },
          
          ]
        },
        { title1: 'RISR 85', title2: 'SALDO PROMEDIO ANUAL DE DEUDAS Y SALDO PROMEDIO ANUAL DE CRÉDITOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para efectos de los artículos 8, párrafo cuarto y 44 de la Ley, los contribuyentes para la cuantificación del cálculo anual por inflación, además de aplicar lo dispuesto en la fracción I del artículo 44 de la Ley, considerarán en la determinación del saldo promedio anual de sus deudas y del saldo promedio anual de sus créditos, lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: 'I. Quienes realicen el pago de la contraprestación por la cesión de derechos sobre los ingresos por otorgar el uso o goce temporal de inmuebles, para el cálculo del saldo promedio anual de sus créditos considerarán el valor total de la contraprestación, así como el plazo que se hubiera determinado en el contrato, y'
            },
            { c1: 'Fracción II',
              c2: 'II. Quienes reciban el pago de la contraprestación por la cesión de derechos sobre los ingresos por otorgar el uso o goce temporal de inmuebles, para el cálculo del saldo promedio de sus deudas considerarán el pago de dicha contraprestación como parte de sus deudas.'
            },
            
          ]
        },
        { title1: 'RISR 86', title2: 'RESERVAS QUE PODRÁN NO CONSIDERARSE COMO DEUDAS',
          content: [
            { c1: '',
              c2: 'Para efectos del artículo 44 de la Ley, los contribuyentes podrán no considerar como deudas las reservas complementarias para los fondos a que se refiere el artículo 29 de la Ley, siempre que dichas reservas tampoco se consideren como créditos para efectos del artículo 44 de la Ley."'
            },
          ],
        },
        { title1: 'RISR 87', title2: 'CONCEPTO DE CANCELACIÓN DE OPERACIÓN',
          content: [
            { c1: '',
              c2: '<br>Para efectos de los artículos 45 y 46 de la Ley, 31 y 88 de este Reglamento, se entenderá cancelación de una operación que dio lugar a un crédito o deuda, según sea el caso, la devolución total o parcial de bienes; el descuento o la bonificación que se otorgue sobre el precio o el valor de los bienes o servicios, así como la nulidad, la rescisión o la resolución de los contratos de los que derive el crédito o la deuda.'
            },
          ],
        },


        { title1: 'RISR 88', title2: 'CANCELACIÓN DEL AJUSTE ANUAL POR INFLACIÓN POR OPERACIONES CANCELADAS',
          content: [
            {
              c1: 'Primer párrafo',
              c2: 'Para efectos de los artículos 45, párrafo penúltimo y 46, párrafo último de la Ley, los contribuyentes cancelarán la parte del ajuste anual por inflación correspondiente al crédito o deuda de la operación cancelada, conforme a lo siguiente:',
            },
            {
              c1: 'Fracción I',
              c2: '<b>CANCELACION DE LA OPERACION ANTES DEL CUARTO MES DEL EJERCICIO SIGUIENTE</b><br>I. Si la cancelación de la operación que dio lugar a un crédito o a una deuda ocurre antes del cuarto mes del ejercicio siguiente a aquél en el que se concertó la operación de que se trate, los contribuyentes restarán del saldo promedio anual de los créditos o de las deudas del ejercicio en el que se acumuló el ingreso o se concertó la deuda que se cancelan, según corresponda, el saldo promedio del crédito o de la deuda del periodo en el que se consideró como crédito o como deuda conforme a lo dispuesto en los artículos 45 y 46 de la Ley.<br>Asimismo, cuando los créditos o deudas sean cancelados en los primeros tres meses del ejercicio siguiente a que se refiere el párrafo anterior, no se considerarán en el saldo promedio anual de los créditos o de las deudas, según corresponda, del ejercicio en el que se cancele la operación de que se trate, y',
            },
            { c1: 'Fracción II',
              c2: '<b>CANCELACION DE LA OPERACION A PARTIR DEL CUARTO MES DEL EJERCICIO SIGUIENTE</b><br>Si la cancelación de la operación que dio lugar a un crédito o a una deuda ocurre a partir del cuarto mes del ejercicio siguiente a aquél en el que se acumuló el ingreso o se contrajo la deuda que se cancelan, los contribuyentes estarán a lo siguiente:<br>a) Restarán el promedio de los saldos que el crédito que se cancela haya tenido al final de cada uno de los meses que abarque el periodo comprendido desde la fecha en la que se acumuló el ingreso del cual proviene el crédito que se cancela hasta el mes en el que se cancela la operación respectiva, del saldo promedio anual de los créditos del ejercicio en el cual se cancele la operación, y<br>b) Restarán el promedio de los saldos que la deuda que se cancela haya tenido al final de cada uno de los meses que abarque el periodo comprendido desde la fecha en la que se contrajo la deuda que se cancela hasta el mes en el que se cancela la operación respectiva, del saldo promedio anual de las deudas del ejercicio en el cual se cancele la operación.<br>'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>SALDO PROMEDIO DE LOS CREDITOS O DEUDAS QUE SE CANCELAN</b><br>El saldo promedio de los créditos o deudas que se cancelan, según corresponda, se calculará dividiendo la suma de los saldos al final de cada uno de los meses que abarque el periodo durante el cual se consideraron como crédito o deuda, entre el número de meses que abarque dicho periodo.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>CASOS EN QUE NO ES NECESARIO CANCELAR EL AJUSTE ANUAL POR INFLACION</b><br>Cuando la totalidad de los créditos o deudas que se cancelan deriven de ingresos o deducciones propias de la actividad del contribuyente y no excedan del 5% del total de ingresos acumulables o deducciones autorizadas, según sea el caso, correspondientes al periodo comprendido desde el mes en el que se concertó la operación de que se trate hasta aquél en el que se canceló, no será necesario efectuar la cancelación del ajuste anual por inflación a que se refiere este artículo.'
            },
          ],
        },
        { title1: 'RM 2022 3.4.1.', title2: 'APORTACIONES GUBERNAMENTALES PARA OBRAS PÚBLICAS QUE NO SE CONSIDERAN DEUDAS',
          content: [
            {
              c1: '',
              c2: 'Los contribuyentes que obtengan concesiones, autorizaciones o permisos para la construcción, operación y mantenimiento de las obras públicas que se señalan en el Anexo 2 y la regla 3.3.2.4., podrán no considerar como deudas para los efectos del artículo 46 de la Ley del ISR, las aportaciones que reciban de la Federación, de las entidades federativas o de los municipios, así como de sus organismos descentralizados, siempre que no se reserven el derecho a participar en los resultados de la concesión, autorización o permiso, o su participación en dichos resultados sea hasta que se termine la concesión de que se trate y se hayan cumplido previamente con todas las obligaciones de pago establecidas en el contrato de concesión respectivo incluyendo el reembolso a la concesionaria del capital de riesgo efectivamente aportado y sus rendimientos.',
            }
          ],
        },
        { title1: 'RM 2022 3.4.2.', title2: 'AJUSTE ANUAL POR INFLACIÓN DEL MARGEN POR VARIACIÓN EN OPERACIONES DERIVADAS ESTANDARIZADAS OPERADAS EN MERCADOS RECONOCIDOS',
          content: [
            {
              c1: '',
              c2: 'Para los efectos del artículo 44, fracción I, segundo párrafo de la Ley del ISR, en relación a las operaciones derivadas estandarizadas a que se refiere la regla 3.1.13., fracción II, los clientes para determinar el saldo promedio anual de los créditos y deudas, considerarán la suma de los saldos diarios provenientes de las ganancias o pérdidas del margen por variación del costo de fondeo producto de la valuación a mercado diaria de las operaciones derivadas estandarizadas antes señaladas, dividida entre el número de días del ejercicio fiscal de que se trate.',
            }
          ],
        },
        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
