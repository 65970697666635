import { render, staticRenderFns } from "./isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subordinado.vue?vue&type=template&id=e5f7b070&lang=html&"
import script from "./isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subordinado.vue?vue&type=script&lang=js&"
export * from "./isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subordinado.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports