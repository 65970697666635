<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col" v-html="item.title2 "></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
      
        { title1: 'LSS 27', title2: 'INTEGRACIÓN DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El salario base de cotización se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, alimentación, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo. Se excluyen como integrantes del salario base de cotización, dada su naturaleza, los siguientes conceptos:'
            },
            { c1: 'Fracción I',
              c2: '<b>INSTRUMENTOS DE TRABAJO</b><br>I. Los instrumentos de trabajo tales como herramientas, ropa y otros similares;'
            },
            { c1: 'Fracción II',
              c2: '<b>EL AHORRO, BAJO CIERTAS CONDICIONES</b><br>II. El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'Fracción III',
              c2: '<b>COUTAS ADICIONALES DEL SEGURO DE RCV</b><br>III. Las aportaciones adicionales que el patrón convenga otorgar a favor de sus trabajadores por concepto de cuotas del seguro de retiro, cesantía en edad avanzada y vejez;'
            },
            { c1: 'Fracción IV',
              c2: '<b>CUOTAS IMSS, APORTACIONES INFONAVIT Y PTU</b><br>IV. Las cuotas que en términos de esta Ley le corresponde cubrir al patrón, las aportaciones al Instituto del Fondo Nacional de la Vivienda para los Trabajadores, y las participaciones en las utilidades de la empresa;'
            },
            { c1: 'Fracción V',
              c2: '<b>ALIMENTACIÓN Y HABITACIÓN, SU PROPORCIONALIDAD</b><br> V. La alimentación y la habitación cuando se entreguen en forma onerosa a los trabajadores; se entiende que son onerosas estas prestaciones cuando el trabajador pague por cada una de ellas, como mínimo, el veinte por ciento del salario mínimo general diario que rija en el Distrito Federal;'
            },
            { c1: 'Fracción VI',
              c2: '<b>DESPENSAS, SU PROPORCIONALIDAD</b><br>VI. Las despensas en especie o en dinero, siempre y cuando su importe no rebase el cuarenta por ciento del salario mínimo general diario vigente en el Distrito Federal;'
            },
            { c1: 'Fracción VII',
              c2: '<b>PREMIOS POR ASISTENCIA Y PUNTUALIDAD, SU PROPORCIONALIDAD</b><br>VII. Los premios por asistencia y puntualidad, siempre que el importe de cada uno de estos conceptos no rebase el diez por ciento del salario base de cotización;'
            },
            { c1: 'Fracción VIII',
              c2: '<b>APORTACIONES PARA PLANES DE PENSIONES, SUS CONDICIONANTES</b><br>VIII. Las cantidades aportadas para fines sociales, considerándose como tales las entregadas para constituir fondos de algún plan de pensiones establecido por el patrón o derivado de contratación colectiva. Los planes de pensiones serán sólo los que reúnan los requisitos que establezca la Comisión Nacional del Sistema de Ahorro para el Retiro, y'
            },
            { c1: 'Fracción IX',
              c2: '<b>TIEMPO EXTRAORDINARIO, SUS LIMITANTES</b><br>IX. El tiempo extraordinario dentro de los márgenes señalados en la Ley Federal del Trabajo .'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>REGISTRO CONTABLE DE CONCEPTOS</b><br>Para que los conceptos mencionados en este precepto se excluyan como integrantes del salario base de cotización, deberán estar debidamente registrados en la contabilidad del patrón.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>INTEGRACIÓN DE LOS EXCEDENTES</b><br>En los conceptos previstos en las fracciones VI, VII y IX cuando el importe de estas prestaciones rebase el porcentaje establecido, solamente se integrarán los excedentes al salario base de cotización.'
            },
          ]
        },
        { title1: 'LSS 28', title2: 'LÍMITES DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los asegurados se inscribirán con el salario base de cotización que perciban en el momento de su afiliación, estableciéndose como límite superior el equivalente a veinticinco veces el salario mínimo general que rija en el Distrito Federal y como límite inferior el salario mínimo general del área geográfica respectiva.'
            },
            
          ]
        },
        { title1: 'LSS 30', title2: 'DETERMINACIÓN DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para determinar el salario diario base de cotización se estará a lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: '<b>SALARIO FIJO</b><br>I. Cuando además de los elementos fijos del salario el trabajador percibiera regularmente otras retribuciones periódicas de cuantía previamente conocida, éstas se sumarán a dichos elementos fijos;'
            },
            { c1: 'Fracción II',
              c2: '<b>SALARIO VARIABLE</b><br>II. Si por la naturaleza del trabajo, el salario se integra con elementos variables que no puedan ser previamente conocidos, se sumarán los ingresos totales percibidos durante los dos meses inmediatos anteriores y se dividirán entre el número de días de salario devengado en ese periodo. Si se trata de un trabajador de nuevo ingreso, se tomará el salario probable que le corresponda en dicho periodo, y<br>…………………………………………………'
            },
          ]
        },
        { title1: 'LSS 32', title2: 'AUMENTOS PARA ALIMENTACIÓN Y HABITACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Si además del salario en dinero el trabajador recibe del patrón, sin costo para aquél, habitación o alimentación, se estimará aumentado su salario en un veinticinco por ciento y si recibe ambas prestaciones se aumentará en un cincuenta por ciento.'
            },
            { c1: 'Segundo párrafo',
              c2: 'Cuando la alimentación no cubra los tres alimentos, sino uno o dos de éstos, por cada uno de ellos se adicionará el salario en un ocho punto treinta y tres por ciento.'
            },
            
          ]
        },

        { title1: 'LINFONAVIT 29', title2: 'OBLIGACIONES PATRONALES',
          content: [
           
            { c1: 'Fracción II',
              c2: 'Son obligaciones de los patrones:<br>……………………………………………………………………………………………………………………<br><b>DETERMINACIÓN Y ENTERO DE APORTACIONES</b><br>II. Determinar el monto de las aportaciones del cinco por ciento sobre el salario de los trabajadores a su servicio y efectuar el pago en las entidades receptoras que actúen por cuenta y orden del Instituto, para su abono en la subcuenta de vivienda de las cuentas individuales de los trabajadores previstas en los sistemas de ahorro para el retiro, en los términos de la presente Ley y sus reglamentos, así como en lo conducente, conforme a lo previsto en la Ley del Seguro Social y en la Ley Federal del Trabajo. En lo que corresponde a la integración y cálculo de la base y límite superior salarial para el pago de aportaciones, se aplicará lo contenido en la Ley del Seguro Social.<br>……………………………………………………………………………………………………………………'
            },
            
          ]
        },

        { title1: 'LFT 68', title2: 'PROLONGACIÓN DEL TIEMPO EXTRAORDINARIO MAYOR A NUEVE HORAS A LA SEMANA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los trabajadores no están obligados a prestar sus servicios por un tiempo mayor del permitido en este capítulo.'
            },
            { c1: 'Segundo párrafo',
              c2: 'La prolongación del tiempo extraordinario que exceda de nueve horas a la semana, obliga al patrón a pagar al trabajador el tiempo excedente con un doscientos por ciento más del salario que corresponda a las horas de la jornada, sin perjuicio de las sanciones establecidas en esta Ley.'
            },
          ]
        },
        { title1: 'LFT 84', title2: 'INTEGRACIÓN DEL SALARIO FIJO Y VARIABLE',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El salario se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo.'
            },
          ]
        },
        
        { title1: 'LUMA 4', title2: 'METODOLOGÍA PARA CALCULAR Y DETERMINAR ANUALMENTE EL VALOR ACTUALIZADO DE LA UMA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El valor actualizado de la UMA se calculará y determinará anualmente por el INEGI, de conformidad con el siguiente método:'
            },
            { c1: 'Fracción I',
              c2: '<b>DETERMINACIÓN DEL VALOR DIARIO</b><br>I. El valor diario se determinará multiplicando el valor diario de la UMA del año inmediato anterior por el resultado de la suma de uno más la variación interanual del índice Nacional de Precios al Consumidor del mes de diciembre del año inmediato anterior.'
            },
            { c1: 'Fracción II',
              c2: '<b>DETERMINACIÓN DEL VALOR MENSUAL</b><br>II. El valor mensual será el producto de multiplicar el valor diario de la UMA por 30.4.'
            },
            { c1: 'Fracción III',
              c2: '<b>DETERMINACIÓN DEL VALOR ANUAL</b><br>III. El valor anual será el producto de multiplicar el valor mensual de la UMA por 12.'
            },
          ]
        },
        { title1: 'LUMA 5', title2: 'PUBLICACIÓN DEL VALOR DE LA UMA E INICIO DE VIGENCIA DE ÉSTE',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El INEGI publicará en el Diario Oficial de la Federación dentro de los primeros diez días del mes de enero de cada año el valor diario, mensual y anual en moneda nacional de la UMA y entrarán en vigor dichos valores el 1o. de febrero de dicho año.'
            },
          ]
        },
        { title1: 'ACDO.SA2.HCT.250117/26.P.DJ ', title2: '',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Acuerdo ACDO.SA2.HCT.250117/26.P.DJ del 25-I-2017, que el límite inferior del SBC será el salario mínimo, pues está expresamente prohibida la inscripción al Instituto por debajo de ese límite, y el límite máximo será de 25 UMAS por constituir una referencia'
            },
          ]
        },

        
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
