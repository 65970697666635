import { render, staticRenderFns } from "./salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue?vue&type=template&id=08fcd52b&lang=html&"
import script from "./salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue?vue&type=script&lang=js&"
export * from "./salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue?vue&type=script&lang=js&"
import style0 from "./salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports