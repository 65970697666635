import { render, staticRenderFns } from "./isr-e-iva-por-actividades-empresariales-profesionales.vue?vue&type=template&id=14a949c6&lang=html&"
import script from "./isr-e-iva-por-actividades-empresariales-profesionales.vue?vue&type=script&lang=js&"
export * from "./isr-e-iva-por-actividades-empresariales-profesionales.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports