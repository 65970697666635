<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" v-html="item.title1" width="178"></th>
            <th scope="col" v-html="item.title2"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th v-html="row.c1"></th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [
        { title1: 'LIESPYS 1', title2: 'SUJETOS DEL IMPUESTO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Están obligadas al pago del impuesto establecido en esta Ley, las personas físicas y las morales que realicen los actos o actividades siguientes:'
            },
            { c1: 'Fracción I',
              c2: '<b>ACTIVIDADES QUE SE GRAVAN</b><br>La enajenación en territorio nacional o, en su caso, la importación de los bienes señalados en esta Ley. Para efectos de la presente Ley se considera importación la introducción al país de bienes.<br>…………………………………………………'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CALCULO DEL IMPUESTO</b><br>El impuesto se calculará aplicando a los valores a que se refiere este ordenamiento, la tasa que para cada bien o servicio establece el artículo 2o. del mismo o, en su caso, la cuota establecida en esta Ley.<br>…………………………………………………'
            },
            
          ]
        },
        { title1: 'LIESPYS 2', title2: 'TASAS Y CUOTAS DEL IMPUESTO',
          content: [
            { c1: 'Primer párrafo ',
              c2: 'Al valor de los actos o actividades que a continuación se señalan, se aplicarán las tasas y cuotas siguientes:'
            },
            { c1: 'Fracción I',
              c2: '<b>ENAJENACION O IMPORTACION DE BIENES</b><br>I. En la enajenación o, en su caso, en la importación de los siguientes bienes:'
            },
            { c1: 'Inciso A)',
              c2: 'A) Bebidas con contenido alcohólico y cerveza:<br>1. Con una graduación alcohólica de hasta 14° G.L. ............................................26.5%<br>2. Con una graduación alcohólica de más de 14° y hasta 20° G.L. .......................30%<br>3. Con una graduación alcohólica de más de 20° G.L. ...........................................53%<br>…………………………………………………'
            },
          ]
        },
        { title1: '<em>LIESPYS 2-C</em>', title2: '<em>PAGO DEL IMPUESTO POR FABRICANTES, PRODUCTORES, ENVASADORES O IMPORTADORES DE CERVEZA</em>',
          content: [
            { c1: '<em>Primer párrafo</em>',
              c2: '<em><b>Artículo derogado a partir del 1o. de enero de 2020</b><br>Para los efectos del artículo 2o., fracción I, inciso A) de esta Ley, los fabricantes, productores o envasadores de cerveza, que la enajenen y quienes la importen, pagarán el impuesto que resulte mayor entre aplicar la tasa prevista en dicho inciso al valor de la enajenación o importación de cerveza, según se trate, y aplicar una cuota de $3.00 por litro enajenado o importado de cerveza, disminuida, en los casos que proceda, con el monto a que se refiere el siguiente párrafo. En estos casos, el impuesto no podrá ser menor al que resulte de aplicar la tasa prevista en el citado inciso a la enajenación o importación de cerveza.</em>'
            },
            { c1: '<em>Segundo párrafo</em>',
              c2: '<em><b>DISMINUCION DE CUOTA Y TRASLADO DEL IMPUESTO</b><br>Los fabricantes, productores, envasadores o importadores de cerveza, podrán disminuir de la cuota de $3.00 por litro a que se refiere el párrafo anterior, $1.26 por litro de cerveza enajenado o importado en envases reutilizados en los términos de esta Ley. El monto de $1.26 por litro en ningún caso podrá disminuirse del impuesto que resulte de aplicar a las actividades gravadas, la tasa prevista en dicho inciso. Los citados fabricantes, productores o envasadores, deberán trasladar el importe mayor que resulte conforme a lo dispuesto en este artículo.</em>'
            },
           
          ]
        },
        { title1: 'LIESPYS 3', title2: 'DEFINICIÓN DE CONCEPTOS',
          content: [
            { c1: 'Fracción II',
              c2: '<b>CERVEZA</b><br>II. Cerveza, la bebida fermentada, elaborada con malta de cebada, lúpulo, levadura y agua o con infusiones de cualquier semilla farinácea procedente de gramíneas o leguminosas, raíces o frutos feculentos o azúcares como adjuntos de la malta, con adición de lúpulo o sucedáneos de éste.'
            },
            
           
          ]
        },
       
        /*{ title1: '', title2: 'ACTIVIDADES QUE SE GRAVAN',
          content: [
            { c1: 'IESPS-A1-P1-F1',
              c2: 'La enajenación en territorio nacional o, en su caso, la importación de los bienes señalados en esta Ley. Para efectos de la presente Ley se considera importación la introducción al país de bienes.'
            },
          ]
        },
        { title1: '', title2: 'CALCULO DEL IMPUESTO',
          content: [
            { c1: 'IESPS-A1-P1-F2',
              c2: 'El impuesto se calculará aplicando a los valores a que se refiere este ordenamiento, la tasa que para cada bien o servicio establece el artículo 2o. del mismo o, en su caso, la cuota establecida en esta Ley.'
            },
          ]
        },
        { title1: '', title2: 'Tasas y cuotas del impuesto',
          content: [
            { c1: 'IESPS-A2-P1',
              c2: 'Al valor de los actos o actividades que a continuación se señalan, se aplicarán las tasas y cuotas siguientes:'
            },
          ]
        },
        { title1: '', title2: 'ENAJENACION O IMPORTACION DE BIENES',
          content: [
            { c1: 'IESPS-A2-P1-F1-A)',
              c2: 'En la enajenación o, en su caso, en la importación de los siguientes bienes:<br>A) Bebidas con contenido alcohólico y cerveza:<br>1. Con una graduación alcohólica de hasta 14° G.L. ............................................26.5<br>2. Con una graduación alcohólica de más de 14° y hasta 20° G.L. .......................30%<br>3. Con una graduación alcohólica de más de 20° G.L. ...........................................53%'
            },
          ]
        },
        { title1: '', title2: '2 -C. Pago del impuesto por fabricantes, productores, envasadores o importadores de cerveza',
          content: [
            { c1: 'IESPS-A2C-P1',
              c2: 'Para los efectos del artículo 2o., fracción I, inciso A) de esta Ley, los fabricantes, productores o envasadores de cerveza, que la enajenen y quienes la importen, pagarán el impuesto que resulte mayor entre aplicar la tasa prevista en dicho inciso al valor de la enajenación o importación de cerveza, según se trate, y aplicar una cuota de $3.00 por litro enajenado o importado de cerveza, disminuida, en los casos que proceda, con el monto a que se refiere el siguiente párrafo. En estos casos, el impuesto no podrá ser menor al que resulte de aplicar la tasa prevista en el citado inciso a la enajenación o importación de cerveza.'
            },
          ]
        },
        { title1: '', title2: 'DISMINUCION DE CUOTA Y TRASLADO DEL IMPUESTO',
          content: [
            { c1: 'IESPS-A2C-P2',
              c2: 'Los fabricantes, productores, envasadores o importadores de cerveza, podrán disminuir de la cuota de $3.00 por litro a que se refiere el párrafo anterior, $1.26 por litro de cerveza enajenado o importado en envases reutilizados en los términos de esta Ley. El monto de $1.26 por litro en ningún caso podrá disminuirse del impuesto que resulte de aplicar a las actividades gravadas, la tasa prevista en dicho inciso. Los citados fabricantes, productores o envasadores, deberán trasladar el importe mayor que resulte conforme a lo dispuesto en este artículo.'
            },
          ]
        },
        { title1: '', title2: 'Definición de conceptosç',
          content: [
            { c1: 'IESPYS-A3-P1',
              c2: 'Para los efectos de esta Ley se entiende por:'
            },
            { c1: '',
              c2: 'CERVEZA'
            },
            { c1: 'IESPYS-A3-P1-F2',
              c2: 'Cerveza, la bebida fermentada, elaborada con malta de cebada, lúpulo, levadura y agua o con infusiones de cualquier semilla farinácea procedente de gramíneas o leguminosas, raíces o frutos feculentos o azúcares como adjuntos de la malta, con adición de lúpulo o sucedáneos de éste.'
            },
            { c1: '',
              c2: 'BEBIDAS ALCOHOLICAS A GRANEL'
            },
            { c1: 'IESPYS-A3-P1-F3',
              c2: 'Bebidas alcohólicas a granel, las que se encuentren envasadas en recipientes cuya capacidad exceda a 5,000 mililitros.'
            },
          ]
        },
        */


       
      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
