<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col-lg col-titles col-mid">
          <h1>IESPYS por venta de cerveza</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Precio de venta por litro:"
              label-for="i-vent" id="input-precio_venta_litro">

              <currency-input id="i-vent" class="form-control"
                v-model="form.precio_venta_litro" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.precio_venta_litro != null" style="color: red;"><small>{{validation.precio_venta_litro}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Número de litros enajenados:"
              label-for="i-nul" id="input-numero_litros">

              <b-form-input
                id="i-nul"
                v-model="form.numero_litros"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"

              ></b-form-input>
              <div v-if="validation.numero_litros != null" style="color: red;"><small>{{validation.numero_litros}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="¿Utiliza envases reciclados?:"
              label-for="i-enva">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.utiliza_envases" id="i-enva" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                  			<b-form-select-option :value="null" disabled selected>Seleccione una opcion</b-form-select-option>
                  			<b-form-select-option :value="0">No</b-form-select-option>
                    		<b-form-select-option :value="1">Sí</b-form-select-option>
                    		
                  		</b-form-select>
                	</div>
             	</div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Contenido ° alcohólico:"
              label-for="i-coal" id="input-contenido_alcoholico">

              <b-form-input
                id="i-coal"
                v-model="form.contenido_alcoholico"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"
              ></b-form-input>
              <div v-if="validation.contenido_alcoholico != null" style="color: red;"><small>{{validation.contenido_alcoholico}}</small></div>
            </b-form-group>

             <b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>


            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">
             
              <div class="row row-body" v-if="form.anio < 2020">
                <div class="col col-detail">
                  IESPYS según Art. <span style="font-size: 19px;font-weight: 300;">2</span>, fracción I, inciso A, numeral <span style="font-size: 19px;font-weight: 300;">1</span>:
                </div>
                <div class="col col-med">
                  {{detailInfo.iesps_segun_art_fracc}} 
                </div>
              </div>
              <div class="row row-body" v-if="form.anio < 2020">
                <div class="col col-detail">
                  IESPYS según Art. <span style="font-size: 19px;font-weight: 300;">2</span>-C:
                </div>
                <div class="col col-med">
                  {{detailInfo.iesps_segun_art2c}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  IESPYS a pagar por venta de cerveza:

                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.total}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Tasa del impuesto:
                </div>
                <div class="col col-med">
                  {{detailInfo.taza_impuesto}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  Cuota complementaria hasta 2019:
                </div>
                <div class="col col-med">
                  {{detailInfo.cuota_complementaria}}
                </div>
              </div>


            </div>

            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
    
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-iesps-por-venta-de-cerveza.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form: {
       
      },

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        precio_venta_litro:null,
        numero_litros:null,
        contenido_alcoholico:null
      }
    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      
      event.preventDefault();
      this.validation.precio_venta_litro = null;
      this.validation.numero_litros = null;
      this.validation.contenido_alcoholico = null;

      if (this.form.precio_venta_litro == 0 || this.form.precio_venta_litro == null) {
          this.validation.precio_venta_litro = '"Precio de venta por litro" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-precio_venta_litro").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.numero_litros == 0 || this.form.numero_litros == null) {
          this.validation.numero_litros = '"Número de litros enajenados" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-numero_litros").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.contenido_alcoholico == 0 || this.form.contenido_alcoholico == null) {
          this.validation.contenido_alcoholico = '"Contenido ° alcohólico" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-contenido_alcoholico").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      $('#btn_calc').hide();
      this.$refs['modal-loading'].show();
      
      
      axios.post(tools.url("/calculateFive"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('Ocurrio un error al calcular la informacion');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 2012; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
