<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
export default {
  data(){
    return{
      items: [
        
        { title1: 'LISR 93', title2: 'INGRESOS EXENTOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'No se pagará el impuesto sobre la renta por la obtención de los siguientes ingresos:<br>....................................'
            },
           
            { c1: 'Fracción XIV',
              c2: '<b>GRATIFICACIONES, PRIMAS VACACIONALES, PTU Y PRIMAS DOMINICALES A TRABAJADORES</b><br>XIV. Las gratificaciones que reciban los trabajadores de sus patrones, durante un año de calendario, hasta el equivalente del salario mínimo general del área geográfica del trabajador elevado a 30 días, cuando dichas gratificaciones se otorguen en forma general; así como las primas vacacionales que otorguen los patrones durante el año de calendario a sus trabajadores en forma general y la participación de los trabajadores en las utilidades de las empresas, hasta por el equivalente a 15 días de salario mínimo general del área geográfica del trabajador, por cada uno de los conceptos señalados. Tratándose de primas dominicales hasta por el equivalente de un salario mínimo general del área geográfica del trabajador por cada domingo que se labore.'
            },
            { c1: 'Fracción XV',
              c2: '<b>EXCEDENTE DE LOS INGRESOS</b><br>XV. Por el excedente de los ingresos a que se refiere la fracción anterior se pagará el impuesto en los términos de este Título.<br>....................................'
            },
          ]
        },
        { title1: 'LISR 94', title2: 'INGRESOS QUE SE GRAVAN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Se consideran ingresos por la prestación de un servicio personal subordinado, los salarios y demás prestaciones que deriven de una relación laboral, incluyendo la participación de los trabajadores en las utilidades de las empresas y las prestaciones percibidas como consecuencia de la terminación de la relación laboral. Para los efectos de este impuesto, se asimilan a estos ingresos los siguientes:<br>....................................'
            },
            
            { c1: 'Quinto párrafo',
              c2: 'El pago del impuesto a que se refiere este artículo deberá efectuarse mediante retención que efectúen las citadas personas morales'
            }, 
          ]
        },
        
        { title1: 'LISR 96', title2: 'RETENCIONES Y ENTEROS MENSUALES DE PAGOS PROVISIONALES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Quienes hagan pagos por los conceptos a que se refiere este Capítulo están obligados a efectuar retenciones y enteros mensuales que tendrán el carácter de pagos provisionales a cuenta del impuesto anual. No se efectuará retención a las personas que en el mes únicamente perciban un salario mínimo general correspondiente al área geográfica del contribuyente.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CÁLCULO DE LA RETENCIÓN</b><br>La retención se calculará aplicando a la totalidad de los ingresos obtenidos en un mes de calendario, la siguiente:<br><b>Tarifa mensual</b><br><table><tr><td>Límite inferior $</td><td>Límite superior $</td><td>Cuota fija $</td><td>Por ciento para aplicarse sobre el excedente del límite inferior %</td></tr><tr><td>0.01</td><td>644.58</td><td>0.00</td><td>1.92<td></tr><tr><td>644.59</td><td>5,470.92</td><td>12.38</td><td>6.40<td></tr><tr><td>5,470.93</td><td>9,614.66</td><td>321.26</td><td>10.88<td></tr><tr><td>9,614.67</td><td>11,176.62</td><td>772.10</td><td>16.00<td></tr><tr><td>11,176.63</td><td>13,381.47</td><td>1,022.01</td><td>17.92<td></tr><tr><td>13,381.48</td><td>26,988.50</td><td>1,417.12</td><td>21.36<td></tr><tr><td>26,988.51</td><td>42,537.58</td><td>4,323.58</td><td>23.52<td></tr><tr><td>42,537.59</td><td>81,211.25</td><td>7,980.73</td><td>30.00<td></tr><tr><td>81,211.26</td><td>108,281.67</td><td>19,582.83</td><td>32.00<td></tr><tr><td>108,281.68</td><td>324,845.01</td><td>28,245.36</td><td>34.00<td></tr><tr><td>324,845.02</td><td>En adelante</td><td>101,876.90</td><td>35.00<td></tr></table>'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>BASE OPCIONAL PARA LA RETENCIÓN DEL IMPUESTO</b><br>Quienes hagan pagos por concepto de gratificación anual, participación de utilidades, primas dominicales y primas vacacionales, podrán efectuar la retención del impuesto de conformidad con los requisitos que establezca el Reglamento de esta Ley; en las disposiciones de dicho Reglamento se preverá que la retención se pueda hacer sobre los demás ingresos obtenidos durante el año de calendario.<br>....................................'
            },
            
          ]
        },
        { title1: 'RISR 151', title2: 'GRATIFICACIONES INFERIORES AL SMG ELEVADO A TREINTA DÍAS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para efectos del artículo 93 , fracción XIV de la Ley, en el caso de que la gratificación sea inferior al monto equivalente al salario mínimo general del área geográfica del trabajador elevado a treinta días, no se pagará el Impuesto hasta por el monto de la gratificación otorgada aun cuando se calcule sobre un salario superior al mínimo.'
            },
           
          ]
        },
        { title1: 'RISR 174', title2: 'RETENCIÓN OPCIONAL EN PAGOS DE GRATIFICACIÓN, PTU Y PRIMAS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Tratándose de las remuneraciones por concepto de gratificación anual, participación de utilidades, primas dominicales y vacacionales a que se refiere el artículo 96 de la Ley, la persona que haga dichos pagos podrá optar por retener el Impuesto que corresponda conforme a lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: 'I. La remuneración de que se trate se dividirá entre 365 y el resultado se multiplicará por 30.4;'
            },
            { c1: 'Fracción II',
              c2: 'II. A la cantidad que se obtenga conforme a la fracción anterior, se le adicionará el ingreso ordinario por la prestación de un servicio personal subordinado que perciba el trabajador en forma regular en el mes de que se trate y al resultado se le aplicará el procedimiento establecido en el artículo 96 de la Ley;'
            },
            { c1: 'Fracción III',
              c2: 'III. El Impuesto que se obtenga conforme a la fracción anterior se disminuirá con el Impuesto que correspondería al ingreso ordinario por la prestación de un servicio personal subordinado a que se refiere dicha fracción, calculando este último sin considerar las demás remuneraciones mencionadas en este artículo;'
            },
            { c1: 'Fracción IV',
              c2: 'IV. El Impuesto a retener será el que resulte de aplicar a las remuneraciones a que se refiere este artículo, sin deducción alguna, la tasa a que se refiere la fracción siguiente, y'
            },
            { c1: 'Fracción V',
              c2: 'V. La tasa a que se refiere la fracción anterior, se calculará dividiendo el Impuesto que se determine en términos de la fracción III de este artículo entre la cantidad que resulte conforme a la fracción I de dicho artículo. El cociente se multiplicará por cien y el producto se expresará en por ciento.'
            },
            
           
          ]
        },
       
       

      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
