<template lang="html">
  <!--header id="header" v-if="$route.name != 'accessPage'" v-bind:class="{ active : isMenuActive }">

    <div class="header-content">
      <div class="container oversized-container">
        <div class="row align-items-center no-gutters">
          <div class="col col-user-info">
            <**-- NOTA IMPORTANTE. Solo poner EL PRIMER nombre, NO poner 2 nombres --**>
            <h5>Bienvenido <span class="txt-orange">Eduardo</span></h5>
            <h6>a la plataforma de herramientas</h6>
          </div>

          <div class="col col-header-content">
            <div class="row no-gutters align-items-center">
              <div class="col col-menu">
                <router-link class="nv-link" :to="{ name: 'homePage' }">
                  <i class="fal fa-home-alt icon"></i> Inicio
                </router-link>

                <router-link class="nv-link hide-767" to="/usuario">
                  <i class="fal fa-user icon"></i> Mi cuenta
                </router-link>
              </div>

              <div class="col col-navigation">
                <div class="box">
                  <div class="col col-profile">
                    <router-link class="placed-backg profile-photo" to="/usuario" v-bind:style="{ backgroundImage: 'url(public/images/shared/profile-photo.jpg)' }"></router-link>
                  </div>

                  <div class="col col-button">
                    <a class="btn-nav" v-bind:class="{ active : isMenuActive }" @click="isMenuActive = !isMenuActive"><i class="fal fa-bars"></i></a>
                  </div>
                </div>

                <div class="menu-container" v-bind:class="{ active : isMenuActive }">
                  <div class="trans-250 bg-menu" @click="isMenuActive = false"></div>

                  <div class="trans-250 box-menu">
                    <ul>
                      <li>
                        <router-link class="nav-link" to="">
                          <i class="n-notif">1</i> <span>Notificaciones</span>
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li>

                      <li class="d-sm-none">
                        <router-link class="nav-link" :to="{ name: 'homePage' }">
                          <i class="fal fa-home-alt icon"></i> Inicio
                        </router-link>
                      </li>
                      <li class="d-md-none">
                        <router-link class="nav-link" to="/usuario">
                          <i class="fal fa-user icon"></i> Mi Cuenta
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/catalogos">
                          <i class="fal fa-list-alt icon"></i> Catálogo de herramientas
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/blog">
                          <i class="fal fa-typewriter icon"></i> Notas de interes
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li>

                      <li>
                        <router-link class="nav-link" to="">
                          <i class="fal fa-question-circle icon"></i> Ayuda
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="">
                          <i class="fal fa-clipboard-list icon"></i> Términos y condiciones
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="">
                          <i class="fal fa-sticky-note icon"></i> Aviso de privacidad
                        </router-link>
                      </li>

                      <li class="li-sep"><a class="nav-link nav-sep"><hr /></a></li>

                      <li>
                        <router-link class="nav-link" to="/">
                          <i class="fal fa-sign-out-alt icon"></i> Cerrar sesión
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header-->
</template>

<script>
export default {
  data(){
    return{
      isMenuActive: false,
    }
  },

  watch: {
    '$route' (to, from){
      this.isMenuActive = false;
    },

    isMenuActive (val, Oldval) {
      if(this.isMenuActive == true){
        document.getElementById("app").classList.add("active-menu");
      }else{
        document.getElementById("app").classList.remove("active-menu");
      }
    }
  }
}
</script>
