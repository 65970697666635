<template lang="html">
  <div id="home-page">

    <section class="container oversized-container catalogs-e-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Catálogo de herramientas</h2>
            </div>

            <!-- <div class="col col-more">
              <router-link class="link-green" to="/catalogos">Ver todas</router-link>
            </div> -->
          </div>
        </div>

        <div class="col-12 col-catalogs">
          <div class="row">

            <div class="col-lg col-catalog" v-for="(catalog, cathInx) in catalogs" :key="'cathInx-'+cathInx">
              <router-link class="box" v-bind:style="{ backgroundImage: 'url('+catalog.img+')' }" :to="{ name: catalog.url }">
                <div class="inside">
                  <div class="row">
                    <div class="col col-icon">
                      <img :src="catalog.icon">
                    </div>

                    <div class="col col-info">
                      <p>{{ catalog.text }}</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </section>

    <!-- <section class="container oversized-container blog-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Notas de interés</h2>
            </div>

            <div class="col col-more">
              <router-link class="link-green" to="/blog">Ver todas</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-articles">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(x, baInx) in 4" :key="'ba-'+baInx">
          <router-link class="box-article" to="/blog/1">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/article-'+(baInx+1)+'.jpg)' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>Título de este artículo</h5>

              <div class="descr">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      catalogs: [
        { //CALCULADORA 1
          url: 'catActuRecaPage', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-1.svg', text: 'Actualización y recargos' },
        { //CALCULADORA 2
          url: 'catISREnaInmPerFis', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-2.svg', text: 'ISR por enajenación de Inmuebles por personas físicas' },
        { //CALCULADORA 3
          url: 'catISRIVAactempypro', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-3.svg', text: 'ISR e IVA por actividades empresariales y profesionales' },
        { //CALCULADORA 4
          url: 'catISRPagoAguiPage', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-4.svg', text: 'ISR por pago de aguinaldo' },

        {//CALCULADORA 5
          url: 'IESPSVentaCerveza', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-5.svg', text: 'IESPS por venta de cerveza' },
        {//CALCULADORA 6
          url: 'DividendosADistribuir', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-6.svg', text: 'Dividendos por distribuir a accionistas e ISR a cargo' },

         {//CALCULADORA 7
          url: 'AjusteAnualPorInflacion', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-7.svg', text: 'Ajuste anual por inflación' },
        {//CALCULADORA 8
          url: 'PagoContribucionesDiferidas', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-8.svg', text: 'Pago de contribuciones diferidas' },

        {//CALCULADORA 9
          url: 'PagoContribucionesParcialidades', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-9.svg', text: 'Pago de contribuciones en parcialidades' },

        {//CALCULADORA 10
          url: 'AmortizacionPerdidasFiscales', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-10.svg', text: 'Amortizacion de perdidas fiscales' },

        {//CALCULADORA 11
          url: 'IsrEjercicioGananciaEnajecion', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-11.svg', text: 'ISR del ejercicio por la ganancia en la enajenación de inmuebles' },

        {//CALCULADORA 12
        url: 'IsrPagoIndemnizacionesRetiro', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-12.svg', text: 'ISR por el pago de las indemnizaciones por retiro' },

        {//CALCULADORA 13
        url: 'IsrIvaArrendamientoPagosProvisionales', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-13.svg', text: 'ISR e IVA por arrendamiento pagos provisionales' },


        {//CALCULADORA 14 ** Referencia circular
        url: 'IsrPorlaPrestacionDeunServicioPersonalSubordinado', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-14.svg', text: 'ISR por la prestación de un servicio personal subordinado (salarios)' },


        {//CALCULADORA 15
        url: 'IsrPorelPagoDelFiniquitoPorelRetiroVoluntario', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-15.svg', text: 'ISR por el pago del finiquito por retiro voluntario' },

        {//CALCULADORA 16
        url: 'IsrPorIngresosAsimiladosAlaPrestacionDeunServicioPersonalSubordinado', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-16.svg', text: 'ISR por ingresos asimilados a la prestación de un servicio personal subordinado (salarios)' },
        {//CALCULADORA 17
        url: 'IsrPorPagoDePtu', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-17.svg', text: 'ISR por pago de PTU' },
        {//CALCULADORA 18
        url: 'DeterminacionDelasCuotasObreroPatronalesBimestrales', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-18.svg', text: 'Determinación de las coutas obrero patronales bimestrales' },
        {//CALCULADORA 19
        url: 'SalarioDiarioBaseDeCotizacionParaEfectosDelImssEInfonavit', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-19.svg', text: 'Salario diario base de cotización para efectos del IMSS e INFONAVIT' },

        {//CALCULADORA 20
        url: 'DeterminacionDelasCuotasObreroPatronalesMensuales', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-20.svg', text: 'Determinación de las coutas obrero patronales mensuales' },


        {//CALCULADORA 21
        url: 'DeterminacionDeLasCuotasObreroPatronalesMensuales', img: 'public/images/pages/catalog/catalog-0.jpg', icon: 'public/images/pages/catalog/cat-icon-20.svg', text: 'Determinación de las coutas obrero patronales mensuales' },
      ],


    }
  }
}
</script>
