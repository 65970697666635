<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col-lg col-titles col-mid">
          <h1>ISR por la prestación de un servicio personal subordinado (salarios)</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">

          	<b-form-group class="custom-form-group-s2"
              label="Año del cálculo: "
              label-for="i-anio">
              	<div class="row">
                	<div class="col-12 col-sm">
                  		<b-form-select v-model="form.anio" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
		                    <b-form-select-option :value="null" disabled selected>Año</b-form-select-option>
		                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

		                </b-form-select>
                	</div>
             	</div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Período de la retención: "
              label-for="i-periodo_retencion">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.periodo_retencion" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="mensual">Mensual</b-form-select-option>
                        <b-form-select-option value="quincenal">Quincenal</b-form-select-option>
                        <b-form-select-option value="decenal">Decenal</b-form-select-option>
                        <b-form-select-option value="semanal">Semanal</b-form-select-option>

                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Ingreso neto deseado del colaborador: "
              label-for="i-periodo_retencion">
                <div class="row">
                  <div class="col-12 col-sm">
                      <b-form-select v-model="form.ingreso_neto_deseado" required oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                        <b-form-select-option :value="null" disabled selected>Selecciona una opción</b-form-select-option>
                        <b-form-select-option value="si">Sí</b-form-select-option>
                        <b-form-select-option value="no">No</b-form-select-option>
                        <b-form-select-option value="ambos">Ambos cálculos</b-form-select-option>

                    </b-form-select>
                  </div>
              </div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Ingresos por la prestación de un servicio personal subordinado (salarios):"
              label-for="i-vent" id="input-ingreso_porla_presentacion" v-if="form.ingreso_neto_deseado != 'si'">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ingreso_porla_presentacion" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ingreso_porla_presentacion != null" style="color: red;"><small>{{validation.ingreso_porla_presentacion}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Ingresos exentos del colaborador:"
              label-for="i-vent" id="input-ingresos_exentos" v-if="form.ingreso_neto_deseado != 'si'">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ingresos_exentos" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ingresos_exentos != null" style="color: red;"><small>{{validation.ingresos_exentos}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Ingreso neto deseado por la prestación de un servicio personal subordinado (salarios):"
              label-for="i-vent" id="input-ingreso_neto_porla_presentacion" v-if="form.ingreso_neto_deseado == 'si'">

              <currency-input id="i-vent" class="form-control"
                v-model="form.ingreso_neto_porla_presentacion" placeholder="$0.00" required oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
              <div v-if="validation.ingreso_neto_porla_presentacion != null" style="color: red;"><small>{{validation.ingreso_neto_porla_presentacion}}</small></div>
            </b-form-group>
            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                  Ingreso base para la retención del ISR  por la prestación de un servicio personal subordinado (salarios):
                </div>
                <div class="col col-med">
                  {{detailInfo.ingreso_base_retencion}}
                </div>
              </div>
            </div>

            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 ISR según tarifa:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 Subsidio al empleo según tabla {{detailInfo.periodo_retencion}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.subsidio_empleo}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 ISR a retener por la prestación de un servicio personal subordinado (o subsidio entregado al colaborador):
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_retener}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 Neto a pagar:
                </div>
                <div class="col col-med">
                  {{detailInfo.neto_pagarone}}
                </div>
              </div>
            </div>

            <p style="text-align:center;"><b>Resumen del cálculo inverso:</b></p>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 Ingreso base para la retención del ISR  por la prestación de un servicio personal subordinado (salarios):
                </div>
                <div class="col col-med">
                  {{detailInfo.ingreso_base_retencion_inverso}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 ISR según tarifa:
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_segun_tarifa_inverso}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 Subsidio al empleo según tabla {{detailInfo.periodo_retencion}}:
                </div>
                <div class="col col-med">
                  {{detailInfo.subsidio_empleo_inverso}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 ISR a retener por la prestación de un servicio personal subordinado (o subsidio entregado al colaborador):
                </div>
                <div class="col col-med">
                  {{detailInfo.isr_retener_presentacion_inverso}}
                </div>
              </div>
            </div>
            <div class="col-12 col-info">
              <div class="row row-body">
                <div class="col col-detail">
                 Neto a pagar:
                </div>
                <div class="col col-med">
                  {{detailInfo.neto_pagartwo}}
                </div>
              </div>
            </div>
            

            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
    
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>

  </div>
</template>

<script>
import FlegalesComponent from './components/fleg-comp-isr-porla-prestacion-deun-servicio-personal-subordinado.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form:{
        anio:null,
        periodo_retencion:null,
        ingreso_neto_deseado:null,
        ingreso_porla_presentacion:null,
        ingresos_exentos:null,
        ingreso_neto_porla_presentacion:null,
      },

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        anio:null,
        periodo_retencion:null,
        ingreso_neto_deseado:null,
        ingreso_porla_presentacion:null,
        ingresos_exentos:null,
        ingreso_neto_porla_presentacion:null,
      }

    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      event.preventDefault();
      
		  /*this.validation.salario_diario = null;
		


      
      if (this.form.salario_diario == 0 || this.form.salario_diario == null) {
          this.validation.salario_diario = '"Salario diari" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-salario_diario").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }
      if (this.form.ultimo_sueldo == 0 || this.form.ultimo_sueldo == null) {
          this.validation.ultimo_sueldo = '"Importe del último sueldo mensual ordinario" debe de ser mayor a 0';
          var scrollt = document.getElementById("input-ultimo_sueldo").offsetTop;
          setTimeout( ()=>{
            window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
          }, 200);
          return;
      }*/
    
      this.$refs['modal-loading'].show();
      //$('#btn_calc').hide();
      
      axios.post(tools.url("/calculateFourteen"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          alert('Ocurrio un error al calcular la informacion');
      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 2016; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
