import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      // { path: '/', name: 'accessPage', component: require(page+'user-access/index.vue').default },

      { path: '/', component: require(page+'user-access/index.vue').default,
        children: [
          {
            path: '/',
            name: 'accessPage',
            component: require(page+'user-access/login.vue').default,
          },
          {
            path: '/recuperar-contrasena',
            name: 'accessPage',
            component: require(page+'user-access/forgot-password.vue').default,
            meta:{ title: '¿Olvidaste tu contraseña?' }
          },
          {
            path: '/registrarse',
            name: 'accessPage',
            component: require(page+'user-access/register.vue').default,
            meta:{ title: 'Registrarse' }
          },
        ]
      },

      { path: '/usuario', component: require(page+'user-account/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
    			{
    				path: '/',
    				component: require(page+'user-account/mis-datos.vue').default,
    				meta:{ title: 'Mis datos' }
    			},
    			{
    				path: 'contrasena',
    				component: require(page+'user-account/contrasena.vue').default,
    				meta:{ title: 'Cambiar contraseña' }
    			},
  	  	]
  		},

	    { path: '/inicio', name: 'homePage', component: require(page+'home.vue').default },

      { path: '/catalogos', component: require(page+'catalog/index.vue').default, meta:{title:"Catálogos"}},
      { path: '/catalogos/actualizacion-y-recargos', name: 'catActuRecaPage', component: require(page+'catalog/actualizacion-y-recargos.vue').default, meta:{title:"Actualización y recargos"}},
      { path: '/catalogos/isr-por-enajenacion-de-inmuebles-por-personas-fisicas', name: 'catISREnaInmPerFis', component: require(page+'catalog/isr-enajenacion-inmuebles-pfisicas.vue').default, meta:{title:"ISR por enajenación de Inmuebles por personas físicas"}},
      { path: '/catalogos/isr-pago-aguinaldo', name: 'catISRPagoAguiPage', component: require(page+'catalog/isr-pago-aguinaldo.vue').default, meta:{title:"ISR por pago de aguinaldo"}},
      { path: '/catalogos/iesps-por-venta-de-cerveza', name: 'IESPSVentaCerveza', component: require(page+'catalog/iesps-por-venta-de-cerveza.vue').default, meta:{title:"IESPS por venta de cerveza"}},
      { path: '/catalogos/isr-e-iva-por-actividades-empresariales-profesionales', name: 'catISRIVAactempypro', component: require(page+'catalog/isr-e-iva-por-actividades-empresariales-profesionales.vue').default, meta:{title:"ISR e IVA por actividades empresariales y profesionales"}},
      { path: '/catalogos/dividendos-a-distribuir', name: 'DividendosADistribuir', component: require(page+'catalog/dividendos-a-distribuir.vue').default, meta:{title:"Dividendos por distribuir a accionistas e ISR a cargo"}},
      { path: '/catalogos/ajuste-anual-inflacion', name: 'AjusteAnualPorInflacion', component: require(page+'catalog/ajuste-anual-inflacion.vue').default, meta:{title:"Ajuste anual por inflación"}},

      { path: '/catalogos/pago-contribuciones-diferidas', name: 'PagoContribucionesDiferidas', component: require(page+'catalog/pago-contribuciones-diferidas.vue').default, meta:{title:"Pago de contribuciones diferidas"}},

      { path: '/catalogos/pago-contribuciones-parcialidades', name: 'PagoContribucionesParcialidades', component: require(page+'catalog/pago-contribuciones-parcialidades.vue').default, meta:{title:"Pago de contribuciones en parcialidades"}},
      { path: '/catalogos/amortizacion-perdidas-fiscales', name: 'AmortizacionPerdidasFiscales', component: require(page+'catalog/amortizacion-perdidas-fiscales.vue').default, meta:{title:"Amortizacion de perdidas fiscales"}},
      { path: '/catalogos/isr-porla-ganancia-enla-enajenacion-de-inmuebles', name: 'IsrEjercicioGananciaEnajecion', component: require(page+'catalog/isr-porla-ganancia-enla-enajenacion-de-inmuebles.vue').default, meta:{title:"ISR del ejercicio por la ganancia en la enajenación de inmuebles"}},
      { path: '/catalogos/isr-porel-pago-delas-indemnizaciones-por-retiro', name: 'IsrPagoIndemnizacionesRetiro', component: require(page+'catalog/isr-pago-inmenzaciones-retiro.vue').default, meta:{title:"ISR por el pago de las indemnizaciones por retiro"}},

      { path: '/catalogos/isr-iva-por-arrendamiento-pagos-provisionales', name: 'IsrIvaArrendamientoPagosProvisionales', component: require(page+'catalog/isr-iva-por-arrendamiento-pagos-provisionales.vue').default, meta:{title:"ISR e IVA por arrendamiento pagos provisionales"}},
      { path: '/catalogos/isr-porla-prestacion-deun-servicio-personal-subordinado', name: 'IsrPorlaPrestacionDeunServicioPersonalSubordinado', component: require(page+'catalog/isr-porla-prestacion-deun-servicio-personal-subordinado.vue').default, meta:{title:"ISR por la prestación de un servicio personal subordinado (salarios)"}},
      { path: '/catalogos/isr-porel-pago-del-finiquito-porel-retiro-voluntario', name: 'IsrPorelPagoDelFiniquitoPorelRetiroVoluntario', component: require(page+'catalog/isr-porel-pago-del-finiquito-porel-retiro-voluntario.vue').default, meta:{title:"ISR por el pago del finiquito por retiro voluntario"}},
      { path: '/catalogos/isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subordinado', name: 'IsrPorIngresosAsimiladosAlaPrestacionDeunServicioPersonalSubordinado', component: require(page+'catalog/isr-por-ingresos-asimilados-ala-prestacion-deun-servicio-personal-subordinado.vue').default, meta:{title:"ISR por ingresos asimilados a la prestación de un servicio personal subordinado (salarios)"}},
      { path: '/catalogos/isr-por-pago-de-ptu', name: 'IsrPorPagoDePtu', component: require(page+'catalog/isr-por-pago-de-ptu.vue').default, meta:{title:"ISR por pago de PTU"}},
      { path: '/catalogos/determinacion-de-las-cuotas-obrero-patronales-bimestrales', name: 'DeterminacionDelasCuotasObreroPatronalesBimestrales', component: require(page+'catalog/determinacion-de-las-cuotas-obrero-patronales-bimestrales.vue').default, meta:{title:"Determinación de las cuotas obrero patronales bimestrales"}},
      { path: '/catalogos/salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit', name: 'SalarioDiarioBaseDeCotizacionParaEfectosDelImssEInfonavit', component: require(page+'catalog/salario-diario-base-de-cotizacion-para-efectos-del-imss-e-infonavit.vue').default, meta:{title:"Salario diario base de cotización para efectos del IMSS e INFONAVIT"}},
      { path: '/catalogos/determinacion-de-las-cuotas-obrero-patronales-mensuales', name: 'DeterminacionDelasCuotasObreroPatronalesMensuales', component: require(page+'catalog/determinacion-de-las-cuotas-obrero-patronales-menusales.vue').default, meta:{title:"Determinación de las cuotas obrero patronales mensuales"}},

      { path: '/catalogos/determinacion-delas-cuotas-obrero-patronales-mensuales', name: 'DeterminacionDeLasCuotasObreroPatronalesMensuales', component: require(page+'catalog/determinacion-delas-cuotas-obrero-patronales-menusales.vue').default, meta:{title:"Determinación de las cuotas obrero patronales mensuales"}},




      { path: '/blog', name: 'blogPage', component: require(page+'blog/index.vue').default, meta:{title:"Notas de interes"}},
      { path: '/blog/:id?', component: require(page+'blog/detail.vue').default, meta:{title:"Notas de interes"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "AJF Arquetipo Jurídico Fiscal", filter: (title)=>{ return title+" - AJF Arquetipo Jurídico Fiscal"; } }
);

// export {routes};
export default MyRouter;
