<template lang="html">
  <div id="catalog-form-page">

    <section class="container oversized-container main-section">
      <div class="row pt-4">
        <div class="col pl-sm-0 col-link">
          <router-link class="link-back" to="/inicio"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
        </div>

        <div class="col-lg col-titles col-mid">
          <h1>Actualización y recargos por pago extemporáneo de contribuciones</h1>
          <hr />
        </div>

        <div class="col col-empty"></div>
      </div>

      <div class="row justify-content-center">
        <!-- Paso 1, Form -->
        <div class="col-12 col-mid col-form" v-if="step == 1">
          <h5 class="mb-4 title">DATOS GENERALES</h5>

          <b-form @submit="onSubmit">
            <b-form-group class="custom-form-group-s2"
              label="Fecha límite de pago:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select required class="blacked" v-model="form.mes1" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.dia1" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.anio1" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

                  </b-form-select>
                </div>

              </div>
              <div v-if="validation.fecha_limite != null" style="color: red;"><small>{{validation.fecha_limite}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de pago:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select required class="blacked" v-model="form.mes2" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.dia2" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daystwo" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.anio2" oninvalid="this.setCustomValidity('Selecciona una opción')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
               <div v-if="validation.fecha_pago != null" style="color: red;"><small>{{validation.fecha_pago}}</small></div>
            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Contribuiciones a cargo:"
              label-for="i-co">

              <currency-input id="i-co" class="form-control"
                v-model="form.contribucion" placeholder="$0.00" required min="1" oninvalid="this.setCustomValidity('Completa este campo')" oninput="setCustomValidity('')"/>
                <div v-if="validation.contribucion != null" style="color: red;"><small>{{validation.contribucion}}</small></div>

              <!-- <b-form-input
                id="i-co"
                v-model="form.contribucion"
                type="text"
                placeholder="0.00"
                @keypress="isNumber($event)"
                required
              ></b-form-input> -->
            </b-form-group>

            <p class="mt-3 mt-lg-5 pt-sm-4 text-center">
              <b-button type="submit" class="_btn-st1 btn-orange" id="btn_calc">Calcular</b-button>
            </p>
          </b-form>
        </div>
        <!-- Paso 1, FIN -->

        <!-- Paso 2, Mostrar datos -->
        <div class="col-12 col-mid col-results" v-if="step == 2">
          <!-- <h5 class="title">DATOS GENERALES</h5> -->
          <b-form-group class="custom-form-group-s2"
              label="Fecha límite de pago:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select required class="blacked" v-model="form.mes1" disabled>
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>

                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.dia1" disabled>
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daysone" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.anio1" disabled>
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>

                  </b-form-select>
                </div>

              </div>

            </b-form-group>

            <b-form-group class="custom-form-group-s2"
              label="Fecha de pago:">
              <div class="row">
                <div class="col-12 col-sm">
                  <b-form-select required class="blacked" v-model="form.mes2" disabled>
                    <b-form-select-option :value="null" disabled>Mes</b-form-select-option>
                    <b-form-select-option :value="month.value" v-for="(month, mindx) in months" :key="mindx">{{month.name}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.dia2" disabled>
                    <b-form-select-option :value="null" disabled>Día</b-form-select-option>
                    <b-form-select-option :value="day.value" v-for="(day, dndx) in daystwo" :key="dndx">{{day.value}}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col-12 col-sm">
                  <b-form-select required v-model="form.anio2" disabled>
                    <b-form-select-option :value="null" disabled>Año</b-form-select-option>
                    <b-form-select-option :value="year.value" v-for="(year, yindx) in years" :key="yindx">{{year.value}}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
            </b-form-group>

          <div class="row">
            <!-- <div class="col-12 col-line">
              <hr />
            </div> -->

            <div class="col-12 col-subbox">
              <div class="row align-items-center">
                <div class="col col-text">
                  <h2 class="trans-250 title with-link" @click="scrolltoTable">CONSULTAR FUNDAMENTOS LEGALES <i class="fas fa-info-circle icon"></i></h2>
                </div>

                <!-- <div class="col col-share">
                  <a class="trans-150 btn-share" v-b-modal.modal-compartir>Compartir <i class="fas fa-share-alt"></i></a>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-info">
              <!-- <div class="row row-top">
                <div class="col col-detail">
                  Detalle
                </div>

                <div class="col col-med">
                  ART. 96 DE LA LISTA LISR
                </div>
              </div> -->

              <div class="row row-body">
                <div class="col col-detail">
                  INPC1 ({{detailInfo.inpc1data}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc1}}
                </div>
              </div>
              <div class="row row-body">
                <div class="col col-detail">
                  INPC2 ({{detailInfo.inpc2data}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.inpc2}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Factor de actualización:
                </div>
                <div class="col col-med">
                  {{detailInfo.factor_actualizacion}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Contribución a cargo (monto histórico):
                </div>
                <div class="col col-med">
                  {{detailInfo.cargo}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Contribución actualizada:
                </div>
                <div class="col col-med">
                  {{detailInfo.contribucion_actulizada}}
                </div>
              </div>


              <div class="row row-body">
                <div class="col col-detail">
                  Tasa de recargos acumulada ({{detailInfo.taza_acumulada}}):
                </div>
                <div class="col col-med">
                  {{detailInfo.recargos}}
                </div>
              </div>

              <div class="row row-body">
                <div class="col col-detail">
                  Total a pagar:
                </div>
                <div class="col col-med col-strong">
                  {{detailInfo.total}}
                </div>
              </div>


            </div>

            <div class="col-12 col-options">
              <p class="mb-3">
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.excel" target="_blank">Exportar a Excel</a>
                <a type="button" class="btn _btn-st1" :href="detailInfo.links.pdf" target="_blank">Exportar a PDF</a>
              </p>
              <p>
                <b-button type="submit" class="_btn-st1 btn-orange" @click="otherCalc">Hacer otro cálculo</b-button>
              </p>
            </div>

          </div>
        </div>
        <!-- Paso 2, FIN -->

        <!-- Fundamentos legales -->
        <flegales-component id="table-flegales"></flegales-component>
        <!--  -->

      </div>
    </section>

    <!-- ******** Modals ******** -->
    <!-- Modal "Compartir a Correo" -->
    <b-modal id="modal-compartir" ref="modal-compartir" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
      <b-form class="content" @submit="onSubmitComp">
        <div class="box-content">
          <h5 class="title">Escribe el correo a compartir</h5>

          <b-form-group class="custom-form-group"
            label="Correo"
            label-for="il-email">
            <b-form-input
              id="il-email"
              v-model="formComp.email"
              type="email"
              placeholder="correo@ejemplo.com"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="box-footer">
          <button type="button" class="btn _btn-st1" @click="$refs['modal-compartir'].hide()">Cancelar</button>
          <b-button type="submit" class="_btn-st1 btn-orange">Compartir</b-button>
        </div>
      </b-form>
    </b-modal>
    <!--  -->

    <!-- Modal "Guardar calculo" -->
    <b-modal id="modal-gcalculo" ref="modal-gcalculo" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
      <b-form class="content" @submit="onSubmitCalc">
        <div class="text-center box-content">
          <i class="fal fa-check-circle icon-success"></i>
          <h5 class="title">¡Cálculo guardado con éxito!</h5>
        </div>

        <div class="box-footer">
          <router-link type="button" class="btn _btn-st1" to="/catalogos">Salir</router-link>
          <b-button type="submit" class="_btn-st1 btn-orange">Hacer otro cálculo</b-button>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
    <b-modal id="modal-loading" ref="modal-loading" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc modal-class="modal-st-1">
        <div style="text-align: center;">
          <img src="public/images/shared/loading2.gif">
          <br><br><h3>Realizando cálculo</h3>
        </div>
    </b-modal>
  </div>
</template>

<script>
// import ComponentModalFlegales from './components/fleg-modal-actu-recargos.vue';
import FlegalesComponent from './components/fleg-comp-actualizacion-y-recargos.vue';
export default {
  data(){
    return{
      step: 1,
      // showModalFLegales: false,
      showFLegalesComp: false,

      form: {
        mes1: null,
        dia1:null,
        anio1:null,

        mes2: null,
        dia2:null,
        anio2:null,
        contribucion:null,

        /*mes1: 1,
        dia1:17,
        anio1:2021,

        mes2: 2,
        dia2:23,
        anio2:2021,
        contribucion:369962.85*/
      },

      formComp: {
        email: null
      },

      years:[],
      months:[
        {value:1,name:'Enero'},
        {value:2,name:'Febrero'},
        {value:3,name:'Marzo'},
        {value:4,name:'Abril'},
        {value:5,name:'Mayo'},
        {value:6,name:'Junio'},
        {value:7,name:'Julio'},
        {value:8,name:'Agosto'},
        {value:9,name:'Septiembre'},
        {value:10,name:'Octubre'},
        {value:11,name:'Noviembre'},
        {value:12,name:'Diciembre'},
      ],
      daysone:[],
      daystwo:[],

      detailInfo:[],
      validation:{
        fecha_limite:null,
        fecha_pago:null,
        contribucion:null
      }
    }
  },
  watch:{
    'form.mes1':function(val)
    {
      var fecha = new Date();
      if (this.form.anio1 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = this.form.anio1;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.anio1':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes1, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daysone = days;
    },
    'form.mes2':function(val)
    {
      var fecha = new Date();
      if (this.form.anio2 == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio = this.form.anio2;
      }
      var d = new Date(anio, val, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
    'form.anio2':function(val)
    {
      var fecha = new Date();
      if (val == null) {
          var anio = fecha.getFullYear();
      }
      else{
          var anio  = val;
      }
      var d = new Date(anio, this.form.mes2, 0);
      var days =[];
      for (var i = 1; i <= d.getDate(); i++) {
        var aux = {
          'value':i
        };
        days.push(aux);
      }
      this.daystwo = days;
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onSubmit(event) { // Enviar formulario del paso 1
      this.validation.fecha_limite = null;
       this.validation.contribucion = null;
       this.validation.fecha_pago = null;
      event.preventDefault();
      var fecha1 = null;
      if (this.form.dia1 < 10) {
          if (this.form.mes1 < 10) {
              fecha1 = this.form.anio1+'-0'+this.form.mes1+'-0'+this.form.dia1;
          }
          else{
              fecha1 = this.form.anio1+'-'+this.form.mes1+'-0'+this.form.dia1;
          }
      }
      else{
          if (this.form.mes1 < 10) {
              fecha1 = this.form.anio1+'-0'+this.form.mes1+'-'+this.form.dia1;
          }
          else{
              fecha1 = this.form.anio1+'-'+this.form.mes1+'-'+this.form.dia1;
          }
      }
      var fecha2=null;
      if (this.form.dia2 < 10) {
          if (this.form.mes2 < 10) {
              fecha2 = this.form.anio2+'-0'+this.form.mes2+'-0'+this.form.dia2;
          }
          else{
              fecha2 = this.form.anio2+'-'+this.form.mes2+'-0'+this.form.dia2;
          }
      }
      else{
          if (this.form.mes2 < 10) {
              fecha2 = this.form.anio2+'-0'+this.form.mes2+'-'+this.form.dia2;
          }
          else{
              fecha2 = this.form.anio2+'-'+this.form.mes2+'-'+this.form.dia2;
          }
      }

      var fecha_uno = new Date(fecha1).getTime();
      var fecha_dos = new Date(fecha2).getTime();
      var diff = fecha_dos - fecha_uno;

      var result = diff/(1000*60*60*24);
      if (result < 1) {

          this.validation.fecha_limite = '"Fecha límite de pago" no debe de ser mayor a "Fecha de pago"';
          return;
      }
      if (this.form.contribucion == 0 || this.form.contribucion == null) {
          this.validation.contribucion = '"Contribuiciones a cargo" debe de ser mayor a 0';
          return;
      }

      this.$refs['modal-loading'].show();
      $('#btn_calc').hide();

      axios.post(tools.url("/calculateOne"),this.form).then((response)=>{
          this.detailInfo = response.data;
          this.$refs['modal-loading'].hide();
          this.step = 2;
      }).catch((error)=>{
          $('#btn_calc').show();
          this.$refs['modal-loading'].hide();
          if(error.response.data.msg == 'date_one'){
              this.validation.fecha_limite = 'No existe INPC para la fecha ingresada';
              return;
          }
          else if(error.response.data.msg == 'date_two')
          {
              this.validation.fecha_pago = 'No existe INPC para la fecha ingresada';
              return;
          }
          else{
            alert('Ocurrio un error al calcular la informacion');
          }


      });
    },
    otherCalc(){
      location.reload();
    },

    scrolltoTable(){
      var tablePosY = document.getElementById("table-flegales").offsetTop;
      this.showFLegalesComp = true;

      setTimeout( ()=>{
        window.scrollTo({ top: tablePosY, left: 0, behavior: 'smooth' });
      }, 200);
    },

    onSubmitComp(event) { // Enviar formulario compartir del Modal "Compartir a Correo"
      event.preventDefault();
      this.$refs['modal-compartir'].hide();

      console.log("Compartir cálculo");
    },

    onSubmitCalc(event) { // Guardar cálculo y regresar a paso 1
      event.preventDefault();

      var keys = Object.keys(this.form);
      var resetForm = {};
      keys.forEach(key => resetForm[key] = null);
      this.form = resetForm;

      this.step = 1;
      this.$refs['modal-gcalculo'].hide();

      console.log("Hacer otro cálculo");
    },
  },

  mounted(){
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var years = [];
    for (var i = 1996; i <= anio; i++) {
        var aux = {
          'value':i
        };
        years.push(aux);
    }

    years.reverse();
    this.years = years;
  },

  components: {
    FlegalesComponent
  },
}
</script>
