<template lang="html">
  <div class="col-12 col-lg-11 col-xl-9 table-container-s1">
    <div v-if="$parent.showFLegalesComp" style="font-family: sans-serif !important; text-align: justify;">
      <h4 class="table-title">Fundamentos legales</h4>

      <table class="table table-hover" v-for="(item, tabl1Inx) in items" :key="'tabl1Inx-'+tabl1Inx">
        <thead>
          <tr>
            <th scope="col" width="178">{{ item.title1 }}</th>
            <th scope="col">{{ item.title2 }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rtInx) in item.content">
            <th>{{ row.c1 }}</th>
            <td v-html="row.c2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
export default {
  data(){
    return{
      items: [
      { title1: 'LSS 25', title2: 'APORTACIONES POR VALUACIONES ACTUARIALES',
          content: [
           { c1: 'Primer párrafo',
              c2: '<b>APORTACIONES POR VALUACIONES ACTUARIALES</b><br>En los casos previstos por el artículo 23 , el Estado aportará la contribución que le corresponda en términos de esta Ley, independientemente de la que resulte a cargo del patrón por la valuación actuarial de su contrato, pagando éste, tanto su propia cuota como la parte de la cuota obrera que le corresponda conforme a dicha valuación.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CUOTA POR PRESTACIONES MEDICAS A PENSIONADOS</b><br>Para cubrir las prestaciones en especie del seguro de enfermedades y maternidad de los pensionados y sus beneficiarios, en los seguros de riesgos de trabajo, invalidez y vida, así como retiro, cesantía en edad avanzada y vejez, los patrones, los trabajadores y el Estado aportarán una cuota de uno punto cinco por ciento sobre el salario base de cotización. De dicha cuota corresponderá al patrón pagar el uno punto cero cinco por ciento, a los trabajadores el cero punto trescientos setenta y cinco por ciento y al Estado el cero punto cero setenta y cinco por ciento.'
            },
          ]
        },
        { title1: 'LSS 27', title2: 'INTEGRACIÓN DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El salario base de cotización se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, alimentación, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo. Se excluyen como integrantes del salario base de cotización, dada su naturaleza, los siguientes conceptos:'
            },
            { c1: 'Fracción I',
              c2: '<b>INSTRUMENTOS DE TRABAJO</b><br>I. Los instrumentos de trabajo tales como herramientas, ropa y otros similares;'
            },
            { c1: 'Fracción II',
              c2: '<b>EL AHORRO, BAJO CIERTAS CONDICIONES</b><br>II. El ahorro, cuando se integre por un depósito de cantidad semanaria, quincenal o mensual igual del trabajador y de la empresa; si se constituye en forma diversa o puede el trabajador retirarlo más de dos veces al año, integrará salario; tampoco se tomarán en cuenta las cantidades otorgadas por el patrón para fines sociales de carácter sindical;'
            },
            { c1: 'Fracción III',
              c2: '<b>CUOTAS ADICIONALES DEL SEGURO DE RCV</b><br>III. Las aportaciones adicionales que el patrón convenga otorgar a favor de sus trabajadores por concepto de cuotas del seguro de retiro, cesantía en edad avanzada y vejez;'
            },
            { c1: 'Fracción IV',
              c2: '<b>CUOTAS IMSS, APORTACIONES INFONAVIT Y PTU</b><br>IV. Las cuotas que en términos de esta Ley le corresponde cubrir al patrón, las aportaciones al Instituto del Fondo Nacional de la Vivienda para los Trabajadores, y las participaciones en las utilidades de la empresa;'
            },
            { c1: 'Fracción V',
              c2: '<b>ALIMENTACIÓN Y HABITACIÓN, SU PROPORCIONALIDAD</b><br>V. La alimentación y la habitación cuando se entreguen en forma onerosa a los trabajadores; se entiende que son onerosas estas prestaciones cuando el trabajador pague por cada una de ellas, como mínimo, el veinte por ciento del salario mínimo general diario que rija en el Distrito Federal;'
            },
            { c1: 'Fracción VI',
              c2: '<b>DESPENSAS, SU PROPORCIONALIDAD</b><br>VI. Las despensas en especie o en dinero, siempre y cuando su importe no rebase el cuarenta por ciento del salario mínimo general diario vigente en el Distrito Federal;'
            },
            { c1: 'Fracción VII',
              c2: '<b>PREMIOS POR ASISTENCIA Y PUNTUALIDAD, SU PROPORCIONALIDAD</b><br>VII. Los premios por asistencia y puntualidad, siempre que el importe de cada uno de estos conceptos no rebase el diez por ciento del salario base de cotización;'
            },
            { c1: 'Fracción VIII',
              c2: '<b>APORTACIONES PARA PLANES DE PENSIONES, SUS CONDICIONANTES</b><br>VIII. Las cantidades aportadas para fines sociales, considerándose como tales las entregadas para constituir fondos de algún plan de pensiones establecido por el patrón o derivado de contratación colectiva. Los planes de pensiones serán sólo los que reúnan los requisitos que establezca la Comisión Nacional del Sistema de Ahorro para el Retiro, y'
            },
            { c1: 'Fracción IX',
              c2: '<b>TIEMPO EXTRAORDINARIO, SUS LIMITANTES</b><br>IX. El tiempo extraordinario dentro de los márgenes señalados en la Ley Federal del Trabajo .'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>REGISTRO CONTABLE DE CONCEPTOS</b><br>Para que los conceptos mencionados en este precepto se excluyan como integrantes del salario base de cotización, deberán estar debidamente registrados en la contabilidad del patrón.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>INTEGRACIÓN DE LOS EXCEDENTES</b><br>En los conceptos previstos en las fracciones VI, VII y IX cuando el importe de estas prestaciones rebase el porcentaje establecido, solamente se integrarán los excedentes al salario base de cotización.'
            },
          ]
        },
        { title1: 'LSS 28', title2: 'LÍMITES DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los asegurados se inscribirán con el salario base de cotización que perciban en el momento de su afiliación, estableciéndose como límite superior el equivalente a veinticinco veces el salario mínimo general que rija en el Distrito Federal y como límite inferior el salario mínimo general del área geográfica respectiva.'
            },
            
          ]
        },
        { title1: 'LSS 29', title2: 'REGLAS PARA LA COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para determinar la forma de cotización se aplicarán las siguientes reglas:'
            },
            { c1: 'Fracción I',
              c2: '<b>PERIODO DE PAGO DE COUTAS</b><br>I. El mes natural será el periodo de pago de cuotas;'
            },
            { c1: 'Fracción II',
              c2: '<b>DETERMINACIÓN DEL SALARIO</b><br>II. Para fijar el salario diario en caso de que pague por semana, quincena o mes, se dividirá la remuneración correspondiente entre siete, quince o treinta respectivamente.Análogo procedimiento será empleado cuando el salario se fije por periodos distintos a los señalados, y'
            },
            { c1: 'Fracción III',
              c2: '<b>SEMANA Y JORNADA REDUCIDA</b><br>III. Si por la naturaleza o peculiaridades de las labores, el salario no se estiupula por semana o por mes, sino por día trabajado y comprende menos días de los de una semana o el asegurado labora jornadas reducidas y su salario se determina por unidad de tiempo, en ningún caso se recibirán cuotas con base en un salario inferior al mínimo.'
            },
          ]
        },
        { title1: 'LSS 30', title2: 'DETERMINACIÓN DEL SALARIO BASE DE COTIZACIÓN',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para determinar el salario diario base de cotización se estará a lo siguiente:'
            },
            { c1: 'Fracción I',
              c2: '<b>SALARIO FIJO</b><br>I. Cuando además de los elementos fijos del salario el trabajador percibiera regularmente otras retribuciones periódicas de cuantía previamente conocida, éstas se sumarán a dichos elementos fijos;'
            },
            { c1: 'Fracción II',
              c2: '<b>SALARIO VARIABLE</b><br>II. Si por la naturaleza del trabajo, el salario se integra con elementos variables que no puedan ser previamente conocidos, se sumarán los ingresos totales percibidos durante los dos meses inmediatos anteriores y se dividirán entre el número de días de salario devengado en ese periodo. Si se trata de un trabajador de nuevo ingreso, se tomará el salario probable que le corresponda en dicho periodo, y<br>…………………………………………………'
            },
          ]
        },
        { title1: 'LSS 71', title2: 'BASE PARA EL PAGO DE LAS CUOTAS POR RIESGO',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las cuotas que por el seguro de riesgos de trabajo deban pagar los patrones, se determinarán en relación con la cuantía del salario base de cotización, y con los riesgos inherentes a la actividad de la negociación de que se trate, en los términos que establezca el reglamento relativo.'
            },
          ]
        },
        { title1: 'LSS 72', title2: 'FÓRMULA DE SINIESTRALIDAD',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Para los efectos de la fijación de primas a cubrir por el seguro de riesgos de trabajo, las empresas deberán calcular sus primas, multiplicando la siniestralidad de la empresa por un factor de prima, y al producto se le sumará el 0.005. El resultado será la prima a aplicar sobre los salarios de cotización, conforme a la fórmula siguiente:<br>Prima = [(S/365)+V * (I + D)] * (F/N) + M'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>PAGO DE PRIMA MEDIA</b><br>Al inscribirse por primera vez en el Instituto o al cambiar de actividad, las empresas cubrirán, en la clase que les corresponda conforme al reglamento, la prima media. Una vez ubicada la empresa en la prima a pagar, los siguientes aumentos o disminuciones de la misma se harán conforme al párrafo primero de este artículo.'
            },
            { c1: 'Tercer párrafo',
              c2: '<b>IMPACTO DE LOS ACCIDENTES EN TRAYECTO</b><br>No se tomarán en cuenta para la siniestralidad de las empresas, los accidentes que ocurran a los trabajadores al trasladarse de su domicilio al centro de labores o viceversa.'
            },
            { c1: 'Cuarto párrafo',
              c2: '<b>FACTOR DE PRIMA PARA PATRONES CON UN SISTEMA DE ADMINISTRACIÓN Y SEGURIDAD EN EL TRABAJO</b><br>Los patrones cuyos centros de trabajo cuenten con un sistema de administración y seguridad en el trabajo acreditado por la Secretaría del Trabajo y Previsión Social, aplicarán una F de 2.2 como factor de prima.'
            },
            { c1: 'Quinto párrafo',
              c2: '<b>DETERMINACIÓN DE PRIMA DE PATRONES CON MENOS DE DIEZ TRABAJADORES</b><br>Las empresas de menos de 10 trabajadores, podrán optar por presentar la declaración anual correspondiente o cubrir la prima media que les corresponda conforme al reglamento, de acuerdo al artículo 73 de esta Ley.'
            },
            
          ]
        },
        { title1: 'LSS 73', title2: 'PRIMA POR INSCRIPCIÓN INICIAL',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Al inscribirse por primera vez en el Instituto o al cambiar de actividad, las empresas cubrirán la prima media de la clase que conforme al Reglamento les corresponda, de acuerdo a la tabla siguiente:<table><tr><th>Prima media</th><th>En por cientos</th></tr><tr><td>Clase I</td><td>0.54355</td></tr><tr><td>Clase II</td><td>1.13065</td></tr><tr><td>Clase III</td><td>2.59840</td></tr><tr><td>Clase IV</td><td>4.65325</td></tr><tr><td>Clase V</td><td>7.58875</td></tr></table>'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>CAMBIO DE ACTIVIDAD POR SENTENCIA O DISPOSICIÓN DE LEY</b><br>Se aplicará igualmente lo dispuesto por este artículo cuando el cambio de actividad de la empresa se origine por una sentencia definitiva o por disposición de esta Ley o de un reglamento.'
            },
         
          ]
        },
        { title1: 'LSS 74', title2: 'REVISIÓN ANUAL DE LA SINIESTRALIDAD',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las empresas tendrán la obligación de revisar anualmente su siniestralidad, conforme al periodo y dentro del plazo que señale el reglamento, para determinar si permanecen en la misma prima, se disminuye o aumenta.'
            },
            { c1: 'Segundo párrafo',
              c2: '<b>MODIFICACIÓN DE LA PRIMA</b><br>La prima conforme a la cual estén cubriendo sus cuotas las empresas podrá ser modificada, aumentándola o disminuyéndola en una proporción no mayor al uno por ciento con respecto a la del año inmediato anterior, tomando en consideración los riesgos de trabajo terminados durante el lapso que fije el reglamento respectivo, con independencia de la fecha en que éstos hubieran ocurrido y la comprobación documental del establecimiento de programas o acciones preventivas de accidentes y enfermedades de trabajo. Estas modificaciones no podrán exceder los límites fijados para la prima mínima y máxima, que serán de cero punto cinco por ciento y quince por ciento de los salarios base de cotización respectivamente.'
            },
            { c1: 'Tercer párrafo',
              c2: 'La siniestralidad se fijará conforme al reglamento de la materia.'
            },
          ]
        },
        { title1: 'LSS 75', title2: 'DETERMINACIÓN DE LAS CLASES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'La determinación de las clases comprenderá una lista de los diversos tipos de actividades y ramas industriales, catalogándolas en razón de la mayor o menor peligrosidad a que están expuestos los trabajadores, y asignando a cada uno de los grupos que formen dicha lista, una clase determinada. Este supuesto sólo se aplicará a las empresas que se inscriben por primera vez en el Instituto o cambien de actividad.'
            },
          ]
        },
        { title1: 'LSS 76', title2: 'REVISIÓN DE LA FÓRMULA PARA EL CÁLCULO DE LA PRIMA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El Consejo Técnico del Instituto promoverá ante las instancias competentes y éstas ante el H. Congreso de la Unión, cada tres años, la revisión de la fórmula para el cálculo de la prima, para asegurar que se mantenga o restituya en su caso, el equilibrio financiero de este seguro, tomando en cuenta a todas las empresas del país. Para tal efecto se considerará la opinión que al respecto sustente el Comité Consultivo del Seguro de Riesgos de Trabajo, el cual estará integrado de manera tripartita.'
            },
            { c1: 'Segundo párrafo',
              c2: 'Si la Asamblea General lo autorizare, el Consejo Técnico podrá promover la revisión a que alude este artículo en cualquier tiempo, tomando en cuenta la experiencia adquirida.'
            },
          ]
        },
         { title1: 'LSS 105', title2: 'ORIGEN DE LOS RECURSOS',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los recursos necesarios para cubrir las prestaciones en dinero, las prestaciones en especie y los gastos administrativos del seguro de enfermedades y maternidad, se obtendrán de las cuotas que están obligados a cubrir los patrones y los trabajadores o demás sujetos y de la contribución que corresponda al Estado.'
            },
          ]
        },
        { title1: 'LSS 106', title2: 'FINANCIAMIENTO DE PRESTACIONES EN ESPECIE EN E Y M',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las prestaciones en especie del seguro de enfermedades y maternidad, se financiarán en la forma siguiente:'
            },
            { c1: 'Fracción I',
              c2: '<b>CUOTA PATRONAL POR SALARIO MÍNIMO</b><br>I. Por cada asegurado se pagará mensualmente una cuota diaria patronal equivalente al trece punto nueve por ciento de un salario mínimo general diario para el Distrito Federal;'
            },
            { c1: 'Fracción II',
              c2: '<b>CUOTA ADICIONAL A TRES SALARIOS MÍNIMOS</b><br>II. Para los asegurados cuyo salario base de cotización sea mayor a tres veces el salario mínimo general diario para el Distrito Federal; se cubrirá además de la cuota establecida en la fracción anterior, una cuota adicional patronal equivalente al seis por ciento y otra adicional obrera del dos por ciento, de la cantidad que resulte de la diferencia entre el salario base de cotización y tres veces el salario mínimo citado, y'
            },
            { c1: 'Fracción III',
              c2: '<b>CUOTA ACTUALIZADA DEL ESTADO</b><br>III. El Gobierno Federal cubrirá mensualmente una cuota diaria por cada asegurado, equivalente a trece punto nueve por ciento de un salario mínimo general para el Distrito Federal, a la fecha de entrada en vigor de esta Ley, la cantidad inicial que resulte se actualizará trimestralmente de acuerdo a la variación del Indice Nacional de Precios al Consumidor.'
            },
          ]
        },
         { title1: 'LSS 107', title2: 'FINANCIAMIENTO DE PRESTACIONES EN DINERO EN E Y M',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las prestaciones en dinero del seguro de enfermedades y maternidad se financiarán con una cuota del uno por ciento sobre el salario base de cotización, que se pagará de la forma siguiente:'
            },
            { c1: 'Fracción I',
              c2: '<b>PARTE PATRONAL</b><br>I. A los patrones les corresponderá pagar el setenta por ciento de dicha cuota;'
            },
            { c1: 'Fracción II',
              c2: '<b>PARTE DE LOS TRABAJADORES</b><br>II. A los trabajadores les corresponderá pagar el veinticinco por ciento de la misma, y'
            },
            { c1: 'Fracción III',
              c2: '<b>PARTE DEL GOBIERNO FEDERAL</b><br>III. Al Gobierno Federal le corresponderá pagar el cinco por ciento restante.'
            },
          ]
        },
        { title1: 'LSS 108', title2: 'APORTACIONES DEL GOBIERNO FEDERAL',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las aportaciones del Gobierno Federal serán cubiertas en pagos mensuales iguales, equivalentes a la doceava parte de la estimación que presente el Instituto para el año siguiente a la Secretaría de Hacienda y Crédito Público, en el mes de julio de cada ejercicio. En el caso de que en un cuatrimestre la inflación sea cuatro puntos porcentuales mayor o menor a la prevista en dichos cálculos, se harán las compensaciones preliminares correspondientes antes de que termine el siguiente bimestre, realizándose los ajustes definitivos, en base a la inflación real anual, durante el mes de enero del año siguiente.'
            },
          ]
        },
       
        { title1: 'LSS 147', title2: 'PORCENTAJE PARA PATRONES Y TRABAJADORES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'A los patrones y a los trabajadores les corresponde cubrir, para el seguro de invalidez y vida el uno punto setenta y cinco por ciento y el cero punto seiscientos veinticinco por ciento sobre el salario base de cotización, respectivamente.'
            },
          ]
        },
        { title1: 'LSS 168', title2: 'CUANTÍA DE LAS CUOTAS DE RETIRO, CESANTÍA Y VEJEZ',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Las cuotas y aportaciones a que se refiere el artículo anterior serán:'
            },
            { c1: 'Fracción I',
              c2: '<b>RETIRO</b><br>I. En el ramo de retiro, a los patrones les corresponde cubrir el importe equivalente al dos por ciento del salario base de cotización del trabajador.'
            },
            { c1: 'Fracción II',
              c2: '<b>CESANTÍA Y VEJEZ</b><br>II. En los ramos de cesantía en edad avanzada y vejez:<br><b>CUOTA PATRONAL</b><br>a) Los patrones cubrirán la cuota que corresponda sobre el salario base de cotización, calculada conforme a la siguiente tabla: …<br><b>CUOTA OBRERA</b><br>b) Los trabajadores cubrirán una cuota del uno punto ciento veinticinco por ciento sobre el salario base de cotización.'
            },
          ]
        },
        { title1: 'LSS 211', title2: 'MONTO DE LA PRIMA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El monto de la prima para este seguro será del uno por ciento sobre el salario base de cotización. Para prestaciones sociales solamente se podrá destinar hasta el veinte por ciento de dicho monto.'
            },
          ]
        },
        { title1: 'LSS Tr 19', title2: 'TASAS TEMPORALES APLICABLES A LAS COUTAS DE E Y M',
          content: [
            { c1: 'Primer párrafo',
              c2: 'La tasa sobre el salario mínimo general diario del Distrito Federal a que se refiere la fracción I del artículo 106, se incrementará el primero de julio de cada año en sesenta y cinco centésimas de punto porcentual. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },
            { c1: 'Segundo párrafo',
              c2: 'Las tasas a que se refiere la fracción II del artículo 106, se reducirán el primero de julio de cada año en cuarenta y nueve centésimas de punto porcentual la que corresponde a los patrones y en dieciséis centésimas de punto porcentual la que corresponde pagar a los Trabajadores. Estas modificaciones comenzarán en el año de 1998 y terminarán en el año 2007.'
            },
          ]
        },

        { title1: 'LSS Tr 25', title2: 'APLICACIÓN ESCALONADA DEL SBC PARA IV Y CV',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El artículo 28 de esta ley entrará en vigor el 1 de enero del año 2007, en lo relativo al seguro de invalidez y vida, así como en los ramos de cesantía en edad avanzada y vejez. Los demás ramons de aseguramiento tendrán como límite superior desde el inicio de la vigencia de esta ley el equivalente a veinticinco veces el salario mínimo general que rija en el Distrito Federal.'
            },
            { c1: 'Segundo párrafo',
              c2: 'A partir de la entrada en vigor de esta Ley el límite del salario base de cotización en veces salario mínimo para el seguro de invalidez y vida, así como para los ramos de cesantía en edad avanzada y vejez, será de quince veces el salario mínimo general vigente en el Distrito Federal, el que se aumentará un salario mínimo por cada año subsecuente hasta llegar a veinticinco en el año 2007'
            },
          ]
        },
        { title1: 'LSS Tr 27', title2: 'PAGO BIMESTRAL DEL RCV, TEMPORALMENTE',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El pago de las cuotas obrero patronales respecto del seguro de retiro, cesantía en edad avanzada y vejez, continuará realizándose en forma bimestral, hasta en tanto no se homologuen los periodos de pago de las Leyes del ISSSTE e INFONAVIT'
            },
            
          ]
        },
       
        { title1: 'LSS Tr 1', title2: 'INICIO DE LA VIGENCIA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El presente Decreto entrará en vigor el 1 de enero de 2021, salvo lo dispuesto en los transitorios siguientes.'
            },
          ]
        },
        { title1: 'LSS Tr 2', title2: 'INCREMENTO GRADUAL DE LA CUOTA PATRONAL DEL RAMO DE CESANTÍA EN EDAD AVANZADA Y VEJEZ',
          content: [
           

            { c1: 'Segundo párrafo',
              c2: '…………………………………………………<br><b>CUOTAS DE FINANCIAMIENTO DEL RAMO DE CV PARA 2021 Y 2022</b><br>Del 1 de enero de 2021 al 31 de diciembre de 2022:'
            },
            { c1: 'Fracción I',
              c2: '<b>CUOTA PATRONAL Y OBRERA</b><br>I. Los patrones seguirán cubriendo, para los ramos de cesantía en edad avanzada y vejez, una cuota del tres punto ciento cincuenta por ciento sobre el salario base de cotización del trabajador.<br>…………………………………………………'
            },
          ]
        },


        
        { title1: 'LFT 68', title2: 'PROLONGACIÓN DEL TIEMPO EXTRAORDINARIO MAYOR A NUEVE HORAS A LA SEMANA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Los trabajadores no están obligados a prestar sus servicios por un tiempo mayor del permitido en este capítulo.'
            },
            { c1: 'Segundo párrafo',
              c2: 'La prolongación del tiempo extraordinario que exceda de nueve horas a la semana, obliga al patrón a pagar al trabajador el tiempo excedente con un doscientos por ciento más del salario que corresponda a las horas de la jornada, sin perjuicio de las sanciones establecidas en esta Ley.'
            },
          ]
        },
        { title1: 'LFT 84', title2: 'INTEGRACIÓN DEL SALARIO FIJO Y VARIABLE',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El salario se integra con los pagos hechos en efectivo por cuota diaria, gratificaciones, percepciones, habitación, primas, comisiones, prestaciones en especie y cualquiera otra cantidad o prestación que se entregue al trabajador por su trabajo.'
            },
          ]
        },
        { title1: 'LINFONAVIT 29', title2: 'OBLIGACIONES PATRONALES',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Son obligaciones de los patrones:<br>…………………………………………………'
            },
            { c1: 'Fracción II',
              c2: '<b>DETERMINACIÓN Y ENTERO DE APORTACIONES</b><br>II. Determinar el monto de las aportaciones del cinco por ciento sobre el salario de los trabajadores a su servicio y efectuar el pago en las entidades receptoras que actúen por cuenta y orden del Instituto, para su abono en la subcuenta de vivienda de las cuentas individuales de los trabajadores previstas en los sistemas de ahorro para el retiro, en los términos de la presente Ley y sus reglamentos, así como en lo conducente, conforme a lo previsto en la Ley del Seguro Social y en la Ley Federal del Trabajo. En lo que corresponde a la integración y cálculo de la base y límite superior salarial para el pago de aportaciones, se aplicará lo contenido en la Ley del Seguro Social.<br>…………………………………………………'
            },
          ]
        },
        
        { title1: 'LUMA 4', title2: 'METODOLOGÍA PARA CALCULAR Y DETERMINAR ANUALMENTE EL VALOR ACTUALIZADO DE LA UMA',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El valor actualizado de la UMA se calculará y determinará anualmente por el INEGI, de conformidad con el siguiente método:'
            },
            { c1: 'Fracción I',
              c2: '<b>DETERMINACIÓN DEL VALOR DIARIO</b><br>I. El valor diario se determinará multiplicando el valor diario de la UMA del año inmediato anterior por el resultado de la suma de uno más la variación interanual del índice Nacional de Precios al Consumidor del mes de diciembre del año inmediato anterior.'
            },
            { c1: 'Fracción II',
              c2: '<b>DETERMINACIÓN DEL VALOR MENSUAL</b><br>II. El valor mensual será el producto de multiplicar el valor diario de la UMA por 30.4.'
            },
            { c1: 'Fracción III',
              c2: '<b>DETERMINACIÓN DEL VALOR ANUAL</b><br>III. El valor anual será el producto de multiplicar el valor mensual de la UMA por 12.'
            },
          ]
        },
        { title1: 'LUMA 5', title2: 'PUBLICACIÓN DEL VALOR DE LA UMA E INICIO DE VIGENCIA DE ÉSTE',
          content: [
            { c1: 'Primer párrafo',
              c2: 'El INEGI publicará en el Diario Oficial de la Federación dentro de los primeros diez días del mes de enero de cada año el valor diario, mensual y anual en moneda nacional de la UMA y entrarán en vigor dichos valores el 1o. de febrero de dicho año.'
            },
          ]
        },
        { title1: 'ACDO.SA2.HCT.250117/26.P.DJ ', title2: '',
          content: [
            { c1: 'Primer párrafo',
              c2: 'Acuerdo ACDO.SA2.HCT.250117/26.P.DJ del 25-I-2017, que el límite inferior del SBC será el salario mínimo, pues está expresamente prohibida la inscripción al Instituto por debajo de ese límite, y el límite máximo será de 25 UMAS por constituir una referencia'
            },
          ]
        },
        
       

      ],
    }
  },

  watch: {
  	'$parent.showFLegalesComp': function(newVal, oldVal) {
      if(newVal == true){
        this.$refs['modal-flegales'].show();
      }
    }
  }
}
</script>
