<template lang="html">
  <div id="blog-page">

    <section class="container oversized-container px-sm-0">
      <div class="d-block mt-3">
        <router-link class="link-back" :to="{ name: 'homePage'}"><i class="fal fa-chevron-left icon"></i> <span>Regresar</span></router-link>
      </div>
    </section>

    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 box-gral-top">
          <div class="row align-items-center no-gutters">
            <div class="col">
              <h2 class="g-s1-title">Notas de interés</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-articles">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(x, baInx) in 4" :key="'ba-'+baInx">
          <router-link class="box-article" to="/blog/1">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/article-'+(baInx+1)+'.jpg)' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>Título de este artículo</h5>

              <div class="descr">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                </p>
              </div>
            </div>
          </router-link>
        </div>

        <!-- relleno -->
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(x, baInx) in 4" :key="'ba-'+baInx">
          <router-link class="box-article" to="/blog/1">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/article-'+(baInx+1)+'.jpg)' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>Título de este artículo</h5>

              <div class="descr">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(x, baInx) in 4" :key="'ba-'+baInx">
          <router-link class="box-article" to="/blog/1">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/article-'+(baInx+1)+'.jpg)' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>Título de este artículo</h5>

              <div class="descr">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <!-- fin del relleno -->
      </div>

      <div class="row">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{}
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'blogPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    }
  }
}
</script>
